import { CREATE_XERO_CREDENTIALS_SUCCESS, CREATE_XERO_CREDENTIALS_FAILURE } from 'actions/xero/credentials/create';
import { VERIFY_XERO_CREDENTIALS_SUCCESS, VERIFY_XERO_CREDENTIALS_FAILURE } from 'actions/xero/credentials/verify';
import { DELETE_XERO_CREDENTIALS_START, DELETE_XERO_CREDENTIALS_SUCCESS, DELETE_XERO_CREDENTIALS_FAILURE } from 'actions/xero/credentials/delete';
import { FETCH_XERO_ACCOUNTS_START, FETCH_XERO_ACCOUNTS_SUCCESS, FETCH_XERO_ACCOUNTS_FAILURE } from 'actions/xero/accounts/fetch';
import { FETCH_XERO_CONTACTS_START, FETCH_XERO_CONTACTS_SUCCESS, FETCH_XERO_CONTACTS_FAILURE } from 'actions/xero/contacts/fetch';
import { FETCH_XERO_TRIAL_BALANCE_START, FETCH_XERO_TRIAL_BALANCE_SUCCESS } from 'actions/xero/trial/fetch';
import { CREATE_XERO_INVOICE_START, CREATE_XERO_INVOICE_SUCCESS, CREATE_XERO_INVOICE_FAILURE } from 'actions/xero/invoices/create';
import { FETCH_XERO_EXPORTS_START, FETCH_XERO_EXPORTS_SUCCESS, FETCH_XERO_EXPORTS_FAILURE } from 'actions/xero/exports/fetch';
import { LOGOUT } from 'actions/session/logout';


const initialState = {
  isLoading: false,
  error: '',

  // TODO: this should change, is used for history and generation data.
  data: [],

  // contacts that come directly from xero
  contacts: [],

  // store the PublicCredentials object which contains data about their Xero
  // creds & session.
  credentials: {},

  // trial account shows the status of each of our accounts.
  trialBalance: {},

  // Array of metadata of the user's xero export history.
  exports: [],

  // TODO: this should change to be accounts.
  properties: [],

  oauthUrl: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_XERO_CONTACTS_START:
    case FETCH_XERO_ACCOUNTS_START:
    case FETCH_XERO_TRIAL_BALANCE_START:
    case CREATE_XERO_INVOICE_START:
    case DELETE_XERO_CREDENTIALS_START:
    case FETCH_XERO_EXPORTS_START:
      return { ...state, isLoading: true, error: '' };
    case DELETE_XERO_CREDENTIALS_SUCCESS:
      return initialState;
    case FETCH_XERO_EXPORTS_SUCCESS:
      return { ...state, isLoading: false, exports: action.data };
    case FETCH_XERO_EXPORTS_FAILURE:
    case FETCH_XERO_ACCOUNTS_FAILURE:
    case DELETE_XERO_CREDENTIALS_FAILURE:
      return { ...state, isLoading: false, error: action.error || {} };
    case CREATE_XERO_CREDENTIALS_SUCCESS:
    case VERIFY_XERO_CREDENTIALS_SUCCESS:
      return { ...state, error: '' };
    case CREATE_XERO_INVOICE_SUCCESS:
      return { ...state, error: '', isLoading: false };
    case CREATE_XERO_CREDENTIALS_FAILURE:
      return { ...state, error: action.data.error };
    case VERIFY_XERO_CREDENTIALS_FAILURE:
      return { ...state, error: 'Verification code is invalid.' };
    case CREATE_XERO_INVOICE_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case FETCH_XERO_CONTACTS_FAILURE:
      return { ...state, isLoading: false, error: 'Could not fetch xero contacts.' };
    case FETCH_XERO_ACCOUNTS_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        properties: action.data,
      };
    case FETCH_XERO_CONTACTS_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        contacts: action.data,
      };
    case FETCH_XERO_TRIAL_BALANCE_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        trialBalance: action.data,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
