import React from 'react';
import { connect } from 'react-redux';
import {
  Form, FormFeedback, FormGroup, Input, FormText,
} from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import LoadButton from 'components/buttons/LoadButton';
import { sendEmail, CREATE_EMAIL_SUCCESS, CREATE_EMAIL_FAILURE,
       } from 'actions/emails/create';


const ContactForm = props => {
  const { status, errors, touched, handleSubmit, isSubmitting } = props;

  const renderStatusError = () => {
    if (!status || !status.error)
      return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder="name"
          name="name"
          tag={Field}
          invalid={errors.name && touched.name}
        />
        <FormFeedback>{errors.name}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="email"
          name="email"
          tag={Field}
          invalid={errors.email && touched.email}
        />
        <FormFeedback>{errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="type"
          component="select"
          name="type"
          tag={Field}
          invalid={errors.type && touched.type}
        >
          <option value="feedback">Feedback</option>
          <option value="sales">Sales</option>
        </Input>
        <FormFeedback>{errors.type}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="subject"
          name="subject"
          tag={Field}
          invalid={errors.subject && touched.subject}
        />
        <FormFeedback>{errors.subject}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          type="textarea"
          placeholder="body"
          name="body"
          tag={Field}
          component="textarea"
          invalid={errors.body && touched.body}
        />
        <FormFeedback>{errors.body}</FormFeedback>
      </FormGroup>
      {renderStatusError()}
      <LoadButton
        block
        isLoading={isSubmitting}
        type="submit"
      >
        Send
      </LoadButton>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    name: '',
    email: '',
    type: 'feedback',
    subject: '',
    body: '',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string()
      .required('Required')
      .email('Invalid email'),
    type: Yup.string().required('Required'),
    subject: Yup.string()
      .required('Required')
      .min(10, 'Subject must be at least 10 characters'),
    body: Yup.string().required('Required'),
  }),

  handleSubmit: async (values, { props, setSubmitting, resetForm, setStatus }) => {
    const payload = { ...values };
    // Set addional email data.
    payload.replyTo = values.email;

    let bodyPrefix = '';
    if (values.type === 'feedback') {
      bodyPrefix = `Feedback received from ${values.name} (${values.email}).`;
    } else if (values.type === 'sales') {
      bodyPrefix = `Sales inquiry received from ${values.name} (${values.email}).`;
    }

    // Have to put values.body below without indent or email is messed up.
    payload.body = `${bodyPrefix}

${values.body}`;

    const action = await props.sendEmail(payload);
    if (action.type === CREATE_EMAIL_SUCCESS) {
      toast("Thank you, we'll be with you ASAP");
      resetForm();
    } else if (action.type === CREATE_EMAIL_FAILURE) {
      setStatus({ error: 'Oops! Could not send email.' });
    }
    setSubmitting(false);
  },
})(ContactForm);

export default connect(null, { sendEmail })(FormikForm);
