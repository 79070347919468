import React from 'react';
import { connect } from 'react-redux';
import {
  InputGroup, InputGroupAddon, InputGroupText, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { searchHelp, clearHelpSearch } from 'actions/help/search';
import './style.scss';

class HelpSearch extends React.Component {
  // Search the help articles for what the user is looking for.
  onSearchChange = (event) => {
    const searchText = event.target.value;
    const { searchHelp } = this.props;
    searchHelp(searchText);
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.props.clearHelpSearch();
    }
  }

  render() {
    const size = this.props.size || 'md';

    const renderClearButton = () => {
      if (this.props.help.searchTerm.length > 0) {
        return (
          <InputGroupAddon addonType="append" onClick={this.props.clearHelpSearch}>
            <InputGroupText>
              <FontAwesomeIcon icon={faTimes} />
            </InputGroupText>
          </InputGroupAddon>
        );
      }
      return null;
    };

    return (
      <div className="help-search">
        <InputGroup size={size}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Search all help articles..."
            value={this.props.help.searchTerm}
            onChange={this.onSearchChange}
            onKeyDown={this.handleKeyDown}
          />
          {renderClearButton()}
        </InputGroup>
      </div>
    );
  }
}

HelpSearch.propTypes = {
  size: PropTypes.string,
};

const mapStateToProps = ({ help }) => ({ help });
export default connect(mapStateToProps, { searchHelp, clearHelpSearch })(HelpSearch);
