import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export default function FeaturesList({ features }) {
  // Display an array of features.
  const renderFeatures = () => features.map((feature, index) => {
    return <p key={index}><FontAwesomeIcon icon={faCheck} /> {feature}</p>;
  });

  return (
    <div className="features-container py-3">
      {renderFeatures()}
    </div>
  );
};
