import axios from 'axios';
import { API_URL } from 'config';


export const DELETE_PROPERTY_START = 'DELETE_PROPERTY_START';
const deletePropertyStart = id => ({ type: DELETE_PROPERTY_START, id });

export const deleteProperty = id =>
  // optimistically delete the property (remove before server response)
  (dispatch) => {
    dispatch(deletePropertyStart(id));
    const url = `${API_URL}properties/${id}/`;
    return axios.delete(url);
  };
