import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LoadingContainer from 'components/LoadingContainer';
import { verifyXeroCredentials, VERIFY_XERO_CREDENTIALS_SUCCESS,
       } from 'actions/xero/credentials/verify';
import './style.scss';

class XeroCallbackRoute extends React.Component {
  /* This route is called as the callback url from Xero after the user connects
     their account.
  */

  state = { error: null }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const oauthVerifier = query.get('code');

    const action = await this.props.verifyXeroCredentials({ verificationCode: this.props.location.search });
    if (action.type === VERIFY_XERO_CREDENTIALS_SUCCESS) {
      this.props.history.push('/dashboard/accounts');
      console.log(">>>>>>>>>move to dashboard<<<<<<<<<<<<<")
    }
    else {
      console.log(">>>>>>>>>error verify<<<<<<<<<<<<<")
      this.setState({ error: action.data || 'Error verifying Xero credentials.' });
    }
  }

  render() {
    if (this.state.error)
      return (
        <div className="xero-callback-container p-5 text-center">
          <h5>An error occured while trying to verify your Xero account.</h5>
          <p className="text-danger p-2">"{this.state.error}"</p>
          <Link to="/dashboard/accounts">Back to dashboard</Link>
        </div>
      );

    return (
      <LoadingContainer text="Verifying Xero auth..." />
    );
  }
}

export default connect(null, { verifyXeroCredentials })(XeroCallbackRoute);
