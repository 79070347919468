import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import DashboardContainer from 'components/dashboard/Container';

import ContactForm from 'components/forms/Contact';

class ContactRoute extends React.Component {
  render() {
    return (
      <div className="help-route">
        <Container>
          <Row className="mb-4">
            <Col className="text-center">
              <h3>Submit contact email</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <DashboardContainer>
                <ContactForm />
              </DashboardContainer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactRoute;
