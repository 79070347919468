import React from 'react';


const PaymentSourceTitle = props => {
  return (
    <div className="header-container text-center">
      <h2 className="my-5">
        {props.children}
      </h2>
    </div>
  );
};


export default PaymentSourceTitle;
