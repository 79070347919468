import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Form, FormText, FormGroup, Input, FormFeedback, Label,
} from 'reactstrap';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import LoadButton from 'components/buttons/LoadButton';
import { registerUser, REGISTER_SUCCESS, REGISTER_FAILURE } from 'actions/users/register';
import { fetchMe } from 'actions/users/fetch';

const RegisterForm = props => {
  const {
    status,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    values,
  } = props;

  const renderStatusError = () => {
    if (!status || !status.error) return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          name="email"
          placeholder="email"
          tag={Field}
          invalid={errors.email && touched.email}
        />
        <FormFeedback>{errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          name="firstName"
          placeholder="first name"
          tag={Field}
          invalid={errors.firstName && touched.firstName}
        />
        <FormFeedback>{errors.firstName}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          name="lastName"
          placeholder="last name"
          tag={Field}
          invalid={errors.lastName && touched.lastName}
        />
        <FormFeedback>{errors.lastName}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          name="password1"
          placeholder="password"
          tag={Field}
          invalid={errors.password1 && touched.password1}
          autoComplete="password"
        />
        <FormFeedback>{errors.password1}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          placeholder="password (repeat)"
          name="password2"
          tag={Field}
          invalid={errors.password2 && touched.password2}
          autoComplete="password"
        />
        <FormFeedback>{errors.password2}</FormFeedback>
      </FormGroup>
      <FormGroup check className="mb-3">
        <Label check>
          <Input
            type="checkbox"
            tag={Field}
            name="hasAgreedTos"
            checked={values.hasAgreedTos}
            invalid={errors.hasAgreedTos && touched.hasAgreedTos}
          />
          {' '}I accept the{' '}
          <Link to="/terms-of-service" target="_blank">Terms of Service</Link>.
          <FormFeedback>{errors.hasAgreedTos}</FormFeedback>
        </Label>
      </FormGroup>
      {renderStatusError()}
      <LoadButton block color="primary" isLoading={isSubmitting} type="submit">
        {props.type === 'trial' ? 'Start trial' : 'Register'}
      </LoadButton>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    email: '',
    firstName: '',
    lastName: '',
    password1: '',
    password2: '',
    hasAgreedTos: false,
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    password1: Yup.string().required('Required').min(6),
    password2: Yup.string().required('Required').min(6),
    hasAgreedTos: Yup.boolean().oneOf([true], 'Must accept Terms of Service.'),
  }),

  handleSubmit: async (values, { props, setStatus, setSubmitting, resetForm }) => {
    if (props.type)
      values.type = props.type;

    const action = await props.registerUser(values);
    setSubmitting(false);
    if (action.type === REGISTER_FAILURE) {
      const error = Object.values(action.data)[0][0];
      setStatus({ error });
    } else if (action.type === REGISTER_SUCCESS) {
      resetForm();
      await props.fetchMe();
      props.history.push('/complete-registration');
    }
  },
})(RegisterForm);

const routedComponent = withRouter(FormikForm);
export default connect(null, { registerUser, fetchMe })(routedComponent);
