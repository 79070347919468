import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import LoadButton from 'components/buttons/LoadButton';
import ModalCancelButton from '../CancelButton';
import { deleteXeroCredentials } from 'actions/xero/credentials/delete';
import { logoutUser } from 'actions/session/logout';

class RemoveXeroModal extends React.Component {
  deleteXeroCredentials = async () => {
    const { deleteXeroCredentials, logoutUser, history, toggle } = this.props;
    await deleteXeroCredentials();
    logoutUser();
    history.push('/');
    toggle();
  }

  render() {
    const { deleteXeroCredentials, logoutUser, history, staticContext, ...rest } = this.props;

    return (
      <Modal { ...rest } className="remove-xero-modal">
        <ModalHeader>
          Remove Xero Integration
        </ModalHeader>
        <ModalBody>
          <p>By removing your Xero integration, to continue using bnbsync you'll need to login and
             reconnect your Xero account. All your current data will be saved.</p>
          <p>Are you sure you want to disconnect bnbsync from your Xero account?</p>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <LoadButton
            className="btn-modal-save"
            onClick={this.deleteXeroCredentials}
            isLoading={this.props.xero.isLoading}
          >
            Yes
          </LoadButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ xero }) => ({ xero });
const ConnectedComponent = connect(mapStateToProps, {
  deleteXeroCredentials, logoutUser,
})(RemoveXeroModal);
export default withRouter(ConnectedComponent);
