import {
  GENERATE_XERO_IMPORT_START, GENERATE_XERO_IMPORT_SUCCESS,
  GENERATE_XERO_IMPORT_FAILURE, RESET_XERO_IMPORT,
} from 'actions/generations/create';
import { TOGGLE_INVOICE_EXPORTED_START } from 'actions/invoices/update';
import { FETCH_GENERATION_START, FETCH_GENERATION_SUCCESS } from 'actions/generations/fetch';
import { UPDATE_PROPERTY_SUCCESS } from 'actions/properties/update';
import { ADD_PROPERTY_LISTING_START, ADD_PROPERTY_LISTING_SUCCESS } from 'actions/properties/addListing';
import { DELETE_TRANSACTION_START } from 'actions/transactions/delete';
import { CREATE_TRANSACTION_START, CREATE_TRANSACTION_SUCCESS, CREATE_TRANSACTION_FAILURE } from 'actions/transactions/create';
import { TOGGLE_INVOICES_EXPORT_START, TOGGLE_INVOICES_EXPORT_SUCCESS } from 'actions/generations/toggleExport';
import { LOGOUT } from 'actions/session/logout';


const initialState = {
  isLoading: false,
  data: {},
  error: '',
};

export default (state = initialState, action) => {
  // Reducer to handle the data about bnbsync generations, invoices and transactions.
  const currentGeneration = { ...state.data };
  let newGeneration = {};
  let newInvoices = [];
  let newTransactions = [];

  switch (action.type) {
    case GENERATE_XERO_IMPORT_START:
    case TOGGLE_INVOICES_EXPORT_START:
    case FETCH_GENERATION_START:
    case ADD_PROPERTY_LISTING_START:
    case CREATE_TRANSACTION_START:
      return { ...state, isLoading: true, error: '' };
    case TOGGLE_INVOICE_EXPORTED_START:
      // update if an invoice will be exported
      newInvoices = currentGeneration.invoices.map((invoice) => {
        if (invoice.id === action.id) return { ...invoice, isExported: action.payload.isExported };
        return invoice;
      });
      newGeneration = { ...state.data, invoices: newInvoices };
      return { ...state, data: newGeneration };
    case DELETE_TRANSACTION_START:
      // optimistically delete a transaction by filtering it out.
      newInvoices = currentGeneration.invoices.map((invoice) => {
        newTransactions = invoice.transactions.filter((transaction) => {
          return transaction.id !== action.transactionId;
        });

        return { ...invoice, transactions: newTransactions };
      });

      newGeneration = { ...state.data, invoices: newInvoices };

      return { ...state, data: newGeneration };
    case UPDATE_PROPERTY_SUCCESS:
    case ADD_PROPERTY_LISTING_SUCCESS:
      if (!currentGeneration.invoices) return state;

      // update the property listings data in the generation data.
      newInvoices = currentGeneration.invoices.map((invoice) => {
        // go through each invoice and see if it has any properties that need
        // to be updated
        newTransactions = invoice.transactions.map((transaction) => {
          const { property } = transaction;
          if ((property && property.id === action.data.id)
              || (action.listing && action.listing === transaction.listing)) {
            /* update existing accounts with this changed property listing
               or if the listing on the transaction matches.
            */
            transaction.property = action.data;

            /* Need to update the commission amount too since the commission
               percent can change. */
            const commissionPercent = transaction.property.commission;
            const commissionAmount = commissionPercent * transaction.unitAmount;
            transaction.commissionAmount = Math.round(commissionAmount * 100) / 100;
          }
          return transaction;
        });

        return { ...invoice, transactions: newTransactions };
      });
      newGeneration = { ...state.data, invoices: newInvoices };
      return { ...state, data: newGeneration, isLoading: false };
    case GENERATE_XERO_IMPORT_SUCCESS:
    case TOGGLE_INVOICES_EXPORT_SUCCESS:
    case FETCH_GENERATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: '',
      };
    case CREATE_TRANSACTION_SUCCESS: {
      newGeneration = { ...currentGeneration };
      // find the invoice that is affected
      const updatedInvoice = newGeneration.invoices.find((invoice) => {
        return invoice.id === action.data.invoice;
      });

      // add the new transaction into its generation invoice
      updatedInvoice.transactions = [
        ...updatedInvoice.transactions,
        action.data,
      ];

      return { ...state, data: newGeneration, isLoading: false };
    }
    case RESET_XERO_IMPORT:
      return { ...state, data: {}, error: '' };
    case GENERATE_XERO_IMPORT_FAILURE:
      return { ...state, isLoading: false, error: action.error || {} };
    case CREATE_TRANSACTION_FAILURE:
      return { ...state, isLoading: false, error: action.error.detail };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
