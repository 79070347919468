import React from 'react';

import './style.scss';

const DashboardContainer = props =>
  // Simple container that styles sections of the dashboard.
  (
    <div {...props} className={`dashboard-container ${props.className}`}>
      {props.children}
    </div>
  );


export default DashboardContainer;
