import React from 'react';
import {
  Form, FormFeedback, FormGroup, Input, FormText,
} from 'reactstrap';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import LoadButton from 'components/buttons/LoadButton';
import ModalCancelButton from 'components/modals/CancelButton';
import { hideModal } from 'actions/modals';
import { createTransaction, CREATE_TRANSACTION_SUCCESS, CREATE_TRANSACTION_FAILURE } from 'actions/transactions/create';
import { transactionTypes } from 'utils/xero';

class XeroTransactionForm extends React.Component {
  // Create a xero transaction record as part of the invoice.

  getAllListings = () =>
    // Return an array of all property listings.
    this.props.properties.data.map(property => property.listings).flat()

  render() {
    const {
      status, errors, touched, handleSubmit, isSubmitting,
    } = this.props;

    const renderStatusError = () => {
      if (!status || !status.error) return null;

      return (
        <FormText color="danger" className="text-center mb-3">
          {status.error}
        </FormText>
      );
    };

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            placeholder="Confirmation Number"
            name="confirmationNumber"
            tag={Field}
            invalid={errors.confirmationNumber && touched.confirmationNumber}
          />
          <FormFeedback>{errors.confirmationNumber}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Unit Amount"
            name="unitAmount"
            tag={Field}
            invalid={errors.unitAmount && touched.unitAmount}
          />
          <FormFeedback>{errors.unitAmount}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Transaction type"
            component="select"
            name="type"
            tag={Field}
            invalid={errors.type && touched.type}
          >
            {transactionTypes.map(type => (
              <option key={type.value} value={type.value}>
                {type.display}
              </option>
            ))}
          </Input>
          <FormFeedback>{errors.type}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Account Code"
            name="accountCode"
            tag={Field}
            invalid={errors.accountCode && touched.accountCode}
          />
          <FormFeedback>{errors.accountCode}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Listing"
            component="select"
            name="listing"
            tag={Field}
            invalid={errors.listing && touched.listing}
          >
            <option value="">Select Listing (optional)</option>
            {this.getAllListings().map(listing => <option key={listing}>{listing}</option>)}
          </Input>
          <FormFeedback>{errors.listing}</FormFeedback>
        </FormGroup>
        {renderStatusError()}
        <div className="button-container text-right">
          <ModalCancelButton />
          <LoadButton
            type="submit"
            color="primary"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
                Submit
          </LoadButton>
        </div>
      </Form>
    );
  }
}

XeroTransactionForm.propTypes = {
  invoiceId: PropTypes.number.isRequired,
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    confirmationNumber: '',
    unitAmount: '',
    listing: '',
    cleaningFee: '',
    accountCode: '',
    type: 'reservation',
  }),

  validationSchema: Yup.object().shape({
    confirmationNumber: Yup.string().required('Required'),
    unitAmount: Yup.number().typeError('Enter valid currency amount').required('Required'),
    cleaningFee: Yup.number(),
    type: Yup.string().required('Required'),
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const payload = {
      invoice: props.invoiceId,
      ...values,
    };

    // Only attach value if it has something.
    Object.keys(payload).forEach((key) => {
      if (payload[key] === '') delete payload[key];
    });

    const action = await props.createTransaction(payload);
    setSubmitting(false);
    if (action.type === CREATE_TRANSACTION_SUCCESS) {
      toast('Transaction created');
      props.hideModal();
    } else if (action.type === CREATE_TRANSACTION_FAILURE) {
      return setStatus({ error: 'Error creating transaction' });
    }
  },
})(XeroTransactionForm);

const mapStateToProps = ({ properties }) => ({ properties });
export default connect(mapStateToProps, { hideModal, createTransaction })(FormikForm);
