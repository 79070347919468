import axios from 'axios';
import { API_URL } from 'config';


export const ADD_PROPERTY_LISTING_START = 'ADD_PROPERTY_LISTING_START';
const addPropertyListingStart = () => ({ type: ADD_PROPERTY_LISTING_START });

export const ADD_PROPERTY_LISTING_SUCCESS = 'ADD_PROPERTY_LISTING_SUCCESS';
const addPropertyListingSuccess = (data, listing, replace) => ({
  type: ADD_PROPERTY_LISTING_SUCCESS, data, listing, replace,
});

export const ADD_PROPERTY_LISTING_FAILURE = 'ADD_PROPERTY_LISTING_FAILURE';
const addPropertyListingFailure = errors => ({
  type: ADD_PROPERTY_LISTING_FAILURE, errors,
});

export const addPropertyListing = (propertyId, payload) =>
  // expects listing name in the payload
  // { listing: <string:required>, replace: <bool:optional> }
  (dispatch) => {
    dispatch(addPropertyListingStart());
    const url = `${API_URL}properties/${propertyId}/listings/`;

    return axios.post(url, payload)
      .then((success) => {
        const { data } = success;
        const { listing, replace } = payload;
        return dispatch(addPropertyListingSuccess(data, listing, replace));
      },
      error => dispatch(addPropertyListingFailure(error.response.data)));
  };
