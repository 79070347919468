import axios from 'axios';

import { XERO_BASE_URL } from '../xero';


export const TOGGLE_INVOICE_EXPORTED_START = 'TOGGLE_INVOICE_EXPORTED_START';
const toggleInvoiceExportedStart = (id, payload) => (
  { type: TOGGLE_INVOICE_EXPORTED_START, id, payload }
);

export const updateXeroInvoice = (id, payload) => (dispatch) => {
  /* for toggling the invoice exported switch we're just going to
       optimistically update the state and assume server could update.
    */
  dispatch(toggleInvoiceExportedStart(id, payload));
  const url = `${XERO_BASE_URL}invoices/${id}/`;
  return axios.patch(url, payload);
};
