import React from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingContainer from 'components/LoadingContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import Router from 'routes/Router';
import ModalContainer from 'components/modals/Container';
import { fetchMe, FETCH_ME_FAILURE } from 'actions/users/fetch';
import { logoutUser } from 'actions/session/logout';
import { environment } from 'config';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toggle/style.css';
import './styles/app.scss';

class App extends React.Component {
  state = { isReady: false }

  async componentDidMount() {
    // Load user session data from persisted state.
    const { token } = this.props.session;
    if (token) {
      // Do these things on app load if the user is authenticated.
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      const action = await this.props.fetchMe();
      if (action.type === FETCH_ME_FAILURE) {
        // Something went wrong, probably invalid token.
        this.props.logoutUser();
        this.props.history.push('/');
      }
    }

    // Report errors if in production.
    if (environment === 'production') {
      window.Raven.config(
        'https://ff6b793719c345cba0c7a2e43ca20209@sentry.io/1396573',
      ).install();
    }

    this.setState({ isReady: true });
  }

  render() {
    if (!this.state.isReady)
      return <LoadingContainer />;

    const { token } = this.props.session;
    const isAuthenticated = !!(token && token.length);

    return (
      <div className="app">
        <ErrorBoundary>
          <Router isAuthenticated={isAuthenticated} />
          <ToastContainer
            closeButton={false}
            position="bottom-right"
            hideProgressBar
            className="toast-body"
            autoClose={3000}
          />
          <ModalContainer />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
const connectedComponent = connect(mapStateToProps, { fetchMe, logoutUser })(App);
export default withRouter(connectedComponent);
