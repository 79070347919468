import axios from 'axios';

import { XERO_BASE_URL } from '../index';

export const FETCH_XERO_TRIAL_BALANCE_START = 'FETCH_XERO_TRIAL_BALANCE_START';
const fetchXeroTrialBalanceStart = () => ({
  type: FETCH_XERO_TRIAL_BALANCE_START,
});

export const FETCH_XERO_TRIAL_BALANCE_SUCCESS = 'FETCH_XERO_TRIAL_BALANCE_SUCCESS';
const fetchXeroTrialBalanceSuccess = data => ({
  type: FETCH_XERO_TRIAL_BALANCE_SUCCESS,
  data,
});

export const fetchXeroTrialBalance = () => ((dispatch) => {
  const url = `${XERO_BASE_URL}trial-balance/`;
  dispatch(fetchXeroTrialBalanceStart());

  return axios.get(url)
    .then(response => dispatch(fetchXeroTrialBalanceSuccess(response.data)));
});
