export const downloadFile = (name, url) => {
  /* Create a link and click on it I suppose, JS is weird.
     https://stackoverflow.com/a/15832662/2548934 */
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const currencyLocaleMap = {
  USD: 'en-US',
  EUR: 'de-DE',
  JPY: 'ja-JP',
  GBP: 'en-GB',
  AUD: 'en-AU',
  CAD: 'en-CA',
  CHF: 'de-CH',
  CNY: 'zh-CN',
  SEK: 'sv-SE',
  NZD: 'en-NZ',
  IDR: 'id-ID',
};
export const formatCurrency = (decimal, currencyCode='AUD') => {
  const locale = currencyLocaleMap[currencyCode] || 'en-AU';
  // Return a decimal value as a properly formatted currency. For now just use AUD.
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  if (currencyCode === 'JPY') return `${formatter.format(decimal).replace('JPY', '¥')}`;
  if (currencyCode === 'IDR') return `${formatter.format(decimal).replace(/[.,]/g, (match) => {return match === '.' ? ',' : '.';})}`;
  if (currencyCode === 'AUD') return `${formatter.format(decimal)} ${currencyCode}`;
  return `${formatter.format(decimal)}`;
};

export const htmlToText = (html) => {
  // Strip out the html tags and leave only the text.
  html.replace(/<(?:.|\n)*?>/gm, '');
};
