import { FETCH_CURRENCIES_SUCCESS } from 'actions/currencies/fetch';

const initialState = {
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_SUCCESS:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
