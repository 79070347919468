import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';

import { voteArticle } from 'actions/help/vote';
import './style.scss';

class ArticleFeedback extends React.Component {
  // Show the feedback section for if the user found this article useful.
  state = {
    hasVoted: false,
  }

  registerVote = (upvote) => {
    // Upvote: boolean determining if it's an upvote or downvote.
    this.setState({ hasVoted: true });
    this.props.voteArticle({ articleId: this.props.article.id, upvote });
  }

  render() {
    if (this.state.hasVoted) {
      return (
        <h5 className="text-center mb-0">Thank you for your feedback.</h5>
      );
    }

    return (
      <div className="article-feedback-container">
        Did you find this article useful?
        <div className="button-container">
          <Button color="success" onClick={() => this.registerVote(true)}>
            <FontAwesomeIcon icon={faThumbsUp} />
          </Button>
          <Button color="danger" onClick={() => this.registerVote(false)}>
            <FontAwesomeIcon icon={faThumbsDown} />
          </Button>
        </div>
      </div>
    );
  }
}

ArticleFeedback.propTypes = {
  article: PropTypes.object.isRequired,
};

export default connect(null, { voteArticle })(ArticleFeedback);
