import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleFull } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';

import './style.scss';


class DotStatus extends React.Component {
  /* Return a row of dots that show a given amount of solid dots marking the
     status of some process. */

  render() {
    const { step, max, ...rest } = this.props;
    const renderDots = () => [...Array(max)].map((_, iteration) => {
      const icon = step > iteration ? faCircleFull : faCircleEmpty;
      return <FontAwesomeIcon icon={icon} key={iteration} />;
    });

    return (
      <div { ...rest } className={`dot-status-container ${rest.className}`}>
        {renderDots()}
      </div>
    );
  }
}

DotStatus.propTypes = {
  step: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default DotStatus;
