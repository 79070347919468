import React from 'react';
import {
  Navbar, NavbarBrand, NavLink, Nav, NavItem,
} from 'reactstrap';

import TermSection from 'components/TermSection';
import Logo from 'components/Logo';
import './style.scss';

class TermsOfServiceRoute extends React.Component {
  render() {
    return (
      <div className="terms-of-service-route">
        <Navbar fixed>
          <NavbarBrand>
            <Logo icon={1} style={{ height: 25 }} />
            <span>Bnbsync General Terms of Service</span>
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/dashboard/accounts/">Dashboard</NavLink>
            </NavItem>
          </Nav>
        </Navbar>

        <main>
          <p className="last-updated"><i>Last updated: 21 March 2019</i></p>
          <p>
            These General Terms of Service (“General Terms”) are a legal agreement between you (“you,” “your”) and My Easy Software Solutions Pty Ltd T/A Bnbsync.io (ABN 87 631 127 467) (”Bnbsync,” “we,” “our” or “us”) and govern your use of Bnbsync’s services, including web based applications, websites, and other products and services (collectively, the “Services”). If you are using the Services on behalf of a business, you represent to us that you have authority to bind that business to these terms, and you accept these terms as an authorised person on behalf of that business. By using any of the Services, you agree to these General Terms and any policies referenced within (“Policies”), including terms that limit our liability (see Section 18). In particular, Bnbsync draws your attention to Section 21, which contains provisions that require arbitration for any potential legal dispute.
          </p>

          <p>
            By using the Services, you also agree to any additional terms specific to the Services you use (“Additional Terms”), such as those listed below or otherwise incorporated pursuant to Section 3, which become part of your agreement with us (collectively, the “Terms”). You should read all of our terms carefully.
          </p>

          <TermSection order={1} title="Privacy">
            You consent to Bnbsync’s Privacy Policy, which explains how we collect, use and protect the personal information you provide to us, and our right to suspend or terminate the Bnbsync Account of anyone who provides inaccurate, untrue or incomplete information or who fails to comply with the account registration requirements.
          </TermSection>
          <TermSection order={2} title="Bnbsync Account Registration">
            You must create an account with us (a “Bnbsync Account”) to use the Services. During registration we will ask you for information, which may include, without limitation, your name You must create an account with us (a “Bnbsync Account”) to use the Services. During registration we will ask you for information, which may include, without limitation, your name and other personal information. You must provide accurate and complete information in response to our questions, and you must keep that information current. You are fully responsible for all activities that occur under your Bnbsync Account, including for any actions taken by persons to whom you have granted access to the Bnbsync Account. We reserve the right to change the account type, suspend or terminate the Bnbsync Account of anyone who provides inaccurate, untrue, or incomplete information, or who fails to comply with the account registration requirements, where doing so is reasonably necessary to protect Bnbsync’s legitimate business interests.
          </TermSection>
          <TermSection order={3} title="Revisions, Disclosures and Notices">
            <p>
              By providing reasonable prior notice to you, we may amend the Terms at any time, including to incorporate new Additional Terms. We may provide such notice by posting the relevant revised Terms (each, a “Revised Version”) on our website. If the revised Terms include any material change to your existing rights and obligations or the material terms of the Services, we will alert you by email at least ten (10) days in advance. The Revised Version will be effective as of the time it is posted, but will not apply retrospectively. You can stop using the Services at any time by deactivating your Bnbsync Account without penalty. Your continued use of the Services after notice of a Revised Version becomes effective shall constitute your acceptance of such Revised Version. Any Dispute (as defined in Section 21) that arose before the changes will be governed by the Terms in place when the Dispute arose.
            </p>
            <p>
              You agree to Bnbsync’s Consent. We may provide disclosures and notices required by Law (as defined in Section 23) and other information about your Bnbsync Account to you electronically, by posting it on our website, pushing notifications through the Services, or emailing it to the email address listed in your Bnbsync Account. If the disclosure, notice or information includes any material change to your existing rights and obligations or the Terms, we will alert you by email. Electronic disclosures and notices have the same meaning and effect as if we had provided you with paper copies. Such disclosures and notices are considered received by you within twenty-four (24) hours of the time posted to our website, or within twenty-four (24) hours of the time emailed to you unless we receive notice that the email was not delivered. If you wish to withdraw your consent to receiving electronic communications, contact Bnbsync Support. If we are not able to support your request, you may need to terminate your Bnbsync Account.
            </p>
          </TermSection>
          <TermSection order={4} title="Restrictions">
            <p>
              You may not, nor may you permit any third party, directly or indirectly, to:
            </p>
            <ol>
              <li>access or monitor any material or information on any Bnbsync system using any manual process or robot, spider, scraper, or other automated means, unless you have separately executed a written agreement with Bnbsync referencing this;</li>
              <li>except to the extent that any restriction is expressly prohibited by Law, violate the restrictions in any robot exclusion headers on any Service, work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services;</li>
              <li>perform or attempt to perform any actions that would interfere with the proper working of the Services, prevent access to or use of the Services by our other customers, or impose an unreasonable or disproportionately large load on our infrastructure;</li>
              <li>copy, reproduce, alter, modify, create derivative works, publicly display, republish, upload, post, transmit, resell or distribute in any way material, information or Services from Bnbsync;</li>
              <li>permit any third party to use and benefit from the Services via a rental, lease, timesharing, service bureau or other arrangement;</li>
              <li>transfer any rights granted to you under the Terms;</li>
              <li>use the Services for any illegal activity or other Bnbsync users, our partners, or Bnbsync to harm; or</li>
              <li>otherwise use the Services except as expressly allowed under these Terms.</li>
            </ol>
            <p>
              If we reasonably suspect that your Bnbsync Account has been used for an unauthorised, illegal, or criminal purpose, you give us express authorisation to share information about you, your Bnbsync Account, and any of your transactions with law enforcement authorities.
            </p>
          </TermSection>
          <TermSection order={5} title="Compatible Mobile Devices and Third Party Carriers">
            <p>
              We do not warrant that the Services will be compatible with your computer, mobile device or carrier. Your use of the Services may be subject to the terms of your agreements with your mobile device manufacturer or your carrier. You may not use a modified device to use the Services if the modification is contrary to the manufacturer’s software or hardware guidelines, including disabling hardware or software controls—sometimes referred to as “jail broken.”
            </p>
          </TermSection>
          <TermSection order={6} title="Your Content">
            <p>
              The Services may include functionality for uploading or providing suggestions, recommendations, feedback, stories, photos, documents, logos, products, loyalty programs, promotions, advertisements and other materials or information (“Content”). You represent to us that you have authority to use the Content in the relevant manner and to grant us the rights below.
            </p>
            <p>
              You grant us and our subsidiaries, affiliates, and successors a worldwide, non-exclusive, royalty-free, fully-paid, transferable, irrevocable, perpetual and sub-licensable right to use, reproduce, modify, adapt, publish, prepare derivative works, distribute, publicly perform, and publicly display your Content throughout the world in any media for any reason, including to provide, promote and incorporate into the Services, with or without attribution or reference to you and even where the treatment of your Content may be considered derogatory. You acknowledge that this consent is irrevocable, is a genuine consent under the Copyright Act 1968 (Cth) and has not been induced by duress or any false or misleading statement. You retain all rights in your Content, subject to the rights you granted to us in these General Terms. You may modify or remove your Content via your Bnbsync Account or by terminating your Bnbsync Account, but your Content may persist in historical, archived or cached copies and versions available on or through the Services.
            </p>
            <p>
              You will not upload or provide Content or otherwise post, transmit, distribute, or disseminate through the Services any material that: (a) is false, misleading, unlawful, obscene, indecent, lewd, pornographic, defamatory, libelous, threatening, harassing, hateful, abusive, or inflammatory; (b) encourages conduct that would be considered a criminal offence or gives rise to civil liability; (c) breaches or infringes any duty toward or rights of any person or entity, including rights of publicity, privacy or intellectual property; (d) contains corrupted data or any other harmful, disruptive, or destructive files; (e) advertises products or services competitive with Bnbsync’s or its partners’ products and services, as determined by us in our reasonable discretion; or (f) is objectionable, restricts or inhibits any person or entity from using or enjoying any portion of the Services, or which may expose Bnbsync, its affiliates or its customers to harm or liability of any nature, as determined by us in our reasonable discretion.
            </p>
            <p>
              Although we have no obligation to monitor any Content, we have discretion, acting reasonably, to remove Content without notice if you breach any of the restrictions outlined above. Bnbsync may also monitor such Content to detect and prevent fraudulent activity or violations of these Terms. You understand that by using the Services, you may be exposed to Content that is offensive, indecent, or objectionable. We take no responsibility and assume no liability for any Content, including any loss or damage to any of your Content, except to the extent arising from fraud, negligence or willful misconduct of Bnbsync or our directors or officers.
            </p>
          </TermSection>
          <TermSection order={7} title="Intellectual Property Infringement">
            <p>
              We respect the intellectual property rights of others and ask you to do the same. We have adopted a Copyright and Trademark Policy regarding third-party claims that your material infringes the rights of others. We respond to all valid notices of such infringement, and our policy is to suspend or terminate the accounts (as described in Section 11) of repeat infringers.
            </p>
          </TermSection>
          <TermSection order={8} title="Security">
            <p>
              We have implemented technical and organisational measures designed to secure information about you from accidental loss and from unauthorised access, use, alteration, or disclosure. However, we cannot guarantee that unauthorised third parties will never be able to defeat those measures or use information about you for improper purposes. You provide information about you at your own risk.
            </p>
            <p>
              You are responsible for safeguarding your password and for restricting access to the Services from your compatible mobile device(s) and computer(s). You will immediately notify us of any unauthorised use of your password or Bnbsync Account or any other breach of security. Notwithstanding Sections 21 and 22, in the event of any dispute between two or more parties as to account ownership, we will be the arbiter of such dispute in our reasonable discretion. Subject to Section 22, our decision (which may include termination or suspension of any Bnbsync Account subject to dispute) will be final and binding on all parties.
            </p>
          </TermSection>
          <TermSection order={9} title="Communications">
            <p>
              You consent to accept and receive communications from us, including email, text messages, calls, and push notifications to the cellular telephone number you provide to us when you sign up for a Bnbsync Account or later update the contact information associated with your Bnbsync Account. Such communications may include, without limitation, requests for secondary authentication, receipts, reminders, notifications regarding updates to your Bnbsync Account or account support and marketing or promotional communications. Call and text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we send you.
            </p>
            <p>
              You may opt out of receiving promotional email communications we send to you by following the unsubscribe options in such promotional emails. You may opt out of any promotional phone calls by informing the caller that you would not like to receive future promotional calls. You do not have to continue to consent to receive promotional texts or calls in order to use the Services.
            </p>
            <p>
              You may opt out of text messages from Bnbsync at any time by texting STOP in response to any such text message. You acknowledge that opting out of receiving communications may impact your use of the Services.
            </p>
          </TermSection>
          <TermSection order={10} title="Paid Services">
            <p>
              Bnbsync may offer Services to be paid for on a recurring basis (“Subscription Services”). Subscription Services may subject you to recurring fees and/or terms. By signing up for a Subscription Service, including after any free trial period, you agree to pay us the subscription fee and any applicable taxes as set forth in your Bnbsync Account settings or as otherwise agreed in writing (“Subscription Fee”).
            </p>
            <p>
              Paid Service Fees may be paid by debit card, credit card, or deducted from your transaction proceeds. If you link a debit or credit card to your account, you authorise us to collect Paid Service Fees by debit from your linked debit card or charge to your linked credit card. Regardless of payment device, we reserve the right to collect Paid Service Fees by deduction from your transaction proceeds, the Balance (as defined in Section 14 of the Payment Terms) in your Bnbsync Account or your linked bank account.
            </p>
            <p>
              Unless otherwise provided in a Subscription Service’s terms, Subscription Fees will be charged on the 1st date of charge and there after that date of every month until cancelled. You may cancel a Subscription Service at any time from your Bnbsync Account settings. If you cancel a Subscription Service, you will continue to have access to that Subscription Service through the end of your then current billing period, but you will not be entitled to a refund or credit for any Subscription Fee already due or paid. We reserve the right to change our Subscription Fee upon at least thirty (30) days’ advance notice. Your continued use of Subscription Services after notice of a change to our Subscription Fee will constitute your agreement to such changes.
            </p>
          </TermSection>
          <TermSection order={11} title="Modification and Termination">
            <p>
              We may at any time terminate these General Terms or any Additional Terms: (a) in the circumstances specified in Section 7; (b) if you have violated the terms of these General Terms, any Additional Terms, any other agreement you have with Bnbsync or any of Bnbsync’s publicly available policies; or (c) if you engage in fraudulent or illegal conduct. We will notify you reasonably promptly of such a termination. Where reasonably practicable and not detrimental to our legitimate business interests, we will notify you in advance of a termination pursuant to (a) or (b) and provide you a reasonable opportunity to remediate; if you do not remediate, we may terminate these Terms upon notice to you. We also may terminate these General Terms and any Additional Terms for convenience upon providing at least thirty (30) days’ advance notice to you. We may add or remove, suspend, stop, delete, discontinue or impose conditions on Services or any feature or aspect of a Service. Where doing so will have a material effect on your existing rights and obligations or the material terms of the Services, we will, to the extent permitted by applicable Law and our agreements with payment card networks and acquiring processors, provide you with at least ten (10) days’ advance notice by email.
            </p>
            <p>
              You may terminate the General Terms and Additional Terms applicable to your Bnbsync Account at any time by deactivating your Bnbsync Account without penalty.
            </p>
          </TermSection>
          <TermSection order={12} title="Effect of Termination">
            <p>
              If these General Terms are terminated for any reason: (a) the license and any other rights granted under these Terms will end; (b) we may (but have no obligation to) delete your information and account data stored on our servers, and (c) we will not be liable to you or any third party for compensation, reimbursement, or damages for any termination of access to the Services, or for deletion of your information or account data, except to the extent arising from fraud, negligence or wilful misconduct of Bnbsync or its officers or directors. In addition to any payment obligations under the Payment Terms, the following sections of these General Terms survive and remain in effect in accordance with their terms upon termination: 6 (Your Content), 7 (Intellectual Property Infringement), 12 (Effect of Termination), 14 (Ownership), 15 (Indemnity), 16 (Representations and Warranties), 19 (Limitation of Liability and Damages), 20 (Third Party Products), 21 (Disputes), 22 (Binding Individual Arbitration), 23 (Governing Law), 24 (Assignment), 25 (Third Party Service and Links to Other Web Sites), and 26 (Other Provisions).
            </p>
          </TermSection>
          <TermSection order={13} title="Your Licence">
            <p>
              We grant you a limited, non-exclusive, revocable, non-transferable, non-sublicensable licence to use the software that is part of the Services, as authorised in these General Terms. We may make software updates to the Services available to you, which you must install to continue using the Services. Any such software updates may be subject to additional terms made known to you at that time.
            </p>
          </TermSection>
          <TermSection order={14} title="Ownership">
            <p>
              We reserve all rights not expressly granted to you in these General Terms. We own all rights, title, interest, copyright and other Intellectual Property Rights (as defined below) in the Services and all copies of the Services. These General Terms do not grant you any rights to our trademarks or service marks.
            </p>
            <p>
              For the purposes of these General Terms, “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights, and other intellectual property rights that may exist now or come into existence in the future, and all of their applications, registrations, renewals and extensions, under the Laws of any state, country, territory or other jurisdiction.
            </p>
            <p>
              You may submit comments or ideas about the Services (“Ideas”). By submitting any Idea, you agree that your disclosure is gratuitous, unsolicited, and without restriction, that it will not place us under any fiduciary, confidentiality or other obligation, and that we are free to use the Idea without any compensation to you whatsoever, and we are entitled to disclose the Idea on a non-confidential basis or otherwise to anyone.
            </p>
          </TermSection>
          <TermSection order={15} title="Indemnity">
            <p>
              You will indemnify, defend, and hold us and our processors (and our and their respective employees, directors, agents, affiliates and representatives) harmless from and against any and all claims, costs, losses, damages, judgments, Tax assessments, penalties, interest and expenses (including without limitation reasonable lawyer’s fees) arising out of any claim, action, audit, investigation, inquiry, or other proceeding instituted by a person or entity that arises out of or relates to: (a) any actual or alleged breach of your representations, warranties, or obligations set out in these Terms; (b) your unauthorised, wrongful or improper use of the Services; (c) your violation of any third-party right, including without limitation any right of privacy, publicity rights or Intellectual Property Rights; (d) your violation of any Law of Australia or any other country; or (e) any third party’s access and/or use of the Services using your unique name, password or other appropriate security code; except, in each case, to the extent arising from fraud, negligence or wilful misconduct of Bnbsync or its directors or officers.
            </p>
          </TermSection>
          <TermSection order={16} title="Representations and Warranties">
            <p>
              You represent and warrant to us that: (a) if you are an individual, you are at least eighteen (18) years of age; (b) you are eligible to register and use the Services and have the right, power, and ability to enter into and perform under these General Terms; (c) any information you provide in connection with the Services, including your business name and any ACN, ABN or other identifier you provide for your business, accurately and truthfully represents your business or, as applicable, personal identity under which you sell goods and services; (d) you and all transactions initiated by you will comply with all Commonwealth, state, territory, and local Laws, rules, and regulations applicable to you and/or your business, including, without limitation, any applicable Tax or employment Laws or regulations; (e) you will not use the Services, directly or indirectly, for any fraudulent undertaking or in any manner so as to interfere with the operation of the Services; and (f) your use of the Services will be in compliance with these Terms.
            </p>
            <p>
              The Services are controlled and operated from facilities in Australia. Bnbsync makes no representations that the Services are appropriate or available for use other than in Australia. You may not use the Services if you are a resident of a country embargoed or sanctioned by Australia, or are a foreign person or entity blocked or denied by the Australian government. Unless otherwise explicitly stated, all materials found on the Services and Bnbsync’s Australian websites are solely directed to individuals, companies or other entities located in Australia.
            </p>
          </TermSection>
          <TermSection order={17} title="Australian Consumer Law – Consumer Guarantees">
            <p>
              Nothing in these Terms is intended to exclude, restrict or modify the rights which you may have under Schedule 2 to the Competition and Consumer Act 2010 (Cth) (the “Australian Consumer Law”). If you are a Consumer, as defined in Section 3 of the Australian Consumer Law (“Consumer”), Bnbsync acknowledges that the Consumer Guarantees in Division 1 of Part 3-2 of the Australian Consumer Law will apply to the supply of goods or services (or both) under these Terms. Bnbsync’s liability for breach of a Consumer Guarantee is governed by Section 18.
            </p>
            <p>
              "Consumer Guarantee" means a guarantee provided to a Consumer applicable to goods or services supplied or to be supplied under these Terms by operation of the Australian Consumer Law, including any Express Warranty.
            </p>
          </TermSection>
          <TermSection order={18} title="Australian Consumer Law – Limitation of Liability">
            <p>
              Where you are a Consumer, Bnbsync’s liability for breach of a Consumer Guarantee is limited to (at our election):
            </p>
            <ul>
              <li>In the case of a supply of services: (i) resupplying the services or (ii) paying the cost of having the services supplied again.</li>
            </ul>
          </TermSection>
          <TermSection order={19} title="Limitations of Liability and Damages">
            <p>
              If the Consumer Guarantees apply, Bnbsync’s liability is limited in accordance with Section 18.
            </p>
            <p>
              In all other respects, and to the maximum extent permitted by Law:
            </p>
            <ol>
              <li>In no event shall Bnbsync, our processors, service providers, suppliers, or licensors (or our or their respective subsidiaries, affiliates, agents, directors or employees) (collectively, the “Excluded Parties”) be liable for any direct, indirect, punitive, incidental, special, consequential or exemplary damages, or for any damages for loss of profits, goodwill, use, data or other intangible losses, that result from the use, inability to use or unavailability of the Services, except to the extent arising from fraud, negligence or willful misconduct of Bnbsync or its directors or officers. In all cases, the Excluded Parties will not be liable for any loss or damage that is not reasonably foreseeable.</li>
              <li>Under no circumstances will the Excluded Parties be responsible for any damage, loss, or injury resulting from hacking, tampering or other unauthorised access or use of the Services or your Bnbsync Account, or the information contained therein, except to the extent arising from fraud, negligence or willful misconduct of Bnbsync or its directors or officers.</li>
              <li>In no event shall the Excluded Parties be liable to you for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the greater of (i) the amount of fees earned by Bnbsync in connection with your use of the Services.</li>
            </ol>
            <p>
              This limitation of liability section applies whether the alleged liability is based on contract, tort, negligence, strict liability or any other basis, even if the Excluded Parties have been advised of the possibility of such damage. The foregoing limitation of liability shall apply to the fullest extent permitted by applicable Law.
            </p>
          </TermSection>
          <TermSection order={20} title="Third Party Services">
            <p>
              All third-party services provided are according to the warranty and other service and support terms specified by the third-party manufacturer, subject to the Consumer Guarantees in the Australian Consumer Law (if and to the extent applicable to you). Bnbsync does not endorse any product advertised or offered by a third party through the Services or any hyperlinked website or service, or featured in any banner or other advertising, and Bnbsync will not be a party to or in any way monitor any transaction between you and third-party providers of products.
            </p>
            <p>
              If the Consumer Guarantees apply (see Section 17), Bnbsync’s liability to you arising out of your use of, or inability to use, third party services is limited in accordance with Section 18. In all other respects, Bnbsync will not be liable for any indirect, special, punitive, incidental, consequential or cover damages arising out of your use of, or inability to use, third-party services, or any amount in excess of the amount paid by you for the product giving rise to any claim.
            </p>
          </TermSection>
          <TermSection order={21} title="Disputes">
            <p>
              “Dispute” is defined as any dispute, difference or issue between the parties concerning or arising out of or in connection with, or relating to these Terms, the subject matter of these Terms or the existence, breach, termination, validity, repudiation, rectification, frustration, operation or interpretation of these Terms, including, without limitation, any claim (i) in tort, (ii) in equity, (iii) pursuant to any applicable state, territory, Commonwealth, foreign or international statute of Law or (iv) of any other kind or nature under any applicable Law.
            </p>
          </TermSection>
          <TermSection order={22} title="Binding Individual Arbitration">
            <p>
              You and Bnbsync agree to arbitrate any and all Disputes by a neutral arbitrator who has the power to award the same damages and relief that a court can. Any Dispute arising out of or in connection with these Terms shall be submitted to final and binding arbitration in accordance with and subject to the Resolution Institute Arbitration Rules, as varied by this Section 22. The arbitrator shall be responsible for determining all threshold arbitrability issues, including, without limitation, whether these Terms (or any aspect thereof) are enforceable, unconscionable or illusory, and any defense to arbitration, including, without limitation, waiver, delay, laches or estoppel.
            </p>
            <p>
              Any arbitration under these Terms will only be on an individual basis. The arbitrator’s award shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. For any claim where you are seeking relief, Bnbsync will not seek to have you pay its legal fees, even if fees might otherwise be awarded, unless the arbitrator determines that your claim was frivolous. Subject to and without waiver of the arbitration provisions above, you agree that any judicial proceedings (other than small claims actions in Consumer cases) will be brought in, and you consent to the exclusive jurisdiction of the courts of South Australia.
            </p>
            <p>
              Consumer claimants (individuals whose use of the Services is intended for personal, family or household use) may elect to pursue their claims in their local small-claims tribunal rather than through arbitration.
            </p>
          </TermSection>
          <TermSection order={23} title="Governing Law">
            <p>
              These Terms and any Dispute will be construed in accordance with, and governed by, the laws of South Australia, and the parties irrevocably and unconditionally submit to the non-exclusive jurisdiction of the courts of South Australia.
            </p>
            <p>
              For purposes of these Terms, “Law” means common law, principles of equity and any legislation, enactment, proclamation, by-law, regulation, published policy or regulatory guide passed or adopted by any government or authority with applicable jurisdiction, any amendments, consolidations or replacements of them and all orders, ordinances, regulations, rules, by-laws and policies made under them.
            </p>
          </TermSection>
          <TermSection order={24} title="Assignment">
            <p>
              These Terms, and any rights and licences granted to you by these Terms, may not be transferred or assigned by you, and any such attempted transfer or assignment by you will be null and void.
            </p>
          </TermSection>
          <TermSection order={25} title="Third Party Services and Links to Other Websites">
            <p>
              You may be offered services, products and promotions provided by third parties and not by Bnbsync, including, without limitation, third party developers who use Bnbsync’s Services (“Third Party Services”). If you decide to use Third Party Services you will be responsible for reviewing and understanding the terms and conditions for these services. We are not responsible for the performance of any Third Party Services. Further, you agree to resolve any disagreement between you and a third party regarding the terms and conditions of any Third Party Services with that third party directly in accordance with the terms and conditions of that relationship, and not Bnbsync. The Services may contain links to third party websites. The inclusion of any website link does not imply an approval, endorsement, or recommendation by Bnbsync. Such third party websites are not governed by these General Terms. You access any such website at your own risk. We expressly disclaim any liability for these websites, except to the extent arising from fraud, negligence or willful misconduct of Bnbsync or its directors or officers. When you use a link to go from the Services to a third party website, our Privacy Policy is no longer in effect. Your browsing and interaction on a third party website, including, without limitation, those that have a link in the Services, is subject to that website’s own terms, rules and policies.
            </p>
          </TermSection>
          <TermSection order={26} title="Other Provisions">
            <p>
              In the event of a conflict between these General Terms and any other Bnbsync agreement or Policy, these General Terms will prevail and control the subject matter of such conflict. If any provision of these Terms is invalid or unenforceable under applicable Law, then it will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable Law, and the remaining provisions will continue in full force and effect. These Terms do not limit any rights that we may have under trade secret, copyright, patent, or other Laws. No waiver of any provision of these Terms will be deemed a further or continuing waiver of such provision or any other provision.
            </p>
          </TermSection>

        </main>
      </div>
    );
  }
}

export default TermsOfServiceRoute;
