import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import CreateXeroAccountForm from 'components/forms/xero/CreateAccount';

class CreateXeroAccountModal extends React.Component {
  render() {
    const { ...rest } = this.props;

    return (
      <Modal {...rest}>
        <ModalBody>
          <CreateXeroAccountForm />
        </ModalBody>
      </Modal>
    );
  }
}

export default CreateXeroAccountModal;
