import React from 'react';

import HelpContainer from 'features/help/Container';
import HelpNav from 'features/help/HelpNav';

class HelpRoute extends React.Component {
  render() {
    const { params } = this.props.match;

    return (
      <div className="help-route" style={{ height: '100%' }}>
        <HelpNav />
        <HelpContainer
          topicSlug={params.topicSlug}
          articleSlug={params.articleSlug}
        />
      </div>
    );
  }
}

export default HelpRoute;
