import axios from 'axios';

import { XERO_BASE_URL } from '../index';
import { fetchMe } from 'actions/users/fetch';

export const VERIFY_XERO_CREDENTIALS_SUCCESS = 'VERIFY_XERO_CREDENTIALS_SUCCESS';
const verifyXeroCredentialsSuccess = data => (
  { type: VERIFY_XERO_CREDENTIALS_SUCCESS, data }
);

export const VERIFY_XERO_CREDENTIALS_FAILURE = 'VERIFY_XERO_CREDENTIALS_FAILURE';
const verifyXeroCredentialsFailure = data => (
  { type: VERIFY_XERO_CREDENTIALS_FAILURE, data }
);

export const verifyXeroCredentials = payload => ((dispatch) => {
  const url = `${XERO_BASE_URL}credentials/verify/`;
  return axios.post(url, payload)
    .then(response => {
      // TODO: Remove this fetchMe and set the xeroOrgName from verify response data.
      dispatch(fetchMe());
      return dispatch(verifyXeroCredentialsSuccess(response.data));
    },
    error => dispatch(verifyXeroCredentialsFailure(error.response.data.error)));
});
