import React from 'react';
import { Link } from 'react-router-dom';

import ForgotPasswordForm from 'components/forms/auth/ForgotPassword';

class ForgotPasswordRoute extends React.Component {
  render() {
    return (
      <div className="forgot-password-route">
        <ForgotPasswordForm />
        <div className="auth-extra-container m-2 text-center">
          <Link to="/auth/login">Back to Login</Link>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordRoute;
