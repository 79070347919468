import React from 'react';
import {
  Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showModal } from 'actions/modals';
import { deleteTransaction } from 'actions/transactions/delete';
import { toast } from 'react-toastify';
import CancelButton from '../CancelButton';
import './style.scss';

const EditTransactionSection = ({ display, onClick }) =>
  /* Section for the user to choose which action they want to take on
     a transaction.
  */
  (
    <div onClick={onClick}>{display}</div>
  );

class EditTransactionModal extends React.Component {
  deleteTransaction = () => {
    this.props.deleteTransaction(this.props.transaction.id);
    toast('Transaction removed');
    this.props.toggle();
  }

  render() {
    const {
      transaction, showModal, deleteTransaction, errors, ...rest
    } = this.props;
    const { property } = transaction;

    const renderEditSections = () => {
      const renderEditAccount = () => {
        if (!property) return null;
        return (
          <EditTransactionSection
            display="Edit Account"
            onClick={() => this.props.showModal('property', { property })}
          />
        );
      };

      return (
        <div className="actions-container">
          {renderEditAccount()}
          <EditTransactionSection
            display="Change listing account"
            onClick={() => this.props.showModal('changeListing', { transaction })}
          />
          <EditTransactionSection
            display="Remove Transaction"
            onClick={this.deleteTransaction}
          />
        </div>
      );
    };

    const renderErrors = () => {
      const { errors } = this.props;
      if (Object.keys(this.props.errors).length === 0) return null;

      return (
        <div className="error-container">
          {Object.keys(errors).map(key => (
            <p key={key}>{errors[key]}</p>
          ))}
        </div>
      );
    };

    return (
      <Modal {...rest} className="edit-transaction-modal">
        <ModalHeader>Edit transaction</ModalHeader>
        <ModalBody>
          <p className="text-muted text-center">How do you want to change this transaction?</p>
          {renderEditSections()}
          {renderErrors()}
        </ModalBody>
        <ModalFooter>
          <CancelButton />
        </ModalFooter>
      </Modal>
    );
  }
}

EditTransactionModal.propTypes = {
  transaction: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default connect(null, { showModal, deleteTransaction })(EditTransactionModal);
