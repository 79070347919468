import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import { StripeProvider } from 'react-stripe-elements';
import { environment, STRIPE_PUBLISHABLE_TOKEN } from 'config';
import * as serviceWorker from './serviceWorker';

import store, { persistor } from './store';
import App from './App';

if (environment === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-TR453R3',
    events: {
      'gtm.start': new Date().getTime(), event: 'gtm.js',
    },
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render((
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <StripeProvider apiKey={STRIPE_PUBLISHABLE_TOKEN}>
          <App />
        </StripeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
