import axios from 'axios';

import { XERO_BASE_URL } from '../index';

export const FETCH_XERO_EXPORTS_START = 'FETCH_XERO_EXPORTS_START';
const fetchXeroExportsStart = () => ({ type: FETCH_XERO_EXPORTS_START });

export const FETCH_XERO_EXPORTS_SUCCESS = 'FETCH_XERO_EXPORTS_SUCCESS';
const fetchXeroExportsSuccess = data => ({
  type: FETCH_XERO_EXPORTS_SUCCESS, data,
});

export const FETCH_XERO_EXPORTS_FAILURE = 'FETCH_XERO_EXPORTS_FAILURE';
const fetchXeroExportsFailure = error => ({
  type: FETCH_XERO_EXPORTS_FAILURE, error,
});

export const fetchXeroExports = () => (dispatch) => {
  dispatch(fetchXeroExportsStart());
  const url = `${XERO_BASE_URL}exports/`;
  return axios.get(url).then(
    response => dispatch(fetchXeroExportsSuccess(response.data)),
    error => dispatch(fetchXeroExportsFailure(error.response.data)),
  );
};
