import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import { fetchXeroContacts } from 'actions/xero/contacts/fetch';
import AutocompleteBase from '../Base';

class SelectXeroContact extends React.Component {
  /* Select control for the user to choose a xero account. This is used when
     when a user wants to map a source listing to a xero account. */
  state = {
    searchTerm: '',
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (!this.props.xero.contacts.length) this.props.fetchXeroContacts();

    if (initialValue) {
      this.setState({
        searchTerm: this.getNameFromId(this.props.initialValue),
      });
    }
  }

  getNameFromId = (xeroContactId) => {
    // Return xero contact name from their id.
    const selectedContact = this.props.items.find(item => item.ContactID === xeroContactId);
    return selectedContact ? selectedContact.Name : '';
  }

  onSelect = (value) => {
    this.props.onSelect(value);
    this.setState({ searchTerm: this.getNameFromId(value) });
  }

  shouldItemRender = (item, value) => item.Name.toLowerCase().includes(value.toLowerCase())

  render() {
    if (this.props.xero.isLoading) return <Loading />;

    return (
      <AutocompleteBase
        className="select-xero-contact"
        refreshAction={this.props.fetchXeroContacts}
      >
        <Autocomplete
          inputProps={{
            className: 'form-control',
            placeholder: this.props.disabled ? 'Xero auth required' : 'Search for Xero Contact',
            disabled: this.props.disabled,
          }}
          items={this.props.items}
          getItemValue={item => item.ContactID}
          autoHighlight
          renderItem={(item, isHighlighted) => {
            let className = 'autocomplete-item';
            if (isHighlighted) className += ' highlighted';

            return (
              <div
                className={className}
                key={item.ContactID}
              >
                {item.Name}
              </div>
            );
          }}
          shouldItemRender={this.shouldItemRender}
          onChange={e => this.setState({ searchTerm: e.target.value })}
          value={this.state.searchTerm}
          onSelect={value => this.onSelect(value)}
        />
      </AutocompleteBase>
    );
  }
}

SelectXeroContact.propTypes = {
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ xero }) => ({ xero });
export default connect(mapStateToProps, { fetchXeroContacts })(SelectXeroContact);
