import axios from 'axios';

import { XERO_BASE_URL } from 'actions/xero';


export const DELETE_TRANSACTION_START = 'DELETE_TRANSACTION_START';
const deleteTransactionStart = transactionId => (
  { type: DELETE_TRANSACTION_START, transactionId }
);

export const deleteTransaction = transactionId => ((dispatch) => {
  // Optimistically delete a transaction on an export invoice.
  dispatch(deleteTransactionStart(transactionId));
  const url = `${XERO_BASE_URL}transactions/${transactionId}`;
  return axios.delete(url);
});
