import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DashboardContainer from 'components/dashboard/Container';
import { showModal } from 'actions/modals';
import { getContactFromId } from 'utils/xero';
import './style.scss';

class AccountsTable extends React.Component {
  // Render our bnbsync accounts in a table.

  openAccountModal = (property) => {
    this.props.showModal('property', { property });
  }

  render() {
    const { accounts } = this.props;
    const renderPropertyRows = () => accounts.map((account) => {
      if (this.props.userType === "owner")
        return (
          <tr
            key={account.id}
            onClick={() => this.openAccountModal(account)}
            className="clickable-row"
          >
            <td>{account.name}</td>
            <td>{account.accountCode}</td>
          </tr>
        );

      const renderContactName = () => {
        if (!account.xeroContactId) return '';

        const contact = getContactFromId(
          account.xeroContactId,
          this.props.xero.contacts,
        );
        return contact ? contact.Name : '';
      };

      return (
        <tr
          key={account.id}
          onClick={() => this.openAccountModal(account)}
          className="clickable-row"
        >
          <td>{account.name}</td>
          <td>{account.accountCode}</td>
          <td>{account.commission}</td>
          <td>{renderContactName()}</td>
        </tr>
      );
    });

    if (accounts.length) {
      const renderHeaders = () => {
        if (this.props.userType === "owner")
          return (
            <tr>
              <th>Property</th>
              <th>Account Code</th>
            </tr>
          );

        return (
          <tr>
            <th>Property</th>
            <th>Account Code</th>
            <th>Commission</th>
            <th>Contact</th>
          </tr>
        );
      };
      return (
        <div className="table-container">
          <Table responsive className="properties-table">
            <thead>
              {renderHeaders()}
            </thead>
            <tbody>
              {renderPropertyRows()}
            </tbody>
          </Table>
        </div>
      );
    }
    return (
      <DashboardContainer className="text-center">
        <span className="text-muted"> You don't have any accounts loaded yet!
          You'll need to first import your accounts from Xero.
        </span>
      </DashboardContainer>
    );
  }
}

AccountsTable.propTypes = {
  accounts: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ xero }) => ({ xero });
export default connect(mapStateToProps, { showModal })(AccountsTable);
