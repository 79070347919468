import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as faCheckCircleReg } from '@fortawesome/free-regular-svg-icons';

import DashboardContainer from 'components/dashboard/Container';
import { updateXeroInvoice } from 'actions/invoices/update';
import { showModal } from 'actions/modals';
import { formatCurrency } from 'utils/misc';

import TransactionRow from '../TransactionRow';
import InvoiceIcon from '../InvoiceIcon';
import './style.scss';

class XeroInvoice extends React.Component {
  // Represents an invoice record that is to be exported to Xero.

  state = {
    expanded: false,
    tooltipOpen: false,
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  containerClassName = () => {
    let className = 'xero-invoice my-3';

    if (this.hasInvalidTransaction()) return className += ' invalid';

    if (this.state.expanded) className += ' expanded';

    if (this.props.invoice.isExported) className += ' selected';

    return className;
  }

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })

  toggleInvoiceExported = (event) => {
    event.stopPropagation();
    const payload = { isExported: !this.props.invoice.isExported };
    this.props.updateXeroInvoice(this.props.invoice.id, payload);
  }

  hasInvalidTransaction = () => {
    // Go through the transactions and see if the invoice has any invalids.
    let hasInvalid = false;
    this.props.invoice.transactions.forEach((transaction) => {
      const { property } = transaction;
      if (property && !property.allowNoCommission
          && property.commission === '0.00') {
        hasInvalid = true;
      }
    });

    return hasInvalid;
  }

  openCreateTransactionModal = (e) => {
    e.stopPropagation();
    this.props.showModal('createTransaction', {
      invoiceId: this.props.invoice.id,
    });
  }

  render() {
    const { invoice } = this.props;
    const renderTransactions = () => invoice.transactions.map((transaction) => {
      const { property } = transaction;
      const getErrors = () => {
        if (property && !property.allowNoCommission && property.commission === '0.00') {
          return {
            noCommission: `${transaction.property.name} has 0%
                    commission but does not allow 0% commission. Click on
                    "Edit Account" to either enter commission or allow no
                    commission.`,
          };
        }
        return {};
      };

      return (
        <TransactionRow
          key={transaction.id}
          transaction={transaction}
          errors={getErrors()}
        />
      );
    });

    const renderExpandedContent = () => {
      // If the invoice is expanded we'll show the transactions.
      if (!this.state.expanded) return null;

      return (
        <section className="transactions-container mt-4">
          {renderTransactions()}
        </section>
      );
    };

    const renderIcon = () => {
      // Return the corresponding icon depending on validity and exported.
      let props = {};

      if (this.hasInvalidTransaction()) {
        props = {
          tooltip: 'Invoice has invalid transactions',
          icon: faExclamationCircle,
          style: { color: '#cc0000' },
        };
      } else if (invoice.isExported) {
        props = {
          tooltip: 'Invoice ready for export',
          icon: faCheckCircle,
          onClick: this.toggleInvoiceExported,
        };
      } else {
        props = {
          tooltip: 'Click to approve invoice export',
          icon: faCheckCircleReg,
          onClick: this.toggleInvoiceExported,
        };
      }

      props.id = `invoice-icon-${invoice.id}`;

      return <InvoiceIcon {...props} />;
    };
    let isHasCleaningFee = false;
    invoice.transactions.map((transaction) => {
      let cleaningFeeFromFees = null;
      if (transaction.fees.some(fee => fee.type === 'cleaning')) {
            cleaningFeeFromFees = transaction.fees.find(fee => fee.type === 'cleaning').amount;
        }
      let cleaningFeeFromProperty = transaction.property.cleaningFee ? transaction.property.cleaningFee : 0;
      const cleaning_fee = cleaningFeeFromFees || cleaningFeeFromProperty || 0;
      if (cleaning_fee > 0) {
        isHasCleaningFee = true;
      }
      });
    let ignoreCleaning = false;
    if (invoice.transactions[0] && invoice.transactions[0].property.ignoreCleaning) {
      ignoreCleaning = true;
    }
    let source = '';
    if (this.props.source === 'booking') {
      source = this.props.source;
    }
    return (
      <DashboardContainer
        className={this.containerClassName()}
        onClick={() => this.toggleExpanded()}
      >
        <Row className="invoice-header">
          <Col>
            <FontAwesomeIcon
              size="2x"
              icon={faPlusCircle}
              id="add-transaction"
              onClick={this.openCreateTransactionModal}
            />
            <Tooltip
              isOpen={this.state.tooltipOpen}
              target="add-transaction"
              toggle={this.toggleTooltip}
            >
                Add new transaction to this invoice
            </Tooltip>
          </Col>
          <Col>{moment(invoice.date).format('ll')}</Col>
          <Col>
            {invoice.transactions.length} Transactions
          </Col>
          <Col>{formatCurrency(invoice.total,this.props.currency)}</Col>
          <Col>{renderIcon()}</Col>
        </Row>
        {renderExpandedContent()}
        {!isHasCleaningFee && !ignoreCleaning && source === 'booking'? (
            <div className="cleaning-fee-warning">
              Warning: The following transactions in this invoice do not have a cleaning fee because the associated account does not have a specified cleaning fee
            </div>
          ) : null
        }
      </DashboardContainer>
    );
  }
}

XeroInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(null, { updateXeroInvoice, showModal })(XeroInvoice);
