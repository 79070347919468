import React from 'react';
import {
  Route, Switch, NavLink, Link,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import LoadingContainer from 'components/LoadingContainer';
import ToastCloseButton from 'components/ToastCloseButton';
import { logoutUser } from 'actions/session/logout';
import { showModal } from 'actions/modals';
import Logo from 'components/Logo';
import AccountsRoute from '../Accounts';
import SettingsRoute from '../Settings';
import PaymentsRoute from '../Payments';
import ExportRoute from '../Export';
import ContactRoute from '../Contact';
import HelpRoute from '../Help';
import UsageRoute from '../Usage';

import { hasActiveTrial } from 'utils/users';
import { createXeroCredentials } from 'actions/xero/credentials/create';
import { fetchProperties } from 'actions/properties/fetch';
import { fetchXeroContacts } from 'actions/xero/contacts/fetch';
import { fetchXeroExports } from 'actions/xero/exports/fetch';
import { fetchCurrencies } from 'actions/currencies/fetch';
import { fetchXeroTrialBalance } from 'actions/xero/trial/fetch';
import { refreshXeroCredentials, REFRESH_XERO_CREDENTIALS_FAILURE } from 'actions/xero/credentials/refresh';
import './style.scss';

class DashboardRoute extends React.Component {
  /* Parent layout route for all dashboard routes. */
  state = { isReady: false }

  async componentDidMount() {
    const { user } = this.props.session;

    this.fetchData();

    if (!user.xero || !user.xero.state || !user.xero.state.token) {
      // User does not have a Xero connection which is required for the dashboard.
      return this.props.history.push('/auth/xero-begin');
    }

    if (user.hasActiveTrial) {
      const now = moment();
      const trialEnd = moment(user.trialEnd);
      const days = trialEnd.diff(now, 'days');

      if (hasActiveTrial(user.trialEnd)) {
        let display = "";
        if (days > 1)
          display = `Your trial ends in ${days} days.`;
        else if (days === 1)
          display = `Your trial ends in ${days} day.`;
        else if (days === 0)
          display = "Your trial ends today!";

        const options = {
          autoClose: false,
          closeButton: <ToastCloseButton />,
        };

        if (days < 5)
          toast.warning(display, options);
        else
          toast(display, options);
      }
    }

    // Refresh the user's token every 28 minutes.
    this.intervalId = setInterval(this.refreshToken, 28 * 60 * 1000);

    if (!user.hasPremiumAccess)
      this.props.history.push('/payments');
    else if (!user.hasAgreedTos)
      this.props.showModal('tosChanged');
    // The welcome modal is no longer accurate, just comment it out for now and we might update in
    // future.
    // else if (!user.hasDoneTutorial)
    //   this.props.showModal('welcome');
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  refreshToken = () => {
    this.props.refreshXeroCredentials();
  }

  fetchData = async () => {
    // Data that should be preloaded for the dashboard should be loaded here.
    const refreshAction = await this.props.refreshXeroCredentials();
    if (refreshAction.type === REFRESH_XERO_CREDENTIALS_FAILURE)
      return this.props.history.push('/auth/xero-begin');

    await Promise.all([
      this.props.fetchXeroContacts(),
      this.props.fetchXeroExports(),
      this.props.fetchProperties(),
      this.props.fetchCurrencies(),
      this.props.fetchXeroTrialBalance(),
    ]);

    this.setState({ isReady: true });
  }

  render() {
    if (!this.state.isReady)
      return <LoadingContainer text="Loading dashboard..." />;

    return (
      <div className="dashboard-route">
        <section className="sidenav-section bg-dark">
          <Logo className="dashboard-nav-logo px-5 py-4" icon={1} />
          <nav className="sidenav">
            <NavLink to="/dashboard/accounts">Accounts</NavLink>
            <NavLink to="/dashboard/export">Export</NavLink>
            <NavLink to="/dashboard/payments">Payments</NavLink>
            <NavLink to="/dashboard/help">Help</NavLink>
            <NavLink to="/dashboard/contact">Contact</NavLink>
            <NavLink to="/dashboard/usage">Usage</NavLink>
            <NavLink to="/dashboard/settings">Settings</NavLink>
            <Link to="/" onClick={this.props.logoutUser}>Logout</Link>
          </nav>
        </section>

        <section className="sidenav-layout-content p-4">
          <Switch>
            <Route path="/dashboard/accounts" component={AccountsRoute} />
            <Route path="/dashboard/export" component={ExportRoute} />
            <Route path="/dashboard/payments" component={PaymentsRoute} />
            <Route path="/dashboard/help/topics/:topicSlug" component={HelpRoute} />
            <Route path="/dashboard/help/articles/:articleSlug" component={HelpRoute} />
            <Route path="/dashboard/usage" component={UsageRoute} />
            <Route exact path="/dashboard/help" component={HelpRoute} />
            <Route exact path="/dashboard/settings" component={SettingsRoute} />
            <Route exact path="/dashboard/contact" component={ContactRoute} />
          </Switch>
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps, {
  logoutUser, showModal, createXeroCredentials, fetchXeroContacts, fetchXeroExports,
  fetchProperties, fetchCurrencies, refreshXeroCredentials, fetchXeroTrialBalance,
})(DashboardRoute);
