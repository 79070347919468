import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';

import LoadButton from 'components/buttons/LoadButton';
import { createStripeCustomer } from 'actions/stripe/customers/create';
import './style.scss';


class CheckoutForm extends Component {
  state = {
    error: '',
    /* Status shows where the user is at in the card process.

       inProgress - User is entering their card info
       complete - User has successfully entered a card source
    */
    status: 'inProgress',
    isLoading: false,
  }

  submit = async e => {
    this.setState({ isLoading: true });
    let { token, error } = await this.props.stripe.createToken({
      name: "Bnbsync",
    });

    if (token) {
      try {
        await this.props.createStripeCustomer({ sourceToken: token.id });
        this.setState({ error: '', status: 'complete' });
      } catch(err) {
        this.setState({ error: err.message, isLoading: false });
      }
    } else if (error) {
      // Error creating stripe token.
      this.setState({ error: error.message, isLoading: false });
    }
  }

  render() {
    if (this.state.status === 'complete') {
      return (
        <div className="success-container text-center">
          <h2 className="text-success">
            <FontAwesomeIcon icon={faCheckCircle} /> Success!
          </h2>
          <p className="text-muted mt-3">You're now ready to start using bnbsync.</p>
          <Button
            color="primary"
            onClick={() => this.props.history.push('/dashboard/accounts')}
            className="mt-4"
          >
            Go to Dashboard
          </Button>
        </div>
      );
    }
    const renderErrorText = () => {
      const { error } = this.state;
      if (!error)
        return null;

      return <p className="text-danger text-center">{error}</p>;
    };

    return (
      <div className="stripe-checkout">
        <CardElement hidePostalCode={true} className="card-element" />
        {renderErrorText()}
        <div className="button-container text-center m-4">
          <LoadButton
            className="btn-checkout"
            onClick={this.submit}
            isLoading={this.state.isLoading}
          >
            Continue
          </LoadButton>
        </div>
      </div>
    );
  }
}


const ConnectedComponent = connect(null, { createStripeCustomer })(CheckoutForm);
export default withRouter(injectStripe(ConnectedComponent));
