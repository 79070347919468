import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { ManagerFeaturesList, OwnerFeaturesList, EnterpriseFeaturesList } from 'features/features';
import './style.scss';

class PaymentOptionsRow extends React.Component {
  render() {
    const { button } = this.props;

    return (
      <Row className="payment-options-row">
        <Col>
          <h4>Property Owner</h4>
          <p>For property owners who manage their own</p>
          <p className="price">$5.99</p>
          <p className="promotional-desc">Celebrate Xero partnership when registering before September 30th!</p>
          <p className="old-price">$17.99</p>
          <p className="price-description">per active property, per month</p>
          {button}
          <OwnerFeaturesList />
        </Col>
        <Col>
          <h4>Property Manager</h4>
          <p>For property managers who manage properties for clients</p>
          <p className="price">$9.99</p>
          <p className="promotional-desc">Celebrate Xero partnership when registering before September 30th!</p>
          <p className="old-price">$24.99</p>
          <p className="price-description">per active property, per month</p>
          {button}
          <ManagerFeaturesList />
        </Col>
        <Col>
          <h4>Enterprise</h4>
          <p>For property management organizations that manage many properties</p>
          <p className="price">Ask us</p>
          <p className="price-description">per active property, per month</p>
          {button}
          <EnterpriseFeaturesList />
        </Col>
      </Row>
    );
  }
}

PaymentOptionsRow.propTypes = {
  button: PropTypes.object,
};

export default PaymentOptionsRow;
