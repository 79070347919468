import axios from 'axios';
import { API_URL } from 'config';


export const FETCH_HELP_TOPICS_START = 'FETCH_HELP_TOPICS_START';
const fetchHelpTopicsStart = () => ({ type: FETCH_HELP_TOPICS_START });

export const FETCH_HELP_TOPICS_SUCCESS = 'FETCH_HELP_TOPICS_SUCCESS';
const fetchHelpTopicsSuccess = data => ({ type: FETCH_HELP_TOPICS_SUCCESS, data });

export const fetchHelpTopics = () => dispatch => {
  dispatch(fetchHelpTopicsStart());
  const url = `${API_URL}topics/`;

  return axios.get(url)
    .then(success => dispatch(fetchHelpTopicsSuccess(success.data)));
};
