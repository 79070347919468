import { FETCH_PROPERTIES_START, FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_FAILURE } from 'actions/properties/fetch';
import { DELETE_PROPERTY_START } from 'actions/properties/delete';
import { IMPORT_PROPERTIES_SUCCESS } from 'actions/xero/accounts/import';
import { UPDATE_PROPERTY_START, UPDATE_PROPERTY_SUCCESS, UPDATE_PROPERTY_FAILURE } from 'actions/properties/update';
import { ADD_PROPERTY_LISTING_START, ADD_PROPERTY_LISTING_SUCCESS } from 'actions/properties/addListing';
import { DELETE_XERO_CREDENTIALS_SUCCESS } from 'actions/xero/credentials/delete';
import { CREATE_XERO_ACCOUNT_SUCCESS } from 'actions/xero/accounts/create';
import { LOGOUT } from 'actions/session/logout';


const initialState = {
  isLoading: false,
  updateIsLoading: false,
  data: [],

  // array of all the user's listings
  listings: [],
  error: {},
};

export default (state = initialState, action) => {
  const currentData = [...state.data];
  let newData = [];

  switch (action.type) {
    case FETCH_PROPERTIES_START:
    case ADD_PROPERTY_LISTING_START:
      return { ...state, isLoading: true };
    case FETCH_PROPERTIES_SUCCESS:
    case IMPORT_PROPERTIES_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    case UPDATE_PROPERTY_FAILURE:
    case FETCH_PROPERTIES_FAILURE:
      // add the new property into our properties state
      return { ...state, isLoading: false, error: action.error };
    case DELETE_PROPERTY_START:
      // optimistically remove the property from state based on action.id
      newData = currentData.filter(property => property.id !== action.id);
      return { ...state, data: newData };
    case UPDATE_PROPERTY_START:
      return { ...state, updateIsLoading: true };
    case UPDATE_PROPERTY_SUCCESS:
      newData = currentData.map((property) => {
        // replace the current state property with the updated property
        return property.id === action.data.id ? action.data : property;
      });
      return { ...state, updateIsLoading: false, data: newData };
    case ADD_PROPERTY_LISTING_SUCCESS: {
      // remove the existing listing if replace was true
      let replacedData = currentData;
      if (action.replace) {
        replacedData = currentData.map((property) => {
          if (property.listings.includes(action.listing)
                 && property.id === action.data.id) {
            property.listings.splice(action.listing);
            return property;
          }
          return property;
        });
      }

      // update the property with new data
      newData = replacedData.map((property) => {
        return property.id === action.data.id ? action.data : property;
      });
      return { ...state, data: newData, isLoading: false };
    }
    case CREATE_XERO_ACCOUNT_SUCCESS:
      newData = [...state.data, action.data];
      newData.sort((a, b) => (a.accountCode > b.accountCode ? 1 : -1));
      return { ...state, data: newData };
    case LOGOUT:
    case DELETE_XERO_CREDENTIALS_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
