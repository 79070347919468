export const environment = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

let stripePublishableToken = 'pk_test_uuBKgsw5xZRW9m5IdYZg49ul';
let apiUrl = 'https://bnbsync-api-prod.herokuapp.com/';
if (environment === 'development') {
  apiUrl = 'http://localhost:8000/';
} else if (environment === 'staging') {
  apiUrl = 'https://bnbsync-api-staging.herokuapp.com/';
} else if (environment === 'production') {
  apiUrl = 'https://bnbsync-api-prod.herokuapp.com/';
  stripePublishableToken = 'pk_live_L8pimofqTr0mYCBDIz1mPQNv';
}

export const API_URL = apiUrl;
export const STRIPE_PUBLISHABLE_TOKEN = stripePublishableToken;
