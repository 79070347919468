import axios from 'axios';

import { API_URL } from 'config';

export const CONVERT_TRIAL_START = 'CONVERT_TRIAL_START';
const convertTrialStart = () => ({ type: CONVERT_TRIAL_START });

export const CONVERT_TRIAL_SUCCESS = 'CONVERT_TRIAL_SUCCESS';
const convertTrialSuccess = data => ({ type: CONVERT_TRIAL_SUCCESS, data });

export const CONVERT_TRIAL_FAILURE = 'CONVERT_TRIAL_FAILURE';
const convertTrialFailure = data => ({ type: CONVERT_TRIAL_FAILURE, data });


export const convertTrial = payload => {
  return function (dispatch) {
    dispatch(convertTrialStart());

    const url = `${API_URL}users/convert_trial/`;

    return axios.post(url)
      .then(success => {
          return dispatch(convertTrialSuccess(success.data));
        },
        error => dispatch(convertTrialFailure(error.response.data)),
      );
  };
};
