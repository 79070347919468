import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import DashboardContainer from '../Container';


const SmallContainer = props =>
  // Put a smaller container to display some message.
  (
    <Container>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <DashboardContainer className="text-center">
            { props.children }
          </DashboardContainer>
        </Col>
      </Row>
    </Container>
  );


export default SmallContainer;
