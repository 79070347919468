import React from 'react';
import { connect } from 'react-redux';
import { Form, Label, FormGroup, Input, FormFeedback, FormText, Container,
         Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { createXeroAccount, CREATE_XERO_ACCOUNT_SUCCESS } from 'actions/xero/accounts/create';
import { hideModal, showModal } from 'actions/modals';
import LoadButton from 'components/buttons/LoadButton';
import ModalCancelButton from 'components/modals/CancelButton';

class CreateXeroAccountForm extends React.Component {
  getInitialValues = () => ({
    accountCode: '',
    accountName: '',
    description: '',
  })

  ValidationSchema = (
    Yup.object().shape({
      accountCode: Yup.number()
        .required('Required')
        .typeError('Account code must be a valid number'),
      accountName: Yup.string().required('Required'),
      description: Yup.string(),
    })
  )

  onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    actions.setStatus({ error: '' });
    const action = await this.props.createXeroAccount(values);
    actions.setSubmitting(false);
    if (action.type === CREATE_XERO_ACCOUNT_SUCCESS) {
      this.props.hideModal();
      if (this.props.session.user.plan.type !== "owner")
        this.props.showModal('property', { property: action.data });
    } else {
      const error = action.error && action.error.detail ?
        action.error.detail : 'Error creating account in Xero.';
      actions.setStatus({ error });
    }
  }

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.ValidationSchema}
        onSubmit={this.onSubmit}
        validate={this.validateForm}
      >

        {({
          status, errors, touched, handleSubmit, isSubmitting, validateForm, values,
        }) => {
      	  const renderStatusError = () => {
      	    if (!status || !status.error) return null;

      	    return (
              <FormText color="danger" className="text-center mb-3">
                {status.error}
              </FormText>
      	    );
      	  };

      	  return (
            <Form onSubmit={handleSubmit}>
              <Container fluid>
                <Row form>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Account Code</Label>
                      <Input
                        name="accountCode"
                        tag={Field}
                        invalid={errors.accountCode && touched.accountCode}
                      />
                      <FormFeedback>{errors.accountCode}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={8}>
                    <FormGroup>
                      <Label>Account Name</Label>
                      <Input
                        name="accountName"
                        tag={Field}
                        invalid={errors.accountName && touched.accountName}
                      />
                      <FormFeedback>{errors.accountName}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        name="description"
                        tag={Field}
                        invalid={errors.description && touched.description}
                      />
                      <FormFeedback>{errors.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <FormText className="mb-3">
                  Submitting this form will create a ledger account in Xero and import it into
                  bnbsync for further editing.
                </FormText>

                {renderStatusError()}

                <div className="button-group text-right">
                  <ModalCancelButton />
                  <LoadButton
                    isLoading={isSubmitting}
                    type="submit"
                    width={180}
                  >
                    Create Xero Account
                  </LoadButton>
                </div>
              </Container>
            </Form>
      	  );
      	}}
      </Formik>
    );
  }
}

const mapStateToProps = ({ xero, session }) => ({ xero, session });

export default connect(mapStateToProps, {
  hideModal, showModal, createXeroAccount,
})(CreateXeroAccountForm);
