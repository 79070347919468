import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import UserSettingsForm from 'components/forms/UserSettings';
import DashboardContainer from 'components/dashboard/Container';
import Loading from 'components/Loading';
import { fetchMe } from 'actions/users/fetch';
import { logoutUser } from 'actions/session/logout';

class SettingsRoute extends React.Component {
  logout = () => {
    this.props.logoutUser();
    this.props.history.push('/');
  }

  render() {
    if (this.props.session.isLoading && !this.props.session.user.email)
      return <Loading />;

    return (
      <div className="account-route">
        <Container>
          <Row>
            <Col sm={{ size: 6, offset: 2 }}>
              <DashboardContainer>
                <UserSettingsForm user={this.props.session.user} />
              </DashboardContainer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps, { fetchMe, logoutUser })(SettingsRoute);
