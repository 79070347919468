import React from 'react';
import { connect } from 'react-redux';
import {
  Form, FormFeedback, FormGroup, Input, FormText,
} from 'reactstrap';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import LoadButton from 'components/buttons/LoadButton';
import {
  createXeroCredentials, CREATE_XERO_CREDENTIALS_FAILURE,
  CREATE_XERO_CREDENTIALS_SUCCESS,
} from 'actions/xero/credentials/create';
import { showModal } from 'actions/modals';
import ModalCancelButton from 'components/modals/CancelButton';

const XeroCredentialsForm = props => {
  const { status, errors, touched, handleSubmit, isSubmitting } = props;

  const renderStatusError = () => {
    if (!status || !status.error) return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder="Consumer Key"
          autoComplete="consumer-key"
          name="consumerKey"
          tag={Field}
          invalid={errors.consumerKey && touched.consumerKey}
        />
        <FormFeedback>{errors.consumerKey}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="Consumer Secret"
          type="password"
          name="consumerSecret"
          tag={Field}
          autoComplete="consumer-secret"
          invalid={errors.consumerSecret && touched.consumerSecret}
        />
        <FormFeedback>{errors.consumerSecret}</FormFeedback>
      </FormGroup>
      {renderStatusError()}
      <div className="button-container text-right">
        <ModalCancelButton text="Later" />
        <LoadButton
          type="submit"
          isLoading={isSubmitting}
        >
          Continue
        </LoadButton>
      </div>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    consumerKey: '',
    consumerSecret: '',
  }),

  validationSchema: Yup.object().shape({
    consumerKey: Yup.string().required('Required'),
    consumerSecret: Yup.string().required('Required'),
  }),

  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    return props.createXeroCredentials(values).then(action => {
      setSubmitting(false);
      if (action.type === CREATE_XERO_CREDENTIALS_FAILURE) {
        setStatus({ error: action.data.error });
      } else if (action.type === CREATE_XERO_CREDENTIALS_SUCCESS) {
        const { verificationUrl } = action.data;
        window.location.href = verificationUrl;
      }
    });
  },
})(XeroCredentialsForm);

const mapStateToProps = ({ session, xero }) => ({ session, xero });
export default connect(mapStateToProps, { createXeroCredentials, showModal })(FormikForm);
