import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Logo from 'components/Logo';
import LoginRoute from '../Login';
import LogoutRoute from '../Logout';
import RegisterRoute from '../Register';
import ForgotPasswordRoute from '../ForgotPassword';
import ResetPasswordRoute from '../ResetPassword';
import XeroOauthBeginRoute from '../XeroOauthBegin';
import './style.scss';

class AuthRoute extends React.Component {
  /* Parent layout route for all auth routes. */
  render() {
    return (
      <div className="auth-route">
        <Logo
          color="black"
          className="logo-circle mb-4"
          style={{ width: 200 }}
        />
        <section className="auth-content shadow-lg">
          <Switch>
            <Route path="/auth/xero-begin" component={XeroOauthBeginRoute} />
            <Route exact path="/auth/register" component={RegisterRoute} />
            <Route path="/auth/register/:type" component={RegisterRoute} />
            <Route path="/auth/login" component={LoginRoute} />
            <Route path="/auth/logout" component={LogoutRoute} />
            <Route path="/auth/forgot-password" component={ForgotPasswordRoute} />
            <Route
              path="/auth/password-reset/confirm/:uid/:token/"
              component={ResetPasswordRoute}
            />
          </Switch>
        </section>
      </div>
    );
  }
}

export default AuthRoute;
