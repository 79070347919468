import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { faSmile } from '@fortawesome/free-regular-svg-icons';

import HelpTooltip from 'components/HelpTooltip';
import './style.scss';

class ExportHistoryTable extends React.Component {
  // Render a table that shows the data for what/when the user has exported to Xero.
  render() {
    const renderExportRows = () => {
      return this.props.data.map((exportData) => {
        const trialIcon = <HelpTooltip
          id={`during-trial-${exportData.id}`}
          tooltipText="Trial export not counted in metrics or charges."
          icon={faSmile}
        />;

        return (
          <tr key={exportData.id}>
            <td>{moment(exportData.createdAt).format("LLL")}</td>
            <td>{exportData.property}</td>
            <td>{exportData.duringTrial ? trialIcon : null}</td>
          </tr>
        );
      });
    };

    return (
      <Table responsive className="export-history-table">
        <thead>
          <tr>
            <th>Export Date</th>
            <th>Property</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderExportRows()}
        </tbody>
      </Table>
    );
  }
}

ExportHistoryTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ExportHistoryTable;
