import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE } from 'actions/session/login';
import { LOGOUT } from 'actions/session/logout';
import { FETCH_ME_START, FETCH_ME_SUCCESS } from 'actions/users/fetch';
import { REGISTER_SUCCESS } from 'actions/users/register';
import { UPDATE_ME_START, UPDATE_ME_SUCCESS } from 'actions/users/update';
import { UPDATE_USER_SETTINGS_START, UPDATE_USER_SETTINGS_SUCCESS } from 'actions/users/settings/update';
import { FORGOT_PASSWORD_START, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from 'actions/session/forgotPassword';
import { RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from 'actions/session/resetPassword';
import { CREATE_XERO_CREDENTIALS_SUCCESS } from 'actions/xero/credentials/create';
import { DELETE_XERO_CREDENTIALS_SUCCESS } from 'actions/xero/credentials/delete';
import { VERIFY_XERO_CREDENTIALS_SUCCESS } from 'actions/xero/credentials/verify';
import { REFRESH_XERO_CREDENTIALS_SUCCESS } from 'actions/xero/credentials/refresh';
import { CREATE_STRIPE_CUSTOMER_SUCCESS } from 'actions/stripe/customers/create';
import { CONVERT_TRIAL_SUCCESS } from 'actions/users/convertTrial';

const initialState = {
  isLoading: false,
  error: null,
  token: null,
  user: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
    case FETCH_ME_START:
    case UPDATE_ME_START:
    case UPDATE_USER_SETTINGS_START:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        token: action.data.key,
      };
    case CONVERT_TRIAL_SUCCESS:
      return {
        ...state,
        user: action.data,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data,
      };
    case FETCH_ME_SUCCESS:
    case UPDATE_ME_SUCCESS:
      return { ...state, isLoading: false, user: action.data };
    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          settings: action.data,
        },
      };
    case FORGOT_PASSWORD_START:
    case RESET_PASSWORD_START:
      return { ...state, isLoading: true };
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_XERO_CREDENTIALS_SUCCESS:
    case VERIFY_XERO_CREDENTIALS_SUCCESS:
    case REFRESH_XERO_CREDENTIALS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          xero: action.data,
        },
      };
    case DELETE_XERO_CREDENTIALS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          xero: null,
        },
      };
    case CREATE_STRIPE_CUSTOMER_SUCCESS:
      return { ...state, user: action.data };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
