import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { addPropertyListing, ADD_PROPERTY_LISTING_SUCCESS } from 'actions/properties/addListing';
import { fetchGeneration } from 'actions/generations/fetch';
import LoadButton from 'components/buttons/LoadButton';
import SelectXeroAccount from 'components/autocomplete/XeroAccount';
import ModalCancelButton from '../CancelButton';
import './style.scss';


class ChangeListingModal extends React.Component {
  // Change the property account of a given listing from a transaction record

  state = {
    isLoading: false,
  }

  changeListing = (propertyId) => {
    this.setState({ isLoading: true });
    // remove a listing's current mapping and add it to the new property
    const { listing } = this.props.transaction;
    const { data } = this.props.generation;

    this.props.addPropertyListing(propertyId, {
      listing,
      replace: true,
    }).then((action) => {
      if (action.type === ADD_PROPERTY_LISTING_SUCCESS) {
        // update the generation data with a fetch generation
        this.props.fetchGeneration(data.id).then((action) => {
          this.setState({ isLoading: false });
          this.props.toggle();
          if (listing) toast(`${listing}'s account has been updated.`);
        });
      }
    });
  }

  render() {
    const {
      transaction, xero, properties, dispatch, addPropertyListing,
      fetchGeneration, ...rest
    } = this.props;

    const renderCurrentAccount = () => {
      if (!transaction.property) return null;

      return (
        <p className="text-muted">
          Listing <span className="source-listing">{ transaction.listing }</span> is currently connected to Xero account
          {' '}<span className="xero-account">{transaction.property.name}</span>
        </p>
      );
    };

    return (
      <Modal {...rest} className="change-listing-modal">
        <ModalHeader>
          Change Listing Account
        </ModalHeader>
        <ModalBody>
          {renderCurrentAccount()}
          <p className="text-muted">Select a new account below to remove this listing's current link, and link it to the new account.</p>
          <SelectXeroAccount
            items={this.props.properties.data}
            onSelect={this.changeListing}
          />
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton disabled={this.state.isLoading} />
          <LoadButton
            color="primary"
            className="btn-modal-save"
            isLoading={this.state.isLoading}
            disabled={this.state.isLoading}
          >
            Save
          </LoadButton>
        </ModalFooter>
      </Modal>
    );
  }
}

ChangeListingModal.propTypes = {
  transaction: PropTypes.object.isRequired,
};

const mapStateToProps = ({ xero, generation, properties }) => ({ xero, generation, properties });
export default connect(mapStateToProps, {
  addPropertyListing, fetchGeneration,
})(ChangeListingModal);
