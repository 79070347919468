import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from 'components/Loading';
import SelectXeroAccount from 'components/autocomplete/XeroAccount';
import { addPropertyListing, ADD_PROPERTY_LISTING_SUCCESS } from 'actions/properties/addListing';
import './style.scss';

class MapListing extends React.Component {
  // Take in a listing and map it to an account.
  state = { searchTerm: '' }

  mapListing = (propertyId) => {
    const { listing } = this.props.transaction;
    this.props.addPropertyListing(propertyId, { listing }).then((action) => {
      if (action.type === ADD_PROPERTY_LISTING_SUCCESS) toast(`${listing} has been mapped.`);
      else toast.error('An error occured while mapping this listing.');
    });
  }

  render() {
    const renderInner = () => {
      if (this.props.properties.isLoading) return <Loading />;

      return (
        <div>
          <p className="text-primary text-center">{this.props.transaction.listingDisplay}</p>
          <SelectXeroAccount
            onSelect={this.mapListing}
            items={this.props.properties.data}
          />
        </div>
      );
    };

    return (
      <div className="p-3 map-listing-container">
        {renderInner()}
      </div>
    );
  }
}

MapListing.propTypes = {
  transaction: PropTypes.object.isRequired,
};

const mapStateToProps = ({ properties }) => ({ properties });
export default connect(mapStateToProps, { addPropertyListing })(MapListing);
