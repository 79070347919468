import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import DashboardContainer from 'components/dashboard/Container';
import PaymentSourceTitle from 'components/plans/PaymentSourceTitle';
import StripeCheckout from 'components/forms/StripeCheckout';
import PaymentOptionsRow from 'components/plans/PaymentOptionsRow';
import { logoutUser } from 'actions/session/logout';
import { convertTrial } from 'actions/users/convertTrial';
import { hasActiveTrial } from 'utils/users';
import './style.scss';

class PaymentSourceRoute extends Component {
  state = {
    // Status determines what is rendered. Valid options are `info` and `card`.
    status: 'info',
  }

  cancel = () => {
    this.props.logoutUser();
    this.props.history.push('/');
  }

  startTrial = async () => {
    await this.props.convertTrial();
    this.props.history.push('/dashboard/accounts');
  }

  render() {
    const renderSecondaryLink = () => {
      /* Depending on the user's trial status there are a few things that can
         happen.

         1) User has an active trial - send to dashboard
         2) User has an inactive trial - display nothing
         3) User does not have any trial - start trial
      */
      const { trialEnd } = this.props.session.user;
      if (!trialEnd)
        return (
          <Button color="link" className="mr-4" onClick={this.startTrial}>
            Start Contact us
          </Button>
        );
      else if (hasActiveTrial(trialEnd))
        return (
          <Button
            color="link"
            className="mr-4"
            onClick={() => this.props.history.push('/dashboard/accounts')}
          >
            Dashboard
          </Button>
        );
      else
        return null;
    };

    const renderContent = () => {
      if (this.state.status === 'info')
        return (
          <Row className="mt-5">
            <Col sm={{ size: 8, offset: 2 }}>
              <PaymentSourceTitle>
                Bnbsync payment system
              </PaymentSourceTitle>

              <DashboardContainer>
                <div className="payment-grade-container my-5">
                  <PaymentOptionsRow />
                </div>
                <p className="text-center">
                  More than 100 properties? Get in touch after signup, there are
                  custom options as well.
                </p>
                <div className="button-container clearfix mt-5 text-right">
                  <Button color="link" className="float-left" onClick={this.cancel}>
                    Cancel
                  </Button>
                  {renderSecondaryLink()}
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => this.setState({ status: 'card' })}
                  >
                    Enter Payment Info <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                  </Button>
                </div>
              </DashboardContainer>
            </Col>
          </Row>
        );
      else if (this.state.status === 'card')
        return (
          <Row className="mt-5">
            <Col sm={{ size: 4, offset: 4 }}>
              <PaymentSourceTitle>
                Enter payment info
              </PaymentSourceTitle>

              <DashboardContainer>
                <Elements>
                  <StripeCheckout />
                </Elements>
              </DashboardContainer>
            </Col>
          </Row>
        );
    };

    return (
      <div className="payment-source-route">
        {renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps, { logoutUser, convertTrial })(PaymentSourceRoute);
