import axios from 'axios';

import { XERO_BASE_URL } from 'actions/xero';


export const FETCH_GENERATION_START = 'FETCH_GENERATION_START';
const fetchGenerationStart = () => (
  { type: FETCH_GENERATION_START }
);

export const FETCH_GENERATION_SUCCESS = 'FETCH_GENERATION_SUCCESS';
const fetchGenerationSuccess = data => (
  { type: FETCH_GENERATION_SUCCESS, data }
);

export const fetchGeneration = id => ((dispatch) => {
  dispatch(fetchGenerationStart());
  const url = `${XERO_BASE_URL}generations/${id}/`;

  return axios.get(url).then((response) => {
    dispatch(fetchGenerationSuccess(response.data));
  });
});
