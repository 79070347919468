import React from 'react';
import PropTypes from 'prop-types';

import XeroConnectImage from 'images/xero/connect-blue.svg';
import './style.scss';

class XeroConnectButton extends React.Component {
  /* Button that will send the user to Xero to authenticate their bnbsync account with Xero. */
  render() {
    return (
      <img
        src={XeroConnectImage}
        alt="Connect Xero bnbsync"
        className="xero-connect-button"
        onClick={() => window.location.href = this.props.oauthUrl}
      />
    );
  }
}

XeroConnectButton.propTypes = {
  oauthUrl: PropTypes.string.isRequired,
};

export default XeroConnectButton;
