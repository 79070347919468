import { SEARCH_HELP_ARTICLES, CLEAR_ARTICLE_SEARCH } from 'actions/help/search';
import { FETCH_HELP_TOPICS_START, FETCH_HELP_TOPICS_SUCCESS } from 'actions/help/fetch';

const initialState = {
  topics: [],
  filteredArticles: [],
  searchTerm: '',
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HELP_TOPICS_START:
      return { ...state, isLoading: true };
    case FETCH_HELP_TOPICS_SUCCESS:
      return { ...state, isLoading: false, topics: action.data };
    case SEARCH_HELP_ARTICLES: {
      const searchTerm = action.text.toLowerCase();
      const filteredArticles = state.topics
        .map(topic => topic.articles)
        .flat()
        .filter(article => article.title.toLowerCase().includes(searchTerm)
                 || article.content.toLowerCase().includes(searchTerm));

      return { ...state, filteredArticles, searchTerm };
    }
    case CLEAR_ARTICLE_SEARCH:
      return { ...state, filteredArticles: [], searchTerm: '' };
    default:
      return state;
  }
};
