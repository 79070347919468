import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { clearHelpSearch } from 'actions/help/search';
import './style.scss';


class ArticleList extends React.Component {
  // Display a list of articles that are for a given topic or search filtered.
  navigateArticle = (article) => {
    const dashboardPrefix = this.props.location.pathname.includes('dashboard')
      ? '/dashboard' : '';

    this.props.clearHelpSearch();
    this.props.history.push(`${dashboardPrefix}/help/articles/${article.slug}`);
  }

  render() {
    if (!this.props.articles.length > 0) {
      return (
        <div className="text-center">
          <p>There are no articles that fit this filter.</p>
          <Button color="link" onClick={this.props.clearHelpSearch}>Clear Search</Button>
        </div>
      );
    }

    const renderArticles = article => this.props.articles.filter(article => article.published).map(article => (
      <div
        key={article.id}
        className="article-preview-container"
        onClick={() => this.navigateArticle(article)}
      >
        <h3>{article.title}</h3>
        <p>{article.preview}</p>
      </div>
    ));

    return (
      <div className="articles-container">
        {renderArticles()}
      </div>
    );
  }
}

ArticleList.propTypes = {
  articles: PropTypes.array,
  hideClear: PropTypes.bool,
};

const ConnectedComponent = connect(null, { clearHelpSearch })(ArticleList);
export default withRouter(ConnectedComponent);
