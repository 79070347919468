import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import DashboardContainer from 'components/dashboard/Container';
import TopicSidebar from '../TopicSidebar';
import ArticleList from '../ArticleList';
import HelpHeader from '../HelpHeader';
import './style.scss';


class Topic extends React.Component {
  // Show the articles of a given topic.
  render() {
    const helpReducer = this.props.help;
    const renderContent = () => {
      // Either show the topic articles or filtered articles if searching.
      if (!helpReducer.searchTerm) {
        return (
          <Row>
            <Col>
              <DashboardContainer className="topic-content-container">
                <div className="topic-articles">
                  <ArticleList articles={this.props.topic.articles} />
                </div>
                <TopicSidebar activeTopicId={this.props.topic.id} />
              </DashboardContainer>
            </Col>
          </Row>
        );
      }
      return (
        <DashboardContainer>
          <ArticleList articles={helpReducer.filteredArticles} />
        </DashboardContainer>
      );
    };

    return (
      <Container className="topic-container">
        <HelpHeader topic={this.props.topic} />
        {renderContent()}
      </Container>
    );
  }
}

Topic.propTypes = {
  topic: PropTypes.object.isRequired,
};

const mapStateToProps = ({ help }) => ({ help });
export default connect(mapStateToProps)(Topic);
