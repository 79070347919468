import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from 'components/forms/auth/Login';
import './style.scss';

class LoginRoute extends React.Component {
  render() {
    return (
      <div className="login-route">
        <LoginForm />
        <div className="auth-extra-container m-2 text-center">
          <Link to="/auth/forgot-password">Forgot password?</Link>
        </div>
      </div>
    );
  }
}

export default LoginRoute;
