import axios from 'axios';

import { STRIPE_BASE_URL } from '../index';


export const CREATE_STRIPE_CUSTOMER_START = "CREATE_STRIPE_CUSTOMER_START";
const createStripeCustomerStart = () => ({ type: CREATE_STRIPE_CUSTOMER_START });

export const CREATE_STRIPE_CUSTOMER_SUCCESS = "CREATE_STRIPE_CUSTOMER_SUCCESS";
const createStripeCustomerSuccess = data => ({
  type: CREATE_STRIPE_CUSTOMER_SUCCESS,
  data,
});

export const CREATE_STRIPE_CUSTOMER_FAILURE = "CREATE_STRIPE_CUSTOMER_FAILURE";
const createStripeCustomerFailure = error => ({
  type: CREATE_STRIPE_CUSTOMER_FAILURE,
  error,
});


export const createStripeCustomer = payload => (dispatch => {
  const url = `${STRIPE_BASE_URL}customers/`;
  dispatch(createStripeCustomerStart());

  return axios.post(url, payload)
    .then(
      response => dispatch(createStripeCustomerSuccess(response.data)),
      error => dispatch(createStripeCustomerFailure(error.response.data)),
    );
});
