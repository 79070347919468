import React from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';

import { showModal } from 'actions/modals';
import { resetXeroImport } from 'actions/generations/create';
import { createXeroCredentials } from 'actions/xero/credentials/create';
import { createXeroInvoice, CREATE_XERO_INVOICE_SUCCESS,
         CREATE_XERO_INVOICE_FAILURE } from 'actions/xero/invoices/create';
import { deleteGenerationInvoices } from 'actions/invoices/delete';
import { toggleInvoicesExport } from 'actions/generations/toggleExport';
import { fetchXeroAccounts } from 'actions/xero/accounts/fetch';
import Loading from 'components/Loading';
import CSVImportForm from 'components/forms/CsvImport';
import DashboardContainer from 'components/dashboard/Container';
import SmallContainer from 'components/dashboard/SmallContainer';
import DashboardHeader from 'components/dashboard/Header';
import ExportSteps from 'features/export/ExportSteps';
import MapListing from 'features/export/MapListing';
import XeroInvoice from 'features/export/XeroInvoice';
import './style.scss';

class ExportRoute extends React.Component {
  state = {
    data: {},
    currentStep: 1,
    mappedListings: 0,
  }

  componentDidMount() {
    // Always start from scratch.
    this.props.resetXeroImport();
  }

  componentWillUnmount() {
    if (this.props.generation.data.id) {
      this.props.deleteGenerationInvoices(this.props.generation.data.id);
      this.props.resetXeroImport();
    }
  }

  exportInvoices = async () => {
    let { invoices } = this.props.generation.data;
    const exportedInvoices = invoices.filter(invoice => invoice.isExported);
    const payload = { invoices: exportedInvoices.map((invoice) => invoice.id) };

    const action = await this.props.createXeroInvoice(payload);
    if (action.type === CREATE_XERO_INVOICE_SUCCESS) {
      this.setState({ currentStep: 5 });
    } else if (action.type === CREATE_XERO_INVOICE_FAILURE) {
      toast.error(this.props.xero.error || 'Error creating Xero invoice.', {
        autoClose: 5000,
      });
    }
  }

  resetExport = () => {
    // Return back to step 1 if the user decides to cancel picking invoices.
    this.props.deleteGenerationInvoices(this.props.generation.data.id);
    this.props.resetXeroImport();
    this.setState({ currentStep: 1 });
  }

  toggleInvoicesSelected = (isExported) => {
    // Either select or unselect all valid invoices.
    const generationId = this.props.generation.data.id;
    const payload = { isExported };
    this.props.toggleInvoicesExport(generationId, payload);
  }

  getGenerationTransactions = () => {
    // Return all transactions for the generation.
    const allTransactions = [];
      const { data } = this.props.xero;

    // Make an array of all the transactions of the generation.
    if ((!data || !data.invoices) && !this.props.generation.data.invoices) return allTransactions;

    this.props.generation.data.invoices.map((invoice) => {
      const invoiceTransactions = invoice.transactions.map((transaction) => {
        const { id, listing, property, listingDisplay } = transaction;
        return { id, listing, property, listingDisplay };
      });

      return allTransactions.push(...invoiceTransactions);
    });

    return allTransactions;
  }

  getListingsNeedingMap = () => {
    /* Return an array of listings that need to be mapped to an account.
       Listings should not appear more than once in this returned array. */
    const allTransactions = this.getGenerationTransactions();
    const listingsAdded = [];

    return allTransactions.filter((transaction) => {
      const { listing } = transaction;

      if (listingsAdded.includes(listing)) return false;

      listingsAdded.push(listing);
      return listing.length && !transaction.property;
    });
  }

  render() {
    // Require that the users have property accounts loaded.
    if (!this.props.properties.data.length) {
      return (
        <SmallContainer>
          <p className="text-muted">You must have property accounts loaded to export data to Xero.</p>
          {this.props.xero.isLoading ?
            <Loading /> :
            <Button color="link" onClick={() => this.props.showModal('importProperties')}>
              Import Accounts
            </Button>
          }
        </SmallContainer>
      );
    }

    const { currentStep } = this.state;

    // Save the listings needing map since we use in multiple places.
    const listingsNeedingMap = this.getListingsNeedingMap();
    let currency = 'AUD'
    if (this.props.session.user.settings.currency) {
      const currency_id = this.props.session.user.settings.currency
      currency = this.props.currencies.data.find(currency => currency.id === currency_id).code
    };
    const renderInvoices = () => this.props.generation.data.invoices.map(invoice => (
      <XeroInvoice source={this.props.generation.data.source} currency={currency} invoice={invoice} key={invoice.id} />
    ));

    const resetExportButton = (
      <Button
        color="link"
        className="mr-3"
        onClick={this.resetExport}
      >
        Cancel
      </Button>
    );

    const renderListingsContainer = () => {
      // There are no listings to map.
      if (!listingsNeedingMap.length) {
        return (
          <div className="text-center">
            <h4 className="p-5 mb-0 text-success">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-3" />
              All listings are linked to an account.
            </h4>
            <div className="button-container text-right">
              {resetExportButton}
              <Button
                size="lg"
                color="primary"
                onClick={() => this.setState({ currentStep: 3 })}
              >
                Continue
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </div>
        );
      }

      // We'll use the first transaction listing for rendering `MapListing`.
      const transaction = listingsNeedingMap[0];
      return (
        <div>
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <p className="text-muted">
                You have <span className="text-danger">{listingsNeedingMap.length}</span> listings remaining that aren't connected to an account.
              </p>
              <p className="text-muted">Please select the account for each listing below. Any future transactions with this listing name will be linked to the chosen account.</p>
              <MapListing transaction={transaction} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button-container text-left">
                {resetExportButton}
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const renderContent = () => {
      // Depending on where we're at in the import process, content will differ.
      if (currentStep === 1) {
        return (
          <DashboardContainer>
            <CSVImportForm
              progressToNextStep={() => this.setState({ currentStep: 2 })}
            />
          </DashboardContainer>
        );
      }
      if (currentStep === 2) {
        return (
          <DashboardContainer>
            {renderListingsContainer()}
          </DashboardContainer>
        );
      }
      if (currentStep === 3) {
        const hasExportedInvoices = () => {
          /* If there are no exported/valid invoices we need to disable the
             Continue button and dispaly some info to the user.
          */
          let hasExported = false;

          this.props.generation.data.invoices.forEach((invoice) => {
            if (invoice.isExported) hasExported = true;
          });

          return hasExported;
        };

        return (
          <div className="invoice-container">
            <div className="invoices-header">
              <Button
                onClick={() => this.toggleInvoicesSelected(true)}
                color="link"
              >
                Select all
              </Button>
              <Button
                onClick={() => this.toggleInvoicesSelected(false)}
                color="link"
              >
                Unselect all
              </Button>
            </div>
            {renderInvoices()}
            <div className="button-container text-right">
              {resetExportButton}
              <Button
                size="lg"
                color="primary"
                onClick={() => this.setState({ currentStep: 4 })}
                disabled={!hasExportedInvoices()}
              >
                Continue
                {' '}
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </div>
        );
      }
      if (currentStep === 4) {
        return (
          <Row className="export-options">
            <Col>
              <DashboardContainer>
                <h4>Export to Xero</h4>
                <p className="text-muted text-center">
                  The selected invoices will be exported to your connected Xero account.
                </p>
                <div className="button-container">
                  <Button
                    className="btn-export-invoices"
                    color="primary"
                    disabled={this.props.xero.isLoading}
                    onClick={this.exportInvoices}
                  >
                    { this.props.xero.isLoading
                      ? <Spinner color="light" className="spinner-button" />
                      : (
                        <span>
                          Export
                          <FontAwesomeIcon icon={faFileExport} />
                        </span>
                      )
                    }
                  </Button>
                </div>
              </DashboardContainer>
            </Col>
          </Row>
        );
      }
      if (currentStep === 5) {
        return (
          <Row>
            <Col sm={{ size: 6, offset: 3 }} className="text-center">
              <DashboardContainer>
                <h2 className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} /> Success!
                </h2>
                <p className="text-muted mt-4">
                  Check out your <a href="https://go.xero.com/AccountsReceivable/Search.aspx?invoiceStatus=INVOICESTATUS/DRAFT" target="_blank" rel="noopener noreferrer">draft invoices</a> in Xero.
                </p>
                <Button
                  color="link"
                  className="mt-2"
                  onClick={() => this.setState({ currentStep: 1 })}
                >
                  Import again
                </Button>
              </DashboardContainer>
            </Col>
          </Row>
        );
      }
    };

    return (
      <div className="export-route">
        <Container>
          <DashboardHeader>
            <ExportSteps step={this.state.currentStep} />
          </DashboardHeader>
          {renderContent()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ xero, properties, session, generation, currencies }) => (
  { xero, properties, session, generation, currencies }
);

export default connect(mapStateToProps, {
  resetXeroImport,
  deleteGenerationInvoices,
  toggleInvoicesExport,
  showModal,
  createXeroInvoice,
  createXeroCredentials,
  fetchXeroAccounts,
})(ExportRoute);
