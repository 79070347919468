import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './style.scss';

class TopicSidebar extends React.Component {
  navigateTopic = (topic) => {
    const dashboardPrefix = this.props.location.pathname.includes('dashboard')
      ? '/dashboard' : '';

    this.props.history.push(`${dashboardPrefix}/help/topics/${topic.slug}`);
  }

  // Show all the help topics along the side of the screen.
  render() {
    const renderTopics = () => this.props.help.topics.map((topic) => {
      const className = topic.id === this.props.activeTopicId ? 'active' : '';
      return (
        <p
          onClick={() => this.navigateTopic(topic)}
          className={className}
          key={topic.id}
        >
          {topic.title}
        </p>
      );
    });

    return (
      <div className="topic-sidebar">
        {renderTopics()}
      </div>
    );
  }
}

TopicSidebar.propTypes = {
  activeTopicId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ help }) => ({ help });
const connectedComponent = connect(mapStateToProps)(TopicSidebar);
export default withRouter(connectedComponent);
