import { combineReducers } from 'redux';

import SessionReducer from './session';
import PropertiesReducer from './properties';
import XeroFilesReducer from './xero';
import ModalReducer from './modals';
import HelpReducer from './help';
import CurrenciesReducer from './currencies';
import GenerationReducer from './generations';

const rootReducer = combineReducers({
  session: SessionReducer,
  properties: PropertiesReducer,
  xero: XeroFilesReducer,
  modal: ModalReducer,
  help: HelpReducer,
  currencies: CurrenciesReducer,
  generation: GenerationReducer,
});

export default rootReducer;
