import axios from 'axios';

import { XERO_BASE_URL } from '../index';


export const CREATE_XERO_CREDENTIALS_START = 'CREATE_XERO_CREDENTIALS_START';
const createXeroCredentialsStart = () => (
  { type: CREATE_XERO_CREDENTIALS_START }
);

export const CREATE_XERO_CREDENTIALS_SUCCESS = 'CREATE_XERO_CREDENTIALS_SUCCESS';
const createXeroCredentialsSuccess = data => (
  { type: CREATE_XERO_CREDENTIALS_SUCCESS, data }
);

export const CREATE_XERO_CREDENTIALS_FAILURE = 'CREATE_XERO_CREDENTIALS_FAILURE';
const createXeroCredentialsFailure = data => (
  { type: CREATE_XERO_CREDENTIALS_FAILURE, data }
);

export const createXeroCredentials = payload => ((dispatch) => {
  dispatch(createXeroCredentialsStart(payload));
  const url = `${XERO_BASE_URL}credentials/`;
  return axios.post(url, payload)
    .then(
      response => dispatch(createXeroCredentialsSuccess(response.data)),
      error => dispatch(createXeroCredentialsFailure(error.response.data)),
    );
});
