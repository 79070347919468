import React from 'react';

import FeaturesList from '../FeaturesList';

export const ManagerFeaturesList = () => (
  <FeaturesList
    features={[
      'Up to 50 properties',
      'Import Data from Airbnb',
      'Reconcile Transactions',
      'Calculate Commissions',
      'Charge Cleaning fees',
      'Categorise Tracking Categories',
      'Batch Owner Payouts',
    ]}
  />
);
