import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import ContactForm from 'components/forms/Contact';
import LandingFeaturesCarousel from 'components/carousels/LandingFeatures';
import LandingNav from 'components/LandingNav';
import PaymentOptionsRow from 'components/plans/PaymentOptionsRow';
import './style.scss';


class LandingRoute extends React.Component {
  constructor(props) {
    super(props);
    this.sectionRefs = {
      welcomeRef: React.createRef(),
      contactRef: React.createRef(),
      featuresRef: React.createRef(),
      pricingRef: React.createRef(),
    };
  }

  componentDidMount() {
    // Send user to dashboard if they are logged in.
    if (this.props.session.token) this.props.history.push('/dashboard/export');
  }

  //navigateStart = () => {
  //  this.props.history.push('/auth/register/trial');
  //}

  handleScrollToRef = () => {

    this.sectionRefs.contactRef.current.scrollIntoView({ behavior: 'smooth'});

  }
  
   
  render() {
    return (
      <div className="landing-route">
        <LandingNav sectionRefs={this.sectionRefs} />
        <section className="welcome-section" ref={this.sectionRefs.welcomeRef}>
          <div className="heading-container">
            <h1>bnbsync</h1>
            <h2>Airbnb & Xero Integration</h2>
            <p>Bnbsync quickly brings your Airbnb transaction data into xero, giving you more time to look after your guest and clients.</p>
            <Button
              color="dark"
              size="lg"
              onClick={this.handleScrollToRef}
            >
              Contact us
            </Button>
          </div>
          <div className="carousel-container d-none d-xl-block">
            <LandingFeaturesCarousel />
          </div>
        </section>

        <section className="features-section" ref={this.sectionRefs.featuresRef}>
          <h1 className="section-title">
            Our Features
          </h1>

          <Fade bottom>
            <Row className="feature">
              <Col className="feature-description">
                <h4>
                  Import Property Accounts
                </h4>
                <p>
		              Once connected with the Xero API, Bnbsync is able to download the accounts in your Xero file and allow the ledger accounts to be mapped to your properties in seconds. If you're a property manager, this is where you can input your commission percentage, link owners to properties and update payment information.
                </p>
                <p>
                 Bnbsync has been designed and developed by property managers, for property managers.
                </p>
              </Col>
              <Col className="feature-image" sm={7}>
                <img src="/images/landing/bnb-properties-1.png" alt="bnbsync properties" />
              </Col>
            </Row>
          </Fade>

          <Fade bottom>
            <Row className="feature">
              <Col className="feature-image" sm={7}>
                <img src="/images/landing/bnb-export-1.png" alt="bnbsync xero export" />
              </Col>
              <Col className="feature-description">
                <h4>
                  Generate Invoices
                </h4>
                <p>
                  Bnbsync generates invoices and bills payable for each reservation on your Airbnb calendar. With each deposit from Airbnb, bnbsync matches these transactions, splitting them in to the corresponding property account ready for distribution to owners or to your own bank account.
                </p>
                <p>
                  Data entry is tough and this is where the majority of errors are made, Bnbsync is linked to your xero file and uses transaction data from your Airbnb account, work smarter and faster.
                </p>
              </Col>
            </Row>
          </Fade>

          <Fade bottom>
            <Row className="feature">
              <Col className="feature-description">
                <h4>
                  Create Payments
                </h4>
                <p>
                  If you're a property manager, making payments to your owners has never been easier. You're a few clicks away from batch paying everyone, whether it is 10 or 100 properties, it's a snap.
                </p>
                <p>
		              Just click down the list of available funds to payout to owners and export to Xero, it's that simple.
                </p>
              </Col>
              <Col className="feature-image" sm={7}>
                <img src="/images/landing/bnb-payments-1.png" alt="bnbsync export payments" />
              </Col>
            </Row>
          </Fade>
        </section>

        <section className="pricing-section" ref={this.sectionRefs.pricingRef}>
          <h1 className="section-title">
            Pricing
          </h1>
          <h4 className="text-center mb-5">Pay only for active properties you use each month</h4>
          <Bounce>
            <PaymentOptionsRow>
              <Button block color="primary" onClick={this.handleScrollToRef}>Contact us</Button>
            </PaymentOptionsRow>
          </Bounce>
          <h4 className="text-center mt-5">
            Have more than 100 properties? Talk to us about your needs.
          </h4>
        </section>

        <section className="contact-section" ref={this.sectionRefs.contactRef}>
          <h1 className="section-title">
            Contact us
          </h1>
          <Row>
            <Col sm={{ size: 6, offset: 3 }} className="contact-form-container">
              <ContactForm />
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps)(LandingRoute);
