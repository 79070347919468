import axios from 'axios';
import { API_URL } from 'config';


export const VOTE_ARTICLE_START = 'VOTE_ARTICLE_START';
const voteArticleStart = () => ({ type: VOTE_ARTICLE_START });

export const voteArticle = ({ articleId, upvote }) => (dispatch) => {
  dispatch(voteArticleStart());
  const url = `${API_URL}articles/${articleId}/vote/`;
  return axios.post(url, { upvote });
};
