import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardContainer from 'components/dashboard/Container';
import './style.scss';

class TopicBlock extends React.Component {
  navigateTopic = () => {
    const dashboardPrefix = this.props.location.pathname.includes('dashboard')
      ? '/dashboard' : '';

    this.props.history.push(`${dashboardPrefix}/help/topics/${this.props.topic.slug}`);
  }

  // Show a topic in block form with some summary data.
  render() {
    const { title, description } = this.props.topic;

    return (
      <DashboardContainer className="topic-block" onClick={this.navigateTopic}>
        <div className="icon-container">
          <FontAwesomeIcon icon={this.props.icon} />
        </div>
        <div className="topic-content-container">
          <h4>{title}</h4>
          <p className="topic-description">{description}</p>
        </div>
      </DashboardContainer>
    );
  }
}

TopicBlock.propTypes = {
  topic: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
};

export default withRouter(TopicBlock);
