import axios from 'axios';

import { XERO_BASE_URL } from 'actions/xero';


export const CREATE_TRANSACTION_START = 'CREATE_TRANSACTION_START';
const createTransactionStart = () => (
  { type: CREATE_TRANSACTION_START }
);

export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
const createTransactionSuccess = data => (
  { type: CREATE_TRANSACTION_SUCCESS, data }
);

export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';
const createTransactionFailure = error => (
  { type: CREATE_TRANSACTION_FAILURE, error }
);

export const createTransaction = payload => ((dispatch) => {
  // Optomistically create a transaction on an export invoice.
  dispatch(createTransactionStart());
  const url = `${XERO_BASE_URL}transactions/`;
  return axios.post(url, payload).then(
    response => dispatch(createTransactionSuccess(response.data)),
    error => dispatch(createTransactionFailure(error.response.data)),
  );
});
