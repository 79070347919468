import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'reactstrap';

class InvoiceIcon extends React.Component {
  /* Icon that should be shown for the invoice. The icon depends on the status
     of the invoice such as exported, ignored, or error/warning, which is
     determined by the props passed into this component.
  */

  state = { tooltipOpen: false }

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })

  render() {
    const { tooltip, ...rest } = this.props;

    return (
      <div>
        <FontAwesomeIcon size="2x" {...rest} />
        <Tooltip
          isOpen={this.state.tooltipOpen}
          target={this.props.id}
          toggle={this.toggleTooltip}
        >
          {this.props.tooltip}
        </Tooltip>
      </div>
    );
  }
}

InvoiceIcon.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default InvoiceIcon;
