import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { hideModal } from 'actions/modals';


const ModalCancelButton = ({ hideModal, text, onClick, disabled=false }) => (
  <Button color="link" className="mr-3" onClick={onClick || hideModal} disabled={disabled}>
    { text || 'Cancel' }
  </Button>
);


export default connect(null, { hideModal })(ModalCancelButton);
