import axios from 'axios';

import { XERO_BASE_URL } from '../index';
import { fetchXeroExports } from 'actions/xero/exports/fetch';
import { fetchMe } from 'actions/users/fetch';

export const CREATE_XERO_INVOICE_START = 'CREATE_XERO_INVOICE_START';
const createXeroInvoiceStart = () => ({ type: CREATE_XERO_INVOICE_START });

export const CREATE_XERO_INVOICE_SUCCESS = 'CREATE_XERO_INVOICE_SUCCESS';
const createXeroInvoiceSuccess = data => ({
  type: CREATE_XERO_INVOICE_SUCCESS, data,
});

export const CREATE_XERO_INVOICE_FAILURE = 'CREATE_XERO_INVOICE_FAILURE';
const createXeroInvoiceFailure = error => ({
  type: CREATE_XERO_INVOICE_FAILURE, error,
});


export const createXeroInvoice = payload =>
  /* create an invoice on our Xero account. These invoices will create bills
     that we can pay by creating payments, or manually paying on Xero.
  */
  (dispatch) => {
    dispatch(createXeroInvoiceStart());
    const url = `${XERO_BASE_URL}invoices/`;
    return axios.post(url, payload).then(
      (response) => {
        dispatch(fetchXeroExports());
        dispatch(fetchMe());
        return dispatch(createXeroInvoiceSuccess(response.data));
      },
      (error) => {
        const message = error && error.response ?
          error.response.data.detail :
          'Error creating Xero invoice.';
        return dispatch(createXeroInvoiceFailure(message));
      },
    );
  };
