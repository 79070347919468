import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Form, FormText, FormGroup, Input, FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import LoadButton from 'components/buttons/LoadButton';
import { loginUser, LOGIN_SUCCESS, LOGIN_FAILURE } from 'actions/session/login';
import { fetchMe } from 'actions/users/fetch';

const LoginForm = props => {
  const { status, errors, touched, handleSubmit, isSubmitting } = props;

  const renderStatusError = () => {
    if (!status || !status.error) return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder="email"
          name="email"
          tag={Field}
          invalid={errors.email && touched.email}
        />
        <FormFeedback>{errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          placeholder="password"
          name="password"
          tag={Field}
          invalid={errors.password && touched.password}
          autoComplete="password"
        />
        <FormFeedback>{errors.password}</FormFeedback>
      </FormGroup>
      {renderStatusError()}
      <LoadButton block color="primary" isLoading={isSubmitting} type="submit">
        Submit
      </LoadButton>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    email: '',
    password: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email'),
    password: Yup.string().required('Required').min(6),
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus, resetForm }) => {
    const action = await props.loginUser(values);

    if (action.type === LOGIN_FAILURE) {
      setStatus({
        error: 'Unable to login with the provided credentials.',
      });
      setSubmitting(false);
    } else if (action.type === LOGIN_SUCCESS) {
      // Set the user's data after successful login.
      const fetchMeAction = await props.fetchMe();
      setSubmitting(false);
      resetForm();

      if (fetchMeAction.data.hasPremiumAccess)
        props.history.push('/dashboard/accounts');
      else
        props.history.push('/payments');
    }
  },
})(LoginForm);

const mapStateToProps = ({ session }) => ({ session });
const routedComponent = withRouter(FormikForm);
export default connect(mapStateToProps, { loginUser, fetchMe })(routedComponent);
