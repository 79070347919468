import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { createXeroCredentials } from 'actions/xero/credentials/create';
import ConnectXeroButton from 'components/buttons/ConnectXero';
import Loading from 'components/Loading';
import './style.scss';

class XeroOauthBeginRoute extends React.Component {
  /* Users will be directed to this route before being sent to Xero to allow
     access between Xero and bnbsync.
  */

  state = {
    isLoading: false,
    oauthUrl: null,
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const action = await this.props.createXeroCredentials();
    this.setState({
      oauthUrl: action.data.oauthUrl,
      isLoading: false,
    });
  }

  render() {
    const { oauthUrl, isLoading } = this.state;
    if (isLoading)
      return (
        <div className="p-5">
          <h5 className="m-3">Fetching xero oauth url...</h5>
          <Loading />
        </div>
      );

    if (!oauthUrl)
      return (
        <div className="xero-oauth-begin-route text-center">
          <h5>Error authenticating with Xero, no oauth url available.</h5>
          <Button color="primary" className="m-3">Dashboard</Button>
        </div>
      );

    return (
      <div className="xero-oauth-begin-route">
        <p>Bnbsync requires an integration with your Xero account.</p>
        <div className="button-container text-center">
          <ConnectXeroButton oauthUrl={this.state.oauthUrl} />
        </div>
      </div>
    );
  }
}

export default connect(null, { createXeroCredentials })(XeroOauthBeginRoute);
