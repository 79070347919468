import axios from 'axios';

import { AUTH_BASE_URL } from '../index';


export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
const resetPasswordStart = () => ({ type: RESET_PASSWORD_START });

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const resetPasswordSuccess = data => ({ type: RESET_PASSWORD_SUCCESS });

export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
const resetPasswordFailure = error => ({ type: RESET_PASSWORD_FAILURE, error });


export const resetPassword = payload => (dispatch) => {
  /* Reset the user's password. This action is fired after the user received the reset email and
     comes back into the app to reset their password. */
  dispatch(resetPasswordStart(payload));
  const url = `${AUTH_BASE_URL}password/reset/confirm/`;

  return axios.post(url, payload)
    .then(
      success => dispatch(resetPasswordSuccess(success.data)),
      error => dispatch(resetPasswordFailure(error.response.data)),
    );
};
