import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import * as Yup from 'yup';

import XeroDisconnect from 'images/xero/disconnect-blue.svg';
import LoadButton from 'components/buttons/LoadButton';
import HelpTooltip from 'components/HelpTooltip';
import { showModal } from 'actions/modals';
import { updateUserSettings, UPDATE_USER_SETTINGS_SUCCESS } from 'actions/users/settings/update';
import './style.scss';

class UserSettingsForm extends React.Component {
  state = {
    selectedUserType: this.props.user.type,
    gstToolTipOpen: false,
  }

  toggleGstTooltip = () => this.setState({
    gstToolTipOpen: !this.state.gstToolTipOpen,
  })

  render() {
    const renderDisconnectButton = () => {
      if (!this.props.session.user.xero)
        return null;
      return (
        <FormGroup className="my-4">
          <FormText className="mb-2">
            {`Connected to "${this.props.session.user.xeroOrgName}" Xero account.`}
          </FormText>
          <img
            src={XeroDisconnect}
            alt="xero disconnect bnbsync"
            className="btn-disconnect"
            onClick={() => this.props.showModal('removeXero')}
          />
        </FormGroup>
      );
    };

    const handleCurrencyChange = (e) => {
      const { value } = e.target;
      setFieldValue('currency', value);
    };

    const { handleSubmit, isSubmitting, values, setFieldValue } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="user-settings-form">
        <FormGroup className="toggle-form-group">
          <div className="toggle-container">
            <Field type="hidden" name="applyGst" value={false} />
            <Toggle
              defaultChecked={values.applyGst}
              onChange={() => setFieldValue('applyGst', !values.applyGst)}
            />
          </div>
          <Label className="m-2">
            Apply GST
            <HelpTooltip
              id="apply-gst"
              tooltipText="Include a line item for cleaning and commissions invoices for 10% of the original amount."
            />
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>Currency</Label>
          <Input
            type="select"
            name="currency"
            value={values.currency}
            onChange={handleCurrencyChange}
          >
            {this.props.currencies.data.map(currency => (
              <option key={currency.id} value={currency.id}>
                {currency.description} {currency.code}
              </option>
            ))}
          </Input>
        </FormGroup>
        {renderDisconnectButton()}
        <div className="submit-container">
          <LoadButton
            color="primary"
            isLoading={isSubmitting}
            type="submit"
          >
            Save
          </LoadButton>
        </div>
      </Form>
    );
  }
}

UserSettingsForm.propTypes = {
  user: PropTypes.object.isRequired,
};

const FormikForm = withFormik({
  mapPropsToValues: props => {
    return props.session.user.settings;
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    const action = await props.updateUserSettings(values);
    setSubmitting(false);
    if (action.type === UPDATE_USER_SETTINGS_SUCCESS)
      toast('Settings Saved');
    else
      toast('Error saving settings');
  },

  validationSchema: Yup.object().shape({
    applyGst: Yup.boolean().required('Required'),
  }),
})(UserSettingsForm);

const mapStateToProps = ({ session, currencies }) => ({ session, currencies });

export default connect(mapStateToProps, { updateUserSettings, showModal })(FormikForm);
