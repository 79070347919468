import React from 'react';
import PropTypes from 'prop-types';

import WhiteFull from 'images/logos/whiteFull.svg';
import BlackFull from 'images/logos/blackFull.svg';
import WhiteIcon from 'images/logos/whiteIcon.png';
import BlackIcon from 'images/logos/blackIcon.png';


class Logo extends React.Component {
  render() {
    const getSrc = () => {
      const { color, icon } = this.props;
      if (color === 'black') return icon ? BlackIcon : BlackFull;
      return icon ? WhiteIcon : WhiteFull;
    };
    return (
      <img {...this.props} src={getSrc()} alt="bnbsync" />
    );
  }
}

Logo.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.number,
};

export default Logo;
