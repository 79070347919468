import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import LoadButton from 'components/buttons/LoadButton';
import DashboardHeader from 'components/dashboard/Header';
import Loading from 'components/Loading';
import { createXeroCredentials } from 'actions/xero/credentials/create';
import { showModal } from 'actions/modals';
import AccountsTable from 'components/tables/Accounts';
import './style.scss';

class AccountsRoute extends React.Component {
  // Route to handle administration of our property account records.
  state = {
    importPropertiesIsLoading: false,
  }

  openImportModal = async () => {
    this.setState({ importPropertiesIsLoading: true });
    this.props.showModal('importProperties');
    this.setState({ importPropertiesIsLoading: false });
  }

  render() {
    if (this.props.properties.isLoading)
      return <Loading />;

      const userType= this.props.session.user.plan ? this.props.session.user.plan.type : 'manager';
      
      return(
      <div className="container accounts-route">
        <div className="row justify-content-center">
          <div className="col-8">
            <DashboardHeader>
              <LoadButton
                onClick={this.openImportModal}
                isLoading={this.state.importPropertiesIsLoading}
                width={155}
              >
                <FontAwesomeIcon className="mr-2" icon={faFileImport} />
                Import Accounts
              </LoadButton>
              <Button
                color="primary"
                className="float-right"
                onClick={() => this.props.showModal("createXeroAccount")}
              >
                Create Account <FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            </DashboardHeader>
            <AccountsTable
              accounts={this.props.properties.data}
              userType={userType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, properties, xero }) => ({
  session, properties, xero,
});

export default connect(mapStateToProps, {
  showModal, createXeroCredentials,
})(AccountsRoute);
