import React from 'react';
import Fade from 'react-reveal/Fade';

import Loading from 'components/Loading';
import Logo from 'components/Logo';

export default function LoadingContainer({ text='Loading' }) {
  return (
    <div className="text-center p-5">
      <Logo icon={0} style={{ width: 150 }} color="black" />
      <Fade duration={500}><h4 className="my-4">{text}</h4></Fade>
      <Loading style={{ width: 50, height: 50 }} className="m-5" />
    </div>
  );
};
