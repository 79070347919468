import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

import DashboardContainer from 'components/dashboard/Container';
import Loading from 'components/Loading';
import { fetchHelpTopics } from 'actions/help/fetch';
import { getTopicIcon } from 'utils/help';
import HelpSearch from '../Search';
import HelpArticle from '../HelpArticle';
import ArticleList from '../ArticleList';
import TopicBlock from '../TopicBlock';
import Topic from '../Topic';
import './style.scss';

class HelpContainer extends React.Component {
  /* Container to display our help section. This is used in both the dashboard
     and outside.

     This will render various different looking components based on if we are
     viewing the main help screen, a topic, or an article.
  */
  componentDidMount() {
    if (this.props.help.topics.length === 0) this.props.fetchHelpTopics();
  }

  render() {
    if (this.props.help.isLoading) return <Loading />;

    const { topicSlug, articleSlug } = this.props;
    const { searchTerm, filteredArticles } = this.props.help;

    if (topicSlug) {
      // We are trying to render a topic and its articles.
      const topic = this.props.help.topics.find(topic => topic.slug === topicSlug);

      if (topic) {
        return <Topic topic={topic} />;
      }
      // TODO: Show that topic not found.
    } else if (articleSlug) {
      const articles = this.props.help.topics.map(topic => topic.articles).flat();
      const article = articles.find(article => article.slug === articleSlug);

      if (article) {
        return <HelpArticle article={article} />;
      }
      // TODO: Show that article not found.
    } else if (searchTerm.length > 0) {
      return (
        <Container className="help-container">
          <HelpSearch />
          <DashboardContainer className="articles-container">
            <ArticleList articles={filteredArticles} />
          </DashboardContainer>
        </Container>
      );
    }

    const renderTopicBlocks = () => this.props.help.topics.map(topic =>
    // const icon = this.topicMap.topic[topic.slug] || faQuestionCircle
      (
        <Col sm={6} key={topic.id} className="mt-3">
          <TopicBlock topic={topic} icon={getTopicIcon(topic)} />
        </Col>
      ));

    // Return the topics if not trying to render a topic/article.
    return (
      <Container className="help-container">
        <HelpSearch />
        <Row>
          {renderTopicBlocks()}
        </Row>
      </Container>
    );
  }
}

HelpContainer.propTypes = {
  articleSlug: PropTypes.string,
  topicSlug: PropTypes.string,
};

const mapStateToProps = ({ help }) => ({ help });

export default connect(mapStateToProps, { fetchHelpTopics })(HelpContainer);
