import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import PropertyForm from 'components/forms/Property';

class PropertyModal extends React.Component {
  render() {
    const { dispatch, ...rest } = this.props;

    return (
      <Modal {...rest}>
        <ModalBody>
          <PropertyForm
            property={this.props.property}
            initialStatus={this.props.initialStatus}
          />
        </ModalBody>
      </Modal>
    );
  }
}

PropertyModal.propTypes = {
  property: PropTypes.object.isRequired,
  initialStatus: PropTypes.string,
};

const mapStateToProps = ({ properties, session }) => ({ properties, session });
export default connect(mapStateToProps)(PropertyModal);
