import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSync } from '@fortawesome/free-solid-svg-icons';

import './style.scss';


class AutocompleteBase extends React.Component {
  /* Base autocomplete select control that can have an `Autocomplete` component
     passed in as child.
  */

  render() {
    const renderRefreshButton = () => {
      // If there is a refreshAction passed in, should add the refresh button.
      if (this.props.refreshAction) {
        return (
          <InputGroupAddon
            addonType="append"
            className="btn-refresh"
            onClick={this.props.refreshAction}
          >
            <InputGroupText><FontAwesomeIcon icon={faSync} /></InputGroupText>
          </InputGroupAddon>
        );
      }
    };

    return (
      <InputGroup className={`autocomplete-base ${this.props.className}`}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
        </InputGroupAddon>
        {this.props.children}
        {renderRefreshButton()}
      </InputGroup>
    );
  }
}

AutocompleteBase.propTypes = {
  className: PropTypes.string.isRequired,
  refreshAction: PropTypes.func,
};

export default AutocompleteBase;
