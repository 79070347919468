import React from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Button,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import ModalCancelButton from 'components/modals/CancelButton';
import { logoutUser } from 'actions/session/logout';
import { updateMe } from 'actions/users/update';


class TermsOfServiceChangeModal extends React.Component {
  declineTerms = () => {
    this.props.toggle();
    this.props.logoutUser();
    this.props.history.push('/');
  }

  acceptTerms = () => {
    this.props.updateMe({ hasAgreedTos: true }).then(() => {
      this.props.toggle();
    });
  }

  render() {
    const { logoutUser, staticContext, ...rest } = this.props;

    return (
      <Modal {...rest} size="md" className="tos-changed-modal">
        <ModalHeader>
          Terms of Service have changed
        </ModalHeader>
        <ModalBody>
          To continue, you must accept the updated
          {' '}
          <Link to="/terms-of-service" target="_blank">Terms of Service</Link>
.
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="decline" onClick={this.declineTerms} />
          <Button color="primary" onClick={this.acceptTerms}>I accept</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(connect(null, { logoutUser, updateMe })(TermsOfServiceChangeModal));
