import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Logo from 'components/Logo';
import './style.scss';

class LandingNav extends React.Component {
  state = { isOpen: false }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const renderMainButton = () => {
      // Have a button to login or go to dashboard depending if the user is authed.
      let route; let
        actionText = null;

      if (this.props.session.token) {
        route = '/dashboard/accounts';
        actionText = 'Dashboard';
      } else {
        route = '/auth/register';
        actionText = 'Login';
      }

      return (
        <Button
          className="px-3"
          color="primary"
          onClick={() => this.props.history.push(route)}
        >
          {actionText}
        </Button>
      );
    };

    return (
      <Navbar className="help-navbar mb-5 pr-5" expand="md" color="dark">
        <NavbarBrand onClick={() => this.props.history.push('/')}>
          <Logo icon={1} color="white" style={{ height: 80 }} className="mr-4" />
          bnbsync
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle}>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="mt-1">
              {renderMainButton()}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
const ConnectedComponent = connect(mapStateToProps)(LandingNav);
export default withRouter(ConnectedComponent);
