import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import CreateTransactionForm from 'components/forms/xero/Transaction';

class CreateTransactionModal extends React.Component {
  render() {
    const { invoiceId, ...rest } = this.props;
    return (
      <Modal {...rest} className="create-transaction-modal">
        <ModalHeader>Create transaction</ModalHeader>
        <ModalBody>
          <p className="text-muted text-center">
            Add a new transaction to this invoice
          </p>
          <CreateTransactionForm invoiceId={this.props.invoiceId} />
        </ModalBody>
      </Modal>
    );
  }
}

CreateTransactionModal.propTypes = {
  invoiceId: PropTypes.number.isRequired,
};

export default CreateTransactionModal;
