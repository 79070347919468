import React from 'react';


class DashboardHeader extends React.Component {
  // Component to display content above normal dashboard content.
  render() {
    return (
      <div className="dashboard-header mb-4">
        {this.props.children}
      </div>
    );
  }
}

export default DashboardHeader;
