import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, FormFeedback, Button, FormGroup, Input, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import { resetPassword, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from 'actions/session/resetPassword';

const ResetPasswordForm = props => {
  const {
    status, errors, touched, handleSubmit, isSubmitting,
  } = props;

  const renderStatusError = () => {
    if (!status || !status.error) return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder="new password"
          type="password"
          name="new_password1"
          tag={Field}
          invalid={errors.new_password1 && touched.new_password1}
        />
        <FormFeedback>{errors.new_password1}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="confirm password"
          type="password"
          name="new_password2"
          tag={Field}
          invalid={errors.new_password2 && touched.new_password2}
        />
        <FormFeedback>{errors.new_password2}</FormFeedback>
      </FormGroup>
      {renderStatusError()}
      <Button block color="primary" disabled={isSubmitting} type="submit">
        Reset Password
      </Button>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: () => ({
    new_password1: '',
    new_password2: '',
  }),

  validationSchema: Yup.object().shape({
    new_password1: Yup.string()
      .required('Required')
      .min(6, 'password must be at least 6 characters'),
    new_password2: Yup.string()
      .required('Required')
      .min(6, 'password must be at least 6 characters'),
  }),

  handleSubmit: (values, { props, setSubmitting, setStatus, resetForm }) => {
    const { token, uid } = props;
    values = { ...values, token, uid };

    return props.resetPassword(values).then((action) => {
      setSubmitting(false);

      if (action.type === RESET_PASSWORD_FAILURE) {
        setStatus({ error: 'Could not reset password.' });
      } else if (action.type === RESET_PASSWORD_SUCCESS) {
        resetForm();
        toast('Password has been reset!', { autoClose: 3000 });
        props.history.push('/auth/login');
      }
    });
  },
})(ResetPasswordForm);

const ConnectedComponent = connect(null, { resetPassword })(FormikForm);
export default withRouter(ConnectedComponent);
