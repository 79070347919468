import axios from 'axios';

import { XERO_BASE_URL } from '../index';

export const FETCH_XERO_ACCOUNTS_START = 'FETCH_XERO_ACCOUNTS_START';
const fetchXeroAccountsStart = () => ({ type: FETCH_XERO_ACCOUNTS_START });

export const FETCH_XERO_ACCOUNTS_SUCCESS = 'FETCH_XERO_ACCOUNTS_SUCCESS';
const fetchXeroAccountsSuccess = data => ({
  type: FETCH_XERO_ACCOUNTS_SUCCESS,
  data,
});

export const FETCH_XERO_ACCOUNTS_FAILURE = 'FETCH_XERO_ACCOUNTS_FAILURE';
const fetchXeroAccountsFailure = () => ({ type: FETCH_XERO_ACCOUNTS_FAILURE });

export const fetchXeroAccounts = () => ((dispatch) => {
  // Fetch the user's xero accounts.
  const url = `${XERO_BASE_URL}properties/`;

  dispatch(fetchXeroAccountsStart());

  return axios.get(url)
    .then(
      response => dispatch(fetchXeroAccountsSuccess(response.data)),
      error => dispatch(fetchXeroAccountsFailure()),
    );
});
