import axios from 'axios';

import { XERO_BASE_URL } from '../index';

export const CREATE_XERO_ACCOUNT_START = 'CREATE_XERO_ACCOUNT_START';
const createXeroAccountStart = () => ({ type: CREATE_XERO_ACCOUNT_START });

export const CREATE_XERO_ACCOUNT_SUCCESS = 'CREATE_XERO_ACCOUNT_SUCCESS';
const createXeroAccountSuccess = (data) => ({
  type: CREATE_XERO_ACCOUNT_SUCCESS,
  data,
});

export const CREATE_XERO_ACCOUNT_FAILURE = 'CREATE_XERO_ACCOUNT_FAILURE';
const createXeroAccountFailure = (error) => ({
  type: CREATE_XERO_ACCOUNT_FAILURE,
  error,
});

export const createXeroAccount = payload => ((dispatch) => {
  // Fetch the user's xero accounts.
  const url = `${XERO_BASE_URL}properties/`;

  dispatch(createXeroAccountStart());

  return axios.post(url, payload)
    .then(
      response => dispatch(createXeroAccountSuccess(response.data)),
      error => dispatch(createXeroAccountFailure(error.response.data)),
    );
});
