import axios from 'axios';

import { deleteXeroCredentials } from 'actions/xero/credentials/delete';
import { XERO_BASE_URL } from '../index';

export const FETCH_XERO_CONTACTS_START = 'FETCH_XERO_CONTACTS_START';
const fetchXeroContactsStart = () => ({ type: FETCH_XERO_CONTACTS_START });

export const FETCH_XERO_CONTACTS_SUCCESS = 'FETCH_XERO_CONTACTS_SUCCESS';
const fetchXeroContactsSuccess = data => ({
  type: FETCH_XERO_CONTACTS_SUCCESS, data,
});

export const FETCH_XERO_CONTACTS_FAILURE = 'FETCH_XERO_CONTACTS_FAILURE';
const fetchXeroContactsFailure = error => ({
  type: FETCH_XERO_CONTACTS_FAILURE, error,
});

export const fetchXeroContacts = () => (dispatch) => {
  dispatch(fetchXeroContactsStart());
  const url = `${XERO_BASE_URL}contacts/`;
  return axios.get(url).then(
    response => dispatch(fetchXeroContactsSuccess(response.data)),
    error => {
      if (error.response.data.code === 'xero-unauthorized')
        return dispatch(deleteXeroCredentials());

      return dispatch(fetchXeroContactsFailure(error.response.data));
    },
  );
};
