import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';

import './style.scss';


class ExportCSVFileDrop extends React.Component {
  /* Component used for receiving a source file. */

  render() {
    const { loadedFile } = this.props;

    return (
      <Dropzone onDrop={this.props.onDrop}>
        {({ getRootProps, getInputProps }) => {
          const dropzoneClasses = () => {
            // add another class if there is a file loaded
            let classes = 'drop-zone p-4';

            if (loadedFile) classes += ' has-file';

            return classes;
          };

          const renderClearButton = () => {
            // show clear button if there is a file loaded.
            if (!loadedFile) return null;

            return <Button color="link" onClick={this.props.clearFile}>Remove</Button>;
          };

          return (
            <div {...getRootProps()} className={dropzoneClasses()}>
              <FontAwesomeIcon size="3x" icon={faFileCsv} className="mr-4" />
              <p>
                <span>
                  {loadedFile ? loadedFile.name
                    : 'Drop source csv file here, or click to browse.'}
                </span>
              </p>
              <input {...getInputProps()} />
              {renderClearButton()}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

ExportCSVFileDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  clearFile: PropTypes.func.isRequired,
  loadedFile: PropTypes.object,
};

export default ExportCSVFileDrop;
