import React from 'react';
import PropTypes from 'prop-types';

import { environment } from 'config';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    // report errors if in production
    if (environment === 'production') window.Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    // Always just return the normal component
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.PropTypes.node.isRequired,
};

export default ErrorBoundary;
