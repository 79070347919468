import React from 'react';
import { Container, Button } from 'reactstrap';
import { connect } from 'react-redux';

import { updateMe } from 'actions/users/update';
import { createXeroCredentials } from 'actions/xero/credentials/create';
import Loading from 'components/Loading';
import DotStatus from 'components/DotStatus';
import ConnectXeroButton from 'components/buttons/ConnectXero';
import DashboardContainer from 'components/dashboard/Container';
import './style.scss';

class CompleteRegistrationRoute extends React.Component {
  // Route for teh user to finish some steps after registrion (user type, xero auth, etc).
  state = {
    step: 1,
    loadingOauthUrl: false,
    oauthUrl: null,
  }

  componentDidMount = async () => {
    const action = await this.props.createXeroCredentials();
    this.setState({ oauthUrl:  action.data.oauthUrl });
  }

  maxSteps = () => {
    // Steps change depending on if user is trial or not.
    if (this.props.session.user.hasActiveTrial)
      return 2;
    return 3;
  }

  setUserType = async type => {
    await this.props.updateMe({ plan: type });
    this.setState({ step: 2 });
  }

  render() {
    const renderStepTitle = () => {
      const { step } = this.state;
      if (step === 1) return "Choose account type";
      if (step === 2) return "Connect Xero";
      if (step === 3) return "Enter Payment Info";
    };

    const renderConnectXero = () => {
      if (!this.state.oauthUrl)
        return <Loading className="p-5" />;

      return (
        <div className="text-center">
          <p className="m-4">Bnbsync requires an integration with your Xero account</p>
          <ConnectXeroButton oauthUrl={this.state.oauthUrl} />
        </div>
      );
    };

    const renderContent = () => {
      const { step } = this.state;
      if (step === 1)
        return (
          <DashboardContainer>
            <div className="account-type-container p-4">
              <section>
                <Button color="primary" size="lg" className="p-4"
                        onClick={() => this.setUserType('owner')}>
                  I am a property <strong>owner</strong>
                </Button>
              </section>
              <section>
                <Button color="primary" size="lg" className="p-4"
                        onClick={() => this.setUserType('manager')}>
                  I am a property <strong>manager</strong>
                </Button>
              </section>
            </div>
          </DashboardContainer>
        );
      if (step === 2)
        return (
          <DashboardContainer>
            {renderConnectXero()}
          </DashboardContainer>
        );
      if (step === 3)
        return (
          <DashboardContainer>
          </DashboardContainer>
        );
    };

    return (
      <div className="complete-registration-route">
        <DotStatus step={this.state.step} max={this.maxSteps()} className="pt-5" />
        <h2 className="text-center py-4">{renderStepTitle()}</h2>
        <Container className="extra-steps-container">
          {renderContent()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps, {
  updateMe, createXeroCredentials,
})(CompleteRegistrationRoute);
