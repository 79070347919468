import axios from 'axios';

import { XERO_BASE_URL } from 'actions/xero';

export const GENERATE_XERO_IMPORT_START = 'GENERATE_XERO_IMPORT_START';
const generateXeroImportStart = () => ({ type: GENERATE_XERO_IMPORT_START });

export const GENERATE_XERO_IMPORT_SUCCESS = 'GENERATE_XERO_IMPORT_SUCCESS';
const generateXeroImportSuccess = data => ({
  type: GENERATE_XERO_IMPORT_SUCCESS, data,
});

export const GENERATE_XERO_IMPORT_FAILURE = 'GENERATE_XERO_IMPORT_FAILURE';
const generateXeroImportFailure = error => ({
  type: GENERATE_XERO_IMPORT_FAILURE,
  error,
});

export const generateXeroImport = payload =>
  // Create a xero export generation instance for a given source file.

  (dispatch) => {
    dispatch(generateXeroImportStart());
    const { startDate, endDate, file } = payload;
    const url = `${XERO_BASE_URL}generations/generate/`;
    const data = new FormData();

    if (startDate) data.append('startDate', startDate);
    if (endDate) data.append('endDate', endDate);
    if (file) data.append('file', new Blob([file], { type: 'text/csv' }));

    return axios.post(url, data)
      .then(response => dispatch(generateXeroImportSuccess(response.data)),
        (error) => {
          let errorMessage = 'Could not process file, please verify the data is valid.';
          if (error.response && error.response.data.error)
            errorMessage = error.response.data.error;

          return dispatch(generateXeroImportFailure(errorMessage));
        });
  };


export const RESET_XERO_IMPORT = 'RESET_XERO_IMPORT';
export const resetXeroImport = () => ({ type: RESET_XERO_IMPORT });
