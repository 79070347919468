import React from 'react';
import { connect } from 'react-redux';
import {
  Form, FormFeedback, Button, FormGroup, Input, FormText,
} from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';

import {
  forgotPassword, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE,
} from 'actions/session/forgotPassword';

const ForgotPasswordForm = props => {
  const { status, errors, touched, handleSubmit, isSubmitting } = props;

  const renderStatusError = () => {
    if (!status || !status.error) return null;

    return (
      <FormText color="danger" className="text-center mb-3">
        {status.error}
      </FormText>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder="email"
          name="email"
          tag={Field}
          invalid={errors.email && touched.email}
        />
        <FormFeedback>{errors.email}</FormFeedback>
      </FormGroup>
      {renderStatusError()}
      <Button block color="primary" disabled={isSubmitting} type="submit">
        Reset Password
      </Button>
    </Form>
  );
};

const FormikForm = withFormik({
  mapPropsToValues: props => ({
    email: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email'),
  }),

  handleSubmit: async (values, { props, setStatus, setSubmitting, resetForm }) => {
    const action = await props.forgotPassword(values);

    setSubmitting(false);
    if (action.type === FORGOT_PASSWORD_FAILURE)
      setStatus({ error: 'Not able to send reset email.' });
    else if (action.type === FORGOT_PASSWORD_SUCCESS) {
      resetForm();
      toast('Email sent with instructions to reset password.', {
        autoClose: 3000,
      });
    }
  },
})(ForgotPasswordForm);

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps, { forgotPassword })(FormikForm);
