import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import AutocompleteBase from '../Base';
import './style.scss';

class SelectXeroAccount extends React.Component {
  /* Select control for the user to choose a xero account. This is used when
     when a user wants to map a source listing to a xero account. */
  state = {
    searchTerm: '',
  }

  shouldItemRender = (item, value) => item.name.toLowerCase().includes(value.toLowerCase())
           || item.accountCode.toString().includes(value.toLowerCase())

  render() {
    const { onSelect, items } = this.props;

    // add z index to default values.
    const menuStyle = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0',
      fontSize: '90%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%',
      zIndex: 1,
    };

    return (
      <AutocompleteBase className="select-xero-account">
        <Autocomplete
          inputProps={{
            className: 'form-control',
            placeholder: 'Search by account name or code',
            autoFocus: true,
          }}
          items={items}
          getItemValue={item => item.id.toString()}
          autoHighlight
          renderItem={(item, isHighlighted) => {
            let className = 'autocomplete-item';
            if (isHighlighted) className += ' highlighted';

            return (
              <div
                className={className}
                key={item.id}
              >
                {item.accountCode} - {item.name}
              </div>
            );
          }}
          shouldItemRender={this.shouldItemRender}
          onChange={e => this.setState({ searchTerm: e.target.value })}
          value={this.state.searchTerm}
          onSelect={value => onSelect(value)}
          menuStyle={menuStyle}
        />
      </AutocompleteBase>
    );
  }
}

SelectXeroAccount.propTypes = {
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default SelectXeroAccount;
