import axios from 'axios';

import { AUTH_BASE_URL } from '../index';


export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
const forgotPasswordStart = () => ({ type: FORGOT_PASSWORD_START });

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const forgotPasswordSuccess = data => ({ type: FORGOT_PASSWORD_SUCCESS });

export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
const forgotPasswordFailure = error => ({ type: FORGOT_PASSWORD_FAILURE, error });


export const forgotPassword = payload => (dispatch) => {
  dispatch(forgotPasswordStart(payload));
  const url = `${AUTH_BASE_URL}password/reset/`;

  return axios.post(url, payload)
    .then(
      success => dispatch(forgotPasswordSuccess(success.data)),
      error => dispatch(forgotPasswordFailure(error.response.data)),
    );
};
