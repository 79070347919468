import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import Loading from 'components/Loading';
import DashboardContainer from 'components/dashboard/Container';
import ExportHistoryTable from 'components/tables/ExportHistory';
import './style.scss';

class UsageRoute extends React.Component {
  state = { current: true }

  filteredExportData = () => {
    // Either show all the data or just for this month.
    if (!this.state.current)
      return this.props.exportData;

    return this.props.exportData.filter(data => {
      const startOfMonth = moment().startOf('month');
      return moment(data.createdAt) >= startOfMonth;
    });
  }

  componentDidMount() {
    if (this.props.user.paymentExempt)
      toast.success("You are payment exempt, charges will not happen.");
  }

  render() {
    if (this.props.isLoading)
      return <Loading />;

    if (this.props.exportData.length === 0)
      return (
        <Container>
          <DashboardContainer className="text-center">
            You don't have any Xero exports yet.
          </DashboardContainer>
        </Container>
      );

    const renderSummarySection = () => {
      const { metrics } = this.props.user;
      if (this.state.current)
        return (
          <DashboardContainer className="summary-section">
            <p>This month you have performed chargable Xero invoice exports for a total of <strong>{metrics.propertyCount}</strong> unique property accounts.</p>
            <p>You have a <strong>manager</strong> plan which puts your current monthly charge at <strong>AUD${metrics.amount}</strong></p>
          </DashboardContainer>
        );

      return (
        <DashboardContainer className="summary-section">
          <p className="mb-0">In total, you have exported <strong>{this.props.exportData.length}</strong> invoices or bills to Xero.</p>
        </DashboardContainer>
      );
    };

    return (
      <div className="usage-route">
        <section className="header text-center mb-5">
          <span className="mr-4">Showing <strong>{this.state.current ? 'current month' : 'full history' } </strong> data</span>
          <Button outline color="primary"
                  onClick={() => this.setState({ current: !this.state.current })}
          >
            Show {this.state.current ? 'full history' : 'current month' }
          </Button>
        </section>
        <section className="content">
          <Container>
            <Row>
              <Col>
                {renderSummarySection()}
              </Col>
              <Col className="data-section">
                <ExportHistoryTable data={this.filteredExportData()} />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ xero, session }) => ({
  isLoading: xero.isLoading,
  exportData: xero.exports,
  user: session.user,
});
export default connect(mapStateToProps)(UsageRoute);
