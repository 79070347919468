import React from 'react';

import ResetPasswordForm from 'components/forms/ResetPassword';

class ResetPasswordRoute extends React.Component {
  render() {
    const { token, uid } = this.props.match.params;
    return <ResetPasswordForm token={token} uid={uid} />;
  }
}

export default ResetPasswordRoute;
