import axios from 'axios';

import { XERO_BASE_URL } from '../xero';


export const deleteGenerationInvoices = generationId =>
  /* delete the user's invoices and transactions. This is mostly a cleanup task
     after the user is done with invoices/transactions because at this time
     we don't use them after exporting data.

     No reducer needs to receive this action, so there is no action.
  */
  (dispatch) => {
    const url = `${XERO_BASE_URL}generations/${generationId}/invoices/`;
    return axios.delete(url);
  };
