import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { processXeroTrialBalance, PROCESS_XERO_TRIAL_BALANCE_SUCCESS,
       } from 'actions/xero/trial/process';
import DashboardContainer from 'components/dashboard/Container';
import Loading from 'components/Loading';
import LoadButton from 'components/buttons/LoadButton';
import TrialAccountBalance from 'components/payments/TrialAccountBalance';
import './style.scss';

class PaymentsRoute extends React.Component {
  state = {
    // Choose which accounts are selected based on the accountId.
    selected: [],
    invoicesCreated: [],
    isCreatingInvoices: false,
    errorMessage: '',
  }

  toggleSelected = (account) => {
    const { selected } = this.state;
    if (selected.includes(account)) {
      const newSelected = selected.filter(selectedAccount => selectedAccount.accountId !== account.accountId);
      this.setState({ selected: newSelected });
    } else {
      this.setState({ selected: [...selected, account] });
    }
  }

  createInvoices = async () => {
    this.setState({ isCreatingInvoices: true });
    const invoicesPayload = this.state.selected.map((trialBalanceAccount) => {
      const bnbsyncAccount = this.props.properties.data.find(account => {
        return account.xeroAccountId === trialBalanceAccount.accountId;
      });

      const xeroContact = this.props.xero.contacts.find(contact => {
        return contact.ContactID === bnbsyncAccount.xeroContactId;
      });

      return {
        account: bnbsyncAccount.id,
        amount: trialBalanceAccount.credit,
        contactName: xeroContact.Name,
      };
    });

    const action = await this.props.processXeroTrialBalance(invoicesPayload);
    this.setState({ isCreatingInvoices: false });

    if (action.type === PROCESS_XERO_TRIAL_BALANCE_SUCCESS) {
      this.setState({ selected: [], invoicesCreated: action.data });
    } else {
      this.setState({ errorMessage: action.error || "Error creating owner payments." });
    }
  }

  reset = () => {
    // After the user is done creating invoices reset the component state.
    this.setState({
      selected: [],
      invoicesCreated: [],
    });
  }

  render() {
    if (this.props.xero.isLoading && !this.props.xero.contacts.length)
      // Initial loading screen.
      return <Loading />;
    
    const createdLength = this.state.invoicesCreated.length;
    if (createdLength) {
      // Show a success screen after the invoices have been created.
      return (
        <Container>
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <DashboardContainer className="text-center">
                <h4 className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} /> Success!
                </h4>
                <p className="text-muted mt-3">
                  {createdLength} Xero {createdLength > 1 ? "invoices have " : "invoice has "}
                  been created. Check your <a href="https://go.xero.com/AccountsPayable/Search.aspx?invoiceStatus=INVOICESTATUS%2fDRAFT" rel="noopener noreferrer" target="_blank">Draft bills</a>{' '}
                  for details and approval of the payments.
                </p>
                <div className="text-center">
                  <Button color="link" onClick={this.reset}>OK</Button>
                </div>
              </DashboardContainer>
            </Col>
          </Row>
        </Container>
      );
    }

    const renderTrialAccounts = () => {
      if (!this.props.xero.trialBalance.length) return null;

      return this.props.xero.trialBalance.map((xeroAccount) => {
        // Attach account contact to the trial account balance.
        const bnbsyncAccount = this.props.properties.data.find(property => {
          return property.xeroAccountId === xeroAccount.accountId;
        });
      let currency = 'AUD'
      const currency_id = this.props.session.user.settings.currency
      currency = this.props.currencies.data.find(currency => currency.id === currency_id).code
        let contact = null;
        if (bnbsyncAccount && bnbsyncAccount.xeroContactId) {
          contact = this.props.xero.contacts.find(contact => {
            return contact.ContactID === bnbsyncAccount.xeroContactId;
          });
        }

        return (
          <TrialAccountBalance
            currency={currency}
            contact={contact}
            key={xeroAccount.accountId}
            bnbsyncAccount={bnbsyncAccount}
            xeroAccount={xeroAccount}
            toggleSelected={xeroAccount => this.toggleSelected(xeroAccount)}
            isSelected={this.state.selected.includes(xeroAccount)}
          />
        );
      });
    };

    const renderHeading = () => {
      // Message to show to user depending on if they have valid trial balances.
      if (this.props.xero.trialBalance.length > 0)
        return "Click on whichever accounts you want to create a bill for. You'll have a chance to review in Xero before paying.";

      return "You don't have any current liabilities with an outstanding balance.";
    };

    const renderErrorMessage = () => {
      // If there is an error message, show it.
      const { errorMessage } = this.state;
      return errorMessage ?
        <p className="text-danger text-center my-4">{errorMessage}</p> :
        null;
    };

    return (
      <div className="payments-route">
        <Container>
          <h4 className="text-center">Trial Balance</h4>
          <p className="text-muted text-center mb-4">
            {renderHeading()}
          </p>
          <div className="trial-balance-container">
            {renderTrialAccounts()}
          </div>
          {renderErrorMessage()}
          <div className="text-right mt-4">
            <LoadButton
              size="lg"
              width={220}
              onClick={this.createInvoices}
              isLoading={this.state.isCreatingInvoices}
            >
              Create Bills
              <FontAwesomeIcon icon={faChevronRight} className="ml-3" />
            </LoadButton>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ session, properties, xero, currencies }) => ({
  session, properties, xero,currencies
});

export default connect(mapStateToProps, { processXeroTrialBalance })(PaymentsRoute);
