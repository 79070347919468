import axios from 'axios';
import { API_URL } from 'config';


export const UPDATE_USER_SETTINGS_START = 'UPDATE_USER_SETTINGS_START';
const updateUserSettingsStart = () => ({ type: UPDATE_USER_SETTINGS_START });

export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
const updateUserSettingsSuccess = data => ({
  type: UPDATE_USER_SETTINGS_SUCCESS,
  data,
});

export const updateUserSettings = payload => (dispatch) => {
  dispatch(updateUserSettingsStart());
  const url = `${API_URL}users/settings/`;

  return axios.patch(url, payload)
    .then(success => dispatch(updateUserSettingsSuccess(success.data)));
};
