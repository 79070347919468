import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import XeroCredentialsForm from 'components/forms/xero/Credentials';
import './style.scss';


class XeroCredentialsModal extends React.Component {
  /* Allow the user to authenticate with Xero.

  Modal consists of 2 parts - Entering the consumer key / private key and then
  entering the verification code provided by xero.

  If the user hasn't entered their consumer key / private key pair we'll first
  show a form to enter those details. After we have that data we're able to
  make the first request and the user will need to enter the verification code
  provided by xero.

  After we receive back the updated credentials we'll save that to the user
  and then will have 30 minutes of xero authentication.
  */
  render() {
    return (
      <Modal {...this.props}>
        <ModalHeader>
          Enter Xero application information
        </ModalHeader>
        <ModalBody>
          <p className="text-muted">
            Before using bnbsync, you'll need to setup a <a href="https://developer.xero.com/documentation/auth-and-limits/public-applications" rel="noopener noreferrer" target="_blank">Xero Public Application</a> to connect to your Xero account.
          </p>
          <p className="text-muted mb-4">
            Go through the steps in <span style={{ color: '#333' }}>"How to register an application"</span>.
            After you've created your application, we just need the consumer key and consumer secret to get started.
          </p>
          <XeroCredentialsForm />
        </ModalBody>
      </Modal>
    );
  }
}

export default XeroCredentialsModal;
