import axios from 'axios';
import { API_URL } from 'config';


export const FETCH_PROPERTIES_START = 'FETCH_PROPERTIES_START';
const fetchPropertiesStart = () => ({ type: FETCH_PROPERTIES_START });

export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
const fetchPropertiesSuccess = data => ({ type: FETCH_PROPERTIES_SUCCESS, data });

export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE';
const fetchPropertiesFailure = () => ({ type: FETCH_PROPERTIES_FAILURE });

export const fetchProperties = () => (dispatch) => {
  dispatch(fetchPropertiesStart());
  const url = `${API_URL}properties/`;

  return axios.get(url)
    .then(
      success => dispatch(fetchPropertiesSuccess(success.data)),
      error => dispatch(fetchPropertiesFailure()),
    );
};
