import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import './style.scss';

class HelpBreadcrumbs extends React.Component {
  // Show breadcrumbs allowing better navigation for the user.

  render() {
    const { article, topic, help } = this.props;

    const dashboardPrefix = this.props.location.pathname.includes('dashboard')
      ? '/dashboard' : '';
    const baseUrl = `${dashboardPrefix}/help`;

    if (article) {
      const articleTopic = help.topics.find(_topic => _topic.id === article.topic);

      return (
        <Breadcrumb className="help-breadcrumb">
          <BreadcrumbItem>
            <Link to={baseUrl}>Help</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${baseUrl}/topics/${articleTopic.slug}`}>
              {articleTopic.title}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{article.title}</BreadcrumbItem>
        </Breadcrumb>
      );
    }
    return (
      <Breadcrumb className="help-breadcrumb">
        <BreadcrumbItem>
          <Link to={baseUrl}>Help</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>{topic.title}</BreadcrumbItem>
      </Breadcrumb>
    );
  }
}

HelpBreadcrumbs.propTypes = {
  article: PropTypes.object,
  topic: PropTypes.object,
};

const mapStateToProps = ({ help }) => ({ help });
const ConnectedComponent = connect(mapStateToProps)(HelpBreadcrumbs);
export default withRouter(ConnectedComponent);
