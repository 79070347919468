export const SEARCH_HELP_ARTICLES = 'SEARCH_HELP_ARTICLES';
const searchHelpArticles = text => ({ type: SEARCH_HELP_ARTICLES, text });

export const CLEAR_ARTICLE_SEARCH = 'CLEAR_ARTICLE_SEARCH';
const clearArticleSearch = () => ({ type: CLEAR_ARTICLE_SEARCH });


export const searchHelp = text =>
  // Take a string and return all the help articles that have the string
  dispatch => dispatch(searchHelpArticles(text));


export const clearHelpSearch = () => dispatch => dispatch(clearArticleSearch());
