import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import DashboardContainer from 'components/dashboard/Container';
import Logo from 'components/Logo';

const NotFoundRoute = () => (
  <div>
    <Container>
      <Row>
        <Col className="text-center" sm={{ size: 6, offset: 3 }}>
          <Logo style={{ width: 200 }} color="black" />
          <DashboardContainer>
            <h4>Page Not Found</h4>
            <div className="link-container mt-4">
              <Link to="/dashboard/accounts" className="mx-4">Dashboard</Link>
              <Link to="/" className="mx-4">Home</Link>
            </div>
          </DashboardContainer>
        </Col>
      </Row>
    </Container>
  </div>
);

export default NotFoundRoute;
