import axios from 'axios';
import { API_URL } from 'config';


export const IMPORT_PROPERTIES_START = 'IMPORT_PROPERTIES_START';
const importPropertiesStart = () => ({ type: IMPORT_PROPERTIES_START });

export const IMPORT_PROPERTIES_SUCCESS = 'IMPORT_PROPERTIES_SUCCESS';
const importPropertiesSuccess = data => ({
  type: IMPORT_PROPERTIES_SUCCESS, data,
});

export const IMPORT_PROPERTIES_FAILURE = 'IMPORT_PROPERTIES_FAILURE';
const importPropertiesFailure = data => ({
  type: IMPORT_PROPERTIES_FAILURE, data,
});


export const importProperties = payload =>
  /* receive an arrayof property data from Xero that should be imported to the
     user's bnbsync properties.
  */
  (dispatch) => {
    dispatch(importPropertiesStart());
    const url = `${API_URL}properties/import/`;

    return axios.post(url, payload)
      .then(success => dispatch(importPropertiesSuccess(success.data)),
        error => dispatch(importPropertiesFailure(error.response.data)));
  };
