import axios from 'axios';
import { API_URL } from 'config';


export const UPDATE_PROPERTY_START = 'UPDATE_PROPERTY_START';
const updatePropertyStart = () => ({ type: UPDATE_PROPERTY_START });

export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
const updatePropertySuccess = data => ({ type: UPDATE_PROPERTY_SUCCESS, data });

export const UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE';
const updatePropertyFailure = error => ({ type: UPDATE_PROPERTY_FAILURE, error });

export const updateProperty = (id, payload, options) =>
  /* Update a property account on bnbsync.

     options: patch <bool> - determines if we use patch or put
  */
  (dispatch) => {
    dispatch(updatePropertyStart());
    const url = `${API_URL}properties/${id}/`;
    const axiosMethod = options && options.patch ? axios.patch : axios.put;

    return axiosMethod(url, payload)
      .then(success => dispatch(updatePropertySuccess(success.data)),
        error => dispatch(updatePropertyFailure(error.response.data.detail)));
  };
