import React from 'react';

import FeaturesList from '../FeaturesList';

export const OwnerFeaturesList = () => (
  <FeaturesList
    features={[
      'Up to 5 properties',
      'Import Data from Airbnb',
      'Reconcile Transactions',
    ]}
  />
);
