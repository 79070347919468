import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import LandingRoute from '../Landing';
import DashboardRoute from '../dashboard/Base';
import AuthRoute from '../auth/Base';
import AuthRequiredRoute from '../auth/AuthRequired';
import TermsOfServiceRoute from '../misc/TermsOfService';
import NotFound from '../NotFound';
import PaymentSource from '../PaymentSource';
import HelpRoute from '../misc/Help';
import XeroCallbackRoute from '../misc/XeroCallback';
import CompleteRegistrationRoute from '../misc/CompleteRegistration';

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={LandingRoute} />
        <Route exact path="/terms-of-service" component={TermsOfServiceRoute} />
        <Route path="/auth" component={AuthRoute} />
        <Route exact path="/help" component={HelpRoute} />
        <Route path="/help/topics/:topicSlug" component={HelpRoute} />
        <Route path="/help/articles/:articleSlug" component={HelpRoute} />
        <Route path="/xero/callback" component={XeroCallbackRoute} />
        <Route path="/complete-registration" component={CompleteRegistrationRoute} />
        <AuthRequiredRoute
          isAuthenticated={this.props.isAuthenticated}
          path="/dashboard"
          component={DashboardRoute}
        />
        <AuthRequiredRoute
          isAuthenticated={this.props.isAuthenticated}
          path="/payments"
          component={PaymentSource}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

Router.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Router;
