import axios from 'axios';

import { XERO_BASE_URL } from '../index';


export const DELETE_XERO_CREDENTIALS_START = 'DELETE_XERO_CREDENTIALS_START';
const deleteXeroCredentialsStart = () => (
  { type: DELETE_XERO_CREDENTIALS_START }
);

export const DELETE_XERO_CREDENTIALS_SUCCESS = 'DELETE_XERO_CREDENTIALS_SUCCESS';
const deleteXeroCredentialsSuccess = data => (
  { type: DELETE_XERO_CREDENTIALS_SUCCESS, data }
);

export const DELETE_XERO_CREDENTIALS_FAILURE = 'DELETE_XERO_CREDENTIALS_FAILURE';
const deleteXeroCredentialsFailure = () => (
  { type: DELETE_XERO_CREDENTIALS_FAILURE }
);

export const deleteXeroCredentials = () => dispatch => {
  dispatch(deleteXeroCredentialsStart());
  const url = `${XERO_BASE_URL}credentials/`;
  return axios.delete(url)
    .then(
      response => dispatch(deleteXeroCredentialsSuccess(response.data)),
      error => dispatch(deleteXeroCredentialsFailure(error.response.data)),
    );
};
