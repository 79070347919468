import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Logo from 'components/Logo';
import './style.scss';

class LandingNav extends React.Component {
  state = { isOpen: false }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  scrollToRef = ref => window.scrollTo({ left: 0, top: ref.offsetTop, behavior: 'smooth' })

  render() {
    const { featuresRef, pricingRef, contactRef } = this.props.sectionRefs;
    return (
      <Navbar className="landing-navbar" expand="md" fixed="top">
        <NavbarBrand>
          <Logo icon={1} color="black" style={{ height: 80 }} className="mr-4" />
          bnbsync
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle}>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink onClick={() => window.open('/help', '_blank')}>Help</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.scrollToRef(featuresRef.current)}>Features</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.scrollToRef(pricingRef.current)}>Pricing</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => this.scrollToRef(contactRef.current)}>Contact</NavLink>
            </NavItem>
            <NavItem className="mt-1">
              <Button
                className="px-3"
                color="primary"
                outline
                onClick={() => this.props.history.push('/auth/login')}
              >
                Login
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

LandingNav.propTypes = {
  sectionRefs: PropTypes.object.isRequired,
};

export default withRouter(LandingNav);
