import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import DashboardContainer from 'components/dashboard/Container';
import ArticleList from '../ArticleList';
import ArticleFeedback from '../ArticleFeedback';
import HelpHeader from '../HelpHeader';
import './style.scss';

class HelpArticle extends React.Component {
  // Show the contents of a help article.
  render() {
    const { content, title } = this.props.article;
    const helpReducer = this.props.help;

    const renderContent = () => {
      // Either render the article or the search results.
      if (!helpReducer.searchTerm) {
        return (
          <div>
            <DashboardContainer>
              <h2 className="article-title text-center mb-4">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </DashboardContainer>
            <DashboardContainer className="mt-4">
              <ArticleFeedback article={this.props.article} />
            </DashboardContainer>
          </div>
        );
      }
      return (
        <DashboardContainer>
          <ArticleList articles={helpReducer.filteredArticles} />
        </DashboardContainer>
      );
    };

    return (
      <Container className="article-container">
        <HelpHeader article={this.props.article} />
        {renderContent()}
      </Container>
    );
  }
}

HelpArticle.propTypes = {
  article: PropTypes.object.isRequired,
};

const mapStateToProps = ({ help }) => ({ help });
export default connect(mapStateToProps)(HelpArticle);
