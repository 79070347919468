import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

class LoadButton extends React.Component {
  // Render a button that turns into a loading spinner when async request is loading.

  render() {
    const { width, isLoading, spinnerColor, spinnerDimension, ...rest } = this.props;

    const defaultSpinnerSize = this.props.size === "lg" ? 28 : 22;
    const spinnerStyle = {
      width: spinnerDimension || defaultSpinnerSize,
      height: spinnerDimension || defaultSpinnerSize,
      fontSize: "0.5rem",
    };

    const getButtonStyle = () => {
      if (!this.props.block) {
        return { width: width || 150 };
      }
    };

    return (
      <Button
        color={this.props.color || "primary"}
        style={getButtonStyle()}
        disabled={this.props.disabled || isLoading}
        { ...rest }
      >
        {isLoading ?
          <Loading color={spinnerColor || "light"} style={spinnerStyle}/> :
          this.props.children
        }
      </Button>
    );
  }
}

LoadButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.number,
  spinnerColor: PropTypes.string,
};

export default LoadButton;
