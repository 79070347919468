import React from 'react';
import { Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import './style.scss';

class ExportSteps extends React.Component {
  render() {
    const { step } = this.props;

    const progressPercent = () => {
      switch (step) {
        case 1:
          return 10;
        case 2:
          return 35;
        case 3:
          return 65;
        case 4:
          return 90;
        case 5:
          return 100;
        default:
          return 0;
      }
    };

    return (
      <div className="export-steps-container mx-5 mb-5">
        <div className="export-steps">
          <div className={step === 1 ? 'active' : ''}>Import</div>
          <FontAwesomeIcon icon={faChevronRight} />
          <div className={step === 2 ? 'active' : ''}>Map Listings</div>
          <FontAwesomeIcon icon={faChevronRight} />
          <div className={step === 3 ? 'active' : ''}>Select Invoices</div>
          <FontAwesomeIcon icon={faChevronRight} />
          <div className={step === 4 ? 'active' : ''}>Export</div>
        </div>
        <Progress value={progressPercent()} striped />
      </div>
    );
  }
}

ExportSteps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ExportSteps;
