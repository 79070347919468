import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

class HelpTooltip extends React.Component {
  state = { isOpen: false }

  toggleOpen = () => this.setState({
    isOpen: !this.state.isOpen,
  })

  render() {
    const { id, tooltipText, ...rest } = this.props;
    const icon = rest.icon || faQuestionCircle;

    return (
      <span>
        <FontAwesomeIcon
          className="mx-1"
          icon={icon}
          id={id}
          { ...rest }
        />
        <Tooltip
          isOpen={this.state.isOpen}
          target={id}
          toggle={this.toggleOpen}
        > {tooltipText}
        </Tooltip>
      </span>
    );
  }
}

HelpTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default HelpTooltip;
