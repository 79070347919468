import React from 'react';
import {
  Modal, ModalBody, ModalHeader, Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import { importProperties, IMPORT_PROPERTIES_SUCCESS, IMPORT_PROPERTIES_FAILURE } from 'actions/xero/accounts/import';
import { fetchXeroAccounts } from 'actions/xero/accounts/fetch';
import ModalCancelButton from '../CancelButton';
import './style.scss';


class ImportPropertiesModal extends React.Component {
  // modal which shows the properties that will be imported from Xero

  componentDidMount() {
    this.props.fetchXeroAccounts();
  }

  filteredProperties = () =>
    // return an array of properties that would be imported.
    this.props.xero.properties.filter((xeroAccount) => {
      const found = this.props.properties.data.find(property => {
        return property.xeroAccountId === xeroAccount.accountId;
      });
      return found === undefined;
    })


  importProperties = () => {
    const { properties } = this.props.xero;
    const cleanedProperties = properties.map(property =>
      // return relevant property data from xero that will be used in bnbsync
      ({
        name: property.name,
        accountCode: property.accountCode,
        accountId: property.accountId,
      }));

    const previousCount = this.props.properties.data.length;

    const payload = { properties: cleanedProperties };
    this.props.importProperties(payload).then((action) => {
      if (action.type === IMPORT_PROPERTIES_SUCCESS) {
        this.props.toggle();
        const currentCount = this.props.properties.data.length;
        toast(`${currentCount - previousCount} properties have been imported`);
      } else if (action.type === IMPORT_PROPERTIES_FAILURE) {
        this.props.toggle();
        toast('An error occured during account import, please contact an administrator.');
      }
    });
  }

  render() {
    const {
      importProperties, history, staticContext,
      helpText, fetchXeroAccounts, ...rest
    } = this.props;

    if (this.props.xero.isLoading) {
      return (
        <Modal {...rest} className="import-properties-modal">
          <div className="loading-container">
            <Loading />
          </div>
        </Modal>
      );
    }

    if (!this.filteredProperties().length) {
      return (
        <Modal {...rest}>
          <ModalBody>
            <p className="text-muted">
              There are no property accounts from Xero that are not already imported.
            </p>
            <div className="text-center">
              <Button color="link" className="mr-3" onClick={this.props.toggle}>
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      );
    }

    const renderProperties = () => {
      // render property accounts that have not already been imported.
      return this.filteredProperties().map(property => (
        <p key={property.accountCode}>
          {property.accountCode} - {property.name}
        </p>
      ));
    };

    return (
      <Modal {...rest}>
        <ModalHeader>
          Import accounts
        </ModalHeader>
        <ModalBody>
          <p className="text-muted help-text pb-3">
            { this.props.helpText ||
              'The following accounts from Xero will be imported into bnbsync.'
            }
          </p>
          {renderProperties()}
          <div className="text-right">
            <ModalCancelButton />
            <Button color="primary" onClick={this.importProperties}>
              Import Accounts <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ImportPropertiesModal.propTypes = {
  helpText: PropTypes.string,
};

const mapStateToProps = ({ session, xero, properties }) => (
  { session, xero, properties }
);
const ConnectedComponent = connect(mapStateToProps, {
  importProperties, fetchXeroAccounts,
})(ImportPropertiesModal);
export default withRouter(ConnectedComponent);
