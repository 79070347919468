import axios from 'axios';

import { API_URL } from 'config';

export const CREATE_EMAIL_START = 'CREATE_EMAIL_START';
const createEmailStart = () => ({ type: CREATE_EMAIL_START });

export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
const createEmailSuccess = data => ({
  type: CREATE_EMAIL_SUCCESS, data,
});

export const CREATE_EMAIL_FAILURE = 'CREATE_EMAIL_FAILURE';
const createEmailFailure = () => ({
  type: CREATE_EMAIL_FAILURE,
});


export const sendEmail = payload =>
  // Send an email. Required payload is subject, body and toEmails (array).
  (dispatch) => {
    dispatch(createEmailStart());
    const url = `${API_URL}emails/`;
    return axios.post(url, payload).then(response => dispatch(createEmailSuccess(response.data)), error => dispatch(createEmailFailure()));
  };
