import { faUserPlus, faSync, faCogs } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export const getTopicIcon = (topic) => {
  // Map the icon that should be shown for each help topic.
  switch (topic.title) {
    case 'Onboarding':
      return faUserPlus;
    case 'FAQs':
      return faQuestionCircle;
    case 'Optimisation':
      return faCogs;
    case 'Integration':
      return faSync;
    default:
      return faQuestionCircle;
  }
};
