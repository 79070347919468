import React from 'react';
import { connect } from 'react-redux';
import {
  Form, Label, Button, FormGroup, Input, FormFeedback, FormText,
  Container, Row, Col,
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import LoadButton from 'components/buttons/LoadButton';
import HelpTooltip from 'components/HelpTooltip';
import ModalCancelButton from 'components/modals/CancelButton';
import SelectXeroContact from 'components/autocomplete/XeroContact';
import { updateProperty, UPDATE_PROPERTY_SUCCESS, UPDATE_PROPERTY_FAILURE } from 'actions/properties/update';
import { deleteProperty } from 'actions/properties/delete';
import { hideModal } from 'actions/modals';

class PropertyForm extends React.Component {
  /* For contact Id we select it from the autocomplete and need to manually
     set the formik value. */
  state = {
    xeroContactId: this.props.property ? this.props.property.xeroContactId : '',
    allowNoCommission: this.props.allowNoCommission,
    wasInitialValidated: false,
  }

  getInitialValues = () => {
    const { name, commission, account, accountCode, xeroContactId } = this.props.property;
    let { cleaningFee, allowNoCommission, ignoreCleaning } = this.props.property;

    // The form doesn't like null values being passed in so we need to change it to empty string.
    if (!cleaningFee) cleaningFee = "";

    if (this.props.session.user.plan.type === "owner") {
      allowNoCommission = true;
      ignoreCleaning = true;
    }

    return {
      name,
      commission,
      account,
      accountCode,
      xeroContactId,
      allowNoCommission,
      ignoreCleaning,
      cleaningFee,
    };
  }

  ValidationSchema = (
    Yup.object().shape({
      name: Yup.string().required('Required'),
      commission: Yup.number()
        .required('Required')
        .min(0)
        .max(1, 'Enter percent as a decimal such as 0.18'),
      cleaningFee: Yup.number().typeError('Enter valid monetary value'),
      accountCode: Yup.string().required('Required'),
    })
  )

  onSubmit = (values, actions) => {
    const { property } = this.props;
    const { xeroContactId } = this.state;

    values.cleaningFee = values.cleaningFee.length ?
      Math.round(values.cleaningFee * 100) / 100 :
      null;

    // Add the selected xero contact to payload.
    const payload = { ...values, xeroContactId };
    const setError = (actions) => {
      const { error } = this.props.properties;
      actions.setStatus({ error });
    };

    this.props.updateProperty(property.id, payload).then((action) => {
      actions.setSubmitting(false);
      if (action.type === UPDATE_PROPERTY_SUCCESS) {
        this.props.hideModal();
        toast(`${values.name} has been updated`);
      } else if (action.type === UPDATE_PROPERTY_FAILURE) setError(actions);
    });
  }

  deleteProperty = () => {
    const { name, id } = this.props.property;
    toast(`${name} has been deleted.`);
    this.props.deleteProperty(id);
    this.props.hideModal();
  }

  hasXeroSession = () => {
    const xeroState = this.props.session.user.xero.state;
    return xeroState && xeroState.token;
  }

  validateForm = values => {
    const errors = {};
    if (!values.allowNoCommission && parseFloat(values.commission) < 0.01) {
      errors.commission = 'commission must be greater than 0';
    }

    return errors;
  }

  render() {
    const renderFormFields = (errors, touched, values) => {
      // Render different form fields based on if the user is a manager/enterprise or owner.
    // const contacts = this.props.xero.contacts.filter(contact => contact.IsSupplier == true);
      if (this.props.session.user.plan.type === 'owner')
        // Limit the form fields for a property owner.
        return (
          <Row form>
            <Col>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="name"
                  tag={Field}
                  invalid={errors.name && touched.name}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Account Code</Label>
                <Input
                  name="accountCode"
                  tag={Field}
                  invalid={errors.accountCode && touched.accountCode}
                />
                <FormFeedback>{errors.accountCode}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        );

      // Return form for manager/enterprise.
      return (
        <div>
          <Row form>
            <Col>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="name"
                  tag={Field}
                  invalid={errors.name && touched.name}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col>
              <FormGroup className="mb-0">
                <Label>Commission Percent</Label>
                <Input
                  name="commission"
                  tag={Field}
                  invalid={errors.commission && true}
                />
                <FormFeedback>{errors.commission}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Account Code</Label>
                <Input
                  name="accountCode"
                  tag={Field}
                  invalid={errors.accountCode && touched.accountCode}
                />
                <FormFeedback>{errors.accountCode}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Cleaning Fee <HelpTooltip
                  id="cleaning-fee"
                  tooltipText="Cleaning fee to be exported to Xero if value not received from source."
                /></Label>
                <Input
                  name="cleaningFee"
                  tag={Field}
                  invalid={errors.cleaningFee && touched.cleaningFee}
                />
                <FormFeedback>{errors.cleaningFee}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col xs={6}>
              <FormGroup check className="mb-3">
                <Label check>
                  <Input
                    type="checkbox"
                    tag={Field}
                    name="allowNoCommission"
                    checked={values.allowNoCommission}
                  /> Allow no commission
                </Label>
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup check inline className="mb-3">
                <Label check>
                  <Input
                    type="checkbox"
                    tag={Field}
                    name="ignoreCleaning"
                    checked={values.ignoreCleaning}
                  /> Ignore Cleaning{' '}
                  <HelpTooltip
                    id="ignore-cleaning"
                    tooltipText="Don't create cleaning invoices for this property."
                  />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>Xero Contact</Label>

            <SelectXeroContact
              initialValue={this.props.property.xeroContactId}
              items={this.props.xero.contacts}
              onSelect={value => this.setState({ xeroContactId: value })}
              disabled={!this.hasXeroSession()}
            />
            <FormFeedback>{errors.xeroContactId}</FormFeedback>
          </FormGroup>
        </div>
      );
    };

    const renderDeleteProperty = () => {
      if (!this.props.property) return null;

      return (
        <div>
          <Button
            type="button"
            color="danger"
            onClick={this.deleteProperty}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      );
    };

    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.ValidationSchema}
        onSubmit={this.onSubmit}
        validate={this.validateForm}
      >

        {({
          status, errors, touched, handleSubmit, isSubmitting, validateForm, values,
        }) => {
      	  const renderStatusError = () => {
      	    if (!status || !status.error) return null;

      	    return (
              <FormText color="danger" className="text-center mb-3">
                {status.error}
              </FormText>
      	    );
      	  };

      	  if (!this.state.wasInitialValidated) {
      	    validateForm(values);
      	    this.setState({ wasInitialValidated: true });
      	  }

      	  return (
            <Form onSubmit={handleSubmit}>
              <Container fluid>
                {renderFormFields(errors, touched, values)}
                {renderStatusError()}

                <div className="button-group">
                  {renderDeleteProperty()}
                  <div className="float-right">
                    <ModalCancelButton />
                    <LoadButton
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      { this.props.property ? 'Save Account' : 'Add Account' }
                    </LoadButton>
                  </div>
                </div>
              </Container>
            </Form>
      	  );
      	}}
      </Formik>
    );
  }
}

PropertyForm.propTypes = {
  property: PropTypes.object.isRequired,
  initialStatus: PropTypes.string,
};

const mapStateToProps = ({ session, properties, xero }) => ({ session, properties, xero });
export default connect(mapStateToProps, {
  deleteProperty, updateProperty, hideModal,
})(PropertyForm);
