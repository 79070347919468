import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class TermSection extends React.Component {
  render() {
    const { order, title, children } = this.props;
    return (
      <div className="term-section">
        <p className="term-section-title">
          {order}. {title}
        </p>
        <p className="term-section-content">
          {children}
        </p>
      </div>
    );
  }
}

TermSection.propTypes = {
  order: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default TermSection;
