import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import HelpSearch from '../Search';
import HelpBreadcrumbs from '../Breadcrumbs';
import './style.scss';

class HelpHeader extends React.Component {
  // Provide a header for topics and articles assisting navigation & search.
  render() {
    return (
      <Row className="help-header mb-3">
        <Col>
          <HelpBreadcrumbs {...this.props} />
        </Col>
        <Col>
          <HelpSearch />
        </Col>
      </Row>
    );
  }
}

HelpHeader.propTypes = {
  article: PropTypes.object,
  topic: PropTypes.object,
};

export default HelpHeader;
