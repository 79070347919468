import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/misc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';

import { showModal } from 'actions/modals';
import './style.scss';

class TransactionRow extends React.Component {
  // Represents an individual transaction on an invoice that is to be exported.

  openTransactionEdit = (e) => {
    // Open modal allowing the user to select which action they want to take.
    e.stopPropagation();
    const { transaction, errors } = this.props;
    this.props.showModal('editTransaction', { transaction, errors });
  }

  editListingProperty = (e) => {
    e.stopPropagation();

    const { transaction, errors } = this.props;
    if (!transaction.listing.length) return;

    this.props.showModal('editTransaction', { transaction, errors });
  }

  render() {
    const { transaction } = this.props;
    let propertyName = '';

    if (transaction.property && transaction.property.name) propertyName = transaction.property.name;

    const listingString = transaction.listing.length > 25
      ? `${transaction.listing.substring(0, 25)}...`
      : transaction.listing;

    let className = 'transaction-row';

    if (Object.keys(this.props.errors).length !== 0) className += ' error';

    // Some transactions need to be displayed differently.
    const detailsDisplayedTypes = ['res_payout', 'res_adjustment', 'adjustment'];

    if (detailsDisplayedTypes.includes(transaction.type)) {
      return (
        <Row
          className={className}
          key={transaction.id}
          onClick={this.openTransactionEdit}
        >
          <Col sm={9}>{transaction.details}</Col>
          <Col sm={2}>{formatCurrency(transaction.unitAmount)}</Col>
          <Col sm={1}>
            <FontAwesomeIcon className="edit-icon" icon={faEdit} />
          </Col>
        </Row>
      );
    }

    return (
      <Row
        className={className}
        key={transaction.id}
        onClick={this.openTransactionEdit}
      >
        <Col sm={2}>{transaction.confirmationNumber}</Col>
        <Col
          sm={3}
          className="col-property-name"
          onClick={this.editListingProperty}
        >
          {propertyName}
        </Col>
        <Col sm={3}>{listingString}</Col>
        <Col sm={2}>{formatCurrency(transaction.unitAmount)}</Col>
        <Col sm={1}>
          <FontAwesomeIcon className="edit-icon" icon={faEdit} />
        </Col>
      </Row>
    );
  }
}

TransactionRow.propTypes = {
  transaction: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default connect(null, { showModal })(TransactionRow);
