import axios from 'axios';

import { XERO_BASE_URL } from 'actions/xero';

export const TOGGLE_INVOICES_EXPORT_START = 'TOGGLE_INVOICES_EXPORT_START';
const toggleInvoicesExportStart = () => (
  { type: TOGGLE_INVOICES_EXPORT_START }
);

export const TOGGLE_INVOICES_EXPORT_SUCCESS = 'TOGGLE_INVOICES_EXPORT_SUCCESS';
const toggleInvoicesExportSuccess = data => ({ type: TOGGLE_INVOICES_EXPORT_SUCCESS, data });

export const toggleInvoicesExport = (generationId, payload) => ((dispatch) => {
  // Toggle all the invoices on the generation if they should be exported.
  dispatch(toggleInvoicesExportStart(payload));
  const url = `${XERO_BASE_URL}generations/${generationId}/toggle_invoices/`;

  return axios.post(url, payload).then((response) => {
    dispatch(toggleInvoicesExportSuccess(response.data));
  });
});
