import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'actions/modals';
import PropertyModal from '../Property';
import XeroCredentialsModal from '../XeroCredentials';
import ImportPropertiesModal from '../ImportProperties';
import WelcomeModal from '../Welcome';
import ChangeListingModal from '../ChangeListing';
import SelectContactModal from '../SelectContact';
import EditTransactionModal from '../EditTransaction';
import CreateTransactionModal from '../CreateTransaction';
import TermsOfServiceChangeModal from '../TermsOfServiceChange';
import RemoveXeroModal from '../RemoveXero';
import CreateXeroAccountModal from '../CreateXeroAccount';

import './style.scss';


class ModalContainer extends React.Component {
  // class to handle rendering modals at the application level.
  modalComponents = {
    property: PropertyModal,
    xeroCredentials: XeroCredentialsModal,
    importProperties: ImportPropertiesModal,
    welcome: WelcomeModal,
    changeListing: ChangeListingModal,
    selectContact: SelectContactModal,
    editTransaction: EditTransactionModal,
    createTransaction: CreateTransactionModal,
    tosChanged: TermsOfServiceChangeModal,
    removeXero: RemoveXeroModal,
    createXeroAccount: CreateXeroAccountModal,
  }

  render() {
    const modalState = this.props.modal;
    if (!modalState.modalType) { return null; }

    const SpecificModal = this.modalComponents[modalState.modalType];

    return (
      <SpecificModal
        {...modalState.modalProps}
        toggle={this.props.hideModal}
        isOpen
      />
    );
  }
}

const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps, { hideModal })(ModalContainer);
