import moment from 'moment';


export const xeroTokenExpired = (expiresAt) => {
  const now = moment().utc().format();

  // Subract minutes here to easily test expired token funcationality.
  const modifiedExpiresAt = moment(expiresAt).subtract(60, 'seconds').format();
  return now > modifiedExpiresAt;
};

export const getContactFromId = (contactId, contactsState) => {
  // Requires the contactId and also needs to pass in the redux contacts state.
  return contactsState.find(contact => contact.ContactID === contactId);
};

// Array of type key/value pairs.
export const transactionTypes = [
  { value: 'reservation', display: 'Reservation' },
  { value: 'res_adjustment', display: 'Resolution Adjustment' },
  { value: 'res_payout', display: 'Resolution Payout' },
  { value: 'adjustment', display: 'Adjustment' },
  { value: 'res_loss_recov', display: 'Resolution Loss Recovery' },
  { value: 'promo_offer', display: 'Promotion Offer' },
];
