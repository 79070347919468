import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { showModal } from 'actions/modals';
import { formatCurrency } from 'utils/misc';
import './style.scss';

class TrialAccountBalance extends React.Component {
  // Show an individual payment record that comes from an account balance.
  selectContact = (event) => {
    /* Either select the contact or if the bnbsync account hasn't been imported
       we need to import the account into bnbsync. */
    event.stopPropagation();
    if (!this.props.bnbsyncAccount) return this.openImportModal();

    const { xeroAccount, bnbsyncAccount } = this.props;
    this.props.showModal('selectContact', { xeroAccount, bnbsyncAccount });
  }

  toggleSelected = () => {
    /* Selected trial account balances will generate payments in xero. Each
       trial balance account needs to have the account imported into bnbsync
       and have a contact associated with it. */
    if (!this.props.bnbsyncAccount) return this.openImportModal();

    if (this.props.contact) this.props.toggleSelected(this.props.xeroAccount);
    else toast.error('Must select contact first.');
  }

  openImportModal = () => {
    /* When the xero account hasn't been imported into bnbsync we need to import
       it. */
    const helpText = `
      The xero account "${this.props.xeroAccount.accountName}" hasn't been
      loaded into bnbsync, and is required to create payment invoices. The
      follow accounts will be imported into bnbsync.
    `;
    this.props.showModal('importProperties', { helpText });
  }

  render() {
    const { credit, accountName } = this.props.xeroAccount;
    const currency = this.props.currency || 'AUD';
    if (!credit) return null;

    const getClassName = () => {
      let className = 'trial-balance-row p-3 my-2 border';

      if (this.props.isSelected) className += ' selected';

      return className;
    };

    const icon = this.props.isSelected ? faCheckCircle : faTimesCircle;

    const renderContact = () => {
      // Show the contact name or a button to link a contact to the xeroAccount.
      if (!this.props.contact) {
        return (
          <Button
            color="link"
            className="p-0"
            onClick={this.selectContact}
          >
            <FontAwesomeIcon icon={faUserPlus} />
            {' '}Needs Contact
          </Button>
        );
      }

      return this.props.contact.Name;
    };

    return (
      <Row
        noGutters
        className={getClassName()}
        onClick={this.toggleSelected}
      >
        <Col>{accountName}</Col>
        <Col>
          {renderContact()}
        </Col>
        <Col sm={2}>{formatCurrency(credit,currency)}</Col>
        <Col className="text-right" sm={1}>
          <FontAwesomeIcon icon={icon} />
        </Col>
      </Row>
    );
  }
}

TrialAccountBalance.propTypes = {
  // Individual account data that comes from trial balance.
  xeroAccount: PropTypes.object.isRequired,
  bnbsyncAccount: PropTypes.object,
  toggleSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,

  // Contact record linked to the xeroAccount, detail data coming from Xero.
  contact: PropTypes.object,
};

export default connect(null, { showModal })(TrialAccountBalance);
