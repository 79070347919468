import axios from 'axios';
import { API_URL } from 'config';


export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
const fetchCurrenciesSuccess = data => ({ type: FETCH_CURRENCIES_SUCCESS, data });

export const fetchCurrencies = () => (dispatch) => {
  const url = `${API_URL}currencies/`;

  return axios.get(url)
    .then(success => dispatch(fetchCurrenciesSuccess(success.data)));
};
