import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import { updateProperty } from 'actions/properties/update';
import SelectXeroContact from 'components/autocomplete/XeroContact';
import './style.scss';

class SelectContactModal extends React.Component {
  selectContact = (contactId) => {
    // Take a contactId and save it to our bnbsync property account.
    const payload = { xeroContactId: contactId };
    const options = { patch: true };

    this.props.updateProperty(this.props.bnbsyncAccount.id, payload, options)
      .then((action) => { this.props.toggle(); });
  }

  render() {
    // const contacts = this.props.xero.contacts.filter(contact => contact.IsSupplier == true);
    const {
      dispatch, updateProperty, bnbsyncAccount, xeroAccount, ...rest
    } = this.props;
    return (
      <Modal {...rest} className="select-contact-modal">
        <ModalBody>
          <p className="text-center text-muted">
            Select contact for account
            {this.props.account && this.props.account.accountName}
          </p>
          <SelectXeroContact
            onSelect={value => this.selectContact(value)}
            items={this.props.xero.contacts}
          />
        </ModalBody>
      </Modal>
    );
  }
}

SelectContactModal.propTypes = {
  xeroAccount: PropTypes.object.isRequired,
  bnbsyncAccount: PropTypes.object,
};

const mapStateToProps = ({ properties, xero }) => ({ properties, xero });
export default connect(mapStateToProps, { updateProperty })(SelectContactModal);
