// process a trial balance to create bills for selected accounts

import axios from 'axios';

import { XERO_BASE_URL } from '../index';
import { fetchXeroExports } from 'actions/xero/exports/fetch';
import { fetchMe } from 'actions/users/fetch';

export const PROCESS_XERO_TRIAL_BALANCE_START = 'PROCESS_XERO_TRIAL_BALANCE_START';
const processXeroTrialBalanceStart = () => ({
  type: PROCESS_XERO_TRIAL_BALANCE_START,
});

export const PROCESS_XERO_TRIAL_BALANCE_SUCCESS = 'PROCESS_XERO_TRIAL_BALANCE_SUCCESS';
const processXeroTrialBalanceSuccess = data => ({
  type: PROCESS_XERO_TRIAL_BALANCE_SUCCESS,
  data,
});

export const PROCESS_XERO_TRIAL_BALANCE_FAILURE = 'PROCESS_XERO_TRIAL_BALANCE_FAILURE';
const processXeroTrialBalanceFailure = error => ({
  type: PROCESS_XERO_TRIAL_BALANCE_FAILURE,
  error,
});

export const processXeroTrialBalance = payload => ((dispatch) => {
  // pass in the accounts that we want to process and create a bill for.
  const url = `${XERO_BASE_URL}trial-balance/`;
  dispatch(processXeroTrialBalanceStart());

  return axios.post(url, payload)
    .then(
      response => {
        dispatch(fetchXeroExports());
        dispatch(fetchMe());
        return dispatch(processXeroTrialBalanceSuccess(response.data));
      },
      error => {
        const message = error && error.response ?
          error.response.data.detail :
          'Error creating owner payments.';
        return dispatch(processXeroTrialBalanceFailure(message));
      },
    );
});
