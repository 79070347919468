import React from 'react';
import {
  Modal, ModalBody, ModalFooter, Button, Container, Row, Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faDatabase, faFileImport, faLongArrowAltRight,
  faExchangeAlt, faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';
import { updateMe } from 'actions/users/update';

import DotStatus from 'components/DotStatus';
import './style.scss';


class WelcomeModal extends React.Component {
  state = {
    // steps determine which screen the user is shown
    step: 1,
  }

  skipTour = () => {
    this.props.updateMe({ hasDoneTutorial: true });
    this.props.toggle();
  }

  finishTour = () => {
    this.props.updateMe({ hasDoneTutorial: true });
    this.props.toggle();
  }

  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  }

  previousStep = () => {
    this.setState({ step: this.state.step - 1 });
  }

  componentWillUnmount() {
    this.finishTour();
  }

  render() {
    const { updateMe, ...rest } = this.props;
    const renderContinueButton = () => {
      if (this.state.step === 4) {
        return (
          <Button color="success" className="pl-3" onClick={this.finishTour}>
            Finish
            {' '}
            <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
          </Button>
        );
      }

      return (
        <Button color="primary" className="pl-3" onClick={this.nextStep}>
          Next
          {' '}
          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </Button>
      );
    };

    const renderBackButtion = () => {
      // don't show on first step
      if (this.state.step === 1) return null;

      return (
        <Button color="primary" onClick={this.previousStep} className="pr-3">
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {' '} Back
        </Button>
      );
    };

    const openStepLink = linkKey => {
      // Link to our relevent help pages for each step.
      const stepLinks = {
        connectXeroAPI: "https://www.bnbsync.io/help/articles/how-to-connect-your-xero-account",
        importAccounts: "https://www.bnbsync.io/help/articles/how-to-import-accounts-into-bnbsync",
        mapListings: "https://www.bnbsync.io/help/articles/how-to-map-my-listings-in-bnbsync",
      };

      return window.open(stepLinks[linkKey], "_blank");
    };



    const renderSection = () => {
      const { step } = this.state;
      // determine which section we're on and render the appropriate content
      if (step === 1) {
        return (
          <div>
            <DotStatus step={this.state.step} max={4} />
            <h5 className="header">
              Automate moving source transactions into your Xero account in 3 steps.
            </h5>
            <div className="steps-container">
              <div className="step" onClick={() => openStepLink('connectXeroAPI')}>
                Connect to Xero API
                {' '}
                <FontAwesomeIcon icon={faDatabase} />
              </div>
              <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
              <div className="step" onClick={() => openStepLink('importAccounts')}>
                Import Xero accounts
                {' '}
                <FontAwesomeIcon icon={faFileImport} />
              </div>
              <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
              <div className="step" onClick={() => openStepLink('mapListings')}>
                Map Airbnb listings
                {' '}
                <FontAwesomeIcon icon={faExchangeAlt} />
              </div>
            </div>
          </div>
        );
      }
      if (step === 2) {
        return (
          <div>
            <DotStatus step={this.state.step} max={4} />
            <h5 className="header">Connect to Xero API</h5>
            <Container className="step-container">
              <Row>
                <Col sm={3} className="mx-3">
                  <FontAwesomeIcon icon={faDatabase} />
                </Col>
                <Col>
                  <p>Connecting to the Xero API gives many advantages.</p>
                  <ul className="xero-api-feature-list">
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                      {' '} importing accounts
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                      {' '} creating payouts
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                      {' '} more planned to come!
                    </li>
                  </ul>
                  <p>
                     Xero has an official guide of steps to set up your application,
                     and we've also prepared further help when you initiate the
                     import.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
      if (step === 3) {
        return (
          <div>
            <DotStatus step={this.state.step} max={4} />
            <h5 className="header">Import Xero Accounts</h5>
            <Container className="step-container">
              <Row>
                <Col sm={3} className="mx-3">
                  <FontAwesomeIcon icon={faFileImport} />
                </Col>
                <Col className="step-flex">
                  <p>Once connected to the Xero API, you can get started quickly by importing your accounts directly from Xero.</p>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
      if (step === 4) {
        return (
          <div>
            <DotStatus step={this.state.step} max={4} />
            <h5 className="header">Map Listings</h5>
            <Container className="step-container">
              <Row>
                <Col sm={3} className="mx-3">
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </Col>
                <Col className="step-flex">
                  <p>The magic happens by mapping each airbnb listing to a Xero account. You'll find an intuitive process to link each of your airbnb listings to a Xero account.</p>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
    };

    return (
      <Modal {...rest} size="lg" className="welcome-modal">
        <ModalBody>
          {renderSection()}
        </ModalBody>
        <ModalFooter>

          <Button color="link" onClick={this.skipTour}>Skip</Button>
          {renderBackButtion()}
          {renderContinueButton()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(null, { updateMe })(WelcomeModal);
