import axios from 'axios';
import { API_URL } from 'config';


export const UPDATE_ME_START = 'UPDATE_ME_START';
const updateMeStart = () => ({ type: UPDATE_ME_START });

export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
const updateMeSuccess = data => ({ type: UPDATE_ME_SUCCESS, data });

export const updateMe = payload => (dispatch) => {
  dispatch(updateMeStart());
  const url = `${API_URL}users/me/`;

  return axios.patch(url, payload)
    .then(success => dispatch(updateMeSuccess(success.data)));
};
