import { connect } from 'react-redux';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { Form, Row, Col, FormText } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import LoadButton from 'components/buttons/LoadButton';
import AibnbFileDrop from 'components/ExportCSVFileDrop';
import { generateXeroImport, GENERATE_XERO_IMPORT_SUCCESS } from 'actions/generations/create';

class CSVImportForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  state = {
    startDate: null,
    endDate: null,
    file: null,
    errorMessage: '',
    isLoading: false
  }

  onStartChange = date => this.setState({ startDate: date })

  onEndChange = date => this.setState({ endDate: date })

  clearFile = (e) => {
    e.stopPropagation();
    this.setState({ file: null });
  }

  handleSelectedFile = (event) => {
    const file = event.target.files[0];
    this.setState({ file });
    toast(`${file.name} ready for processing.`);
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0];
    this.setState({ file });
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    const { startDate, endDate, file } = this.state;
    const formattedStartDate = startDate
      ? moment(startDate).format('MM/DD/YYYY')
      : null;

    const formattedEndDate = endDate
      ? moment(endDate).format('MM/DD/YYYY')
      : null;

    if (startDate && endDate && startDate > endDate) {
      return this.setState({ errorMessage: 'End date must be on or after Start Date.' });
    }


    if (!file) {
      return this.setState({
        errorMessage: 'Please attach a source csv file to be imported.',
      });
    }

    this.setState({ errorMessage: '' });

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      file,
    };

    const action = await this.props.generateXeroImport(payload);
    this.setState({ isLoading: false });

    if (action.type === GENERATE_XERO_IMPORT_SUCCESS) 
      this.props.progressToNextStep();
    else
      this.setState({ 
        errorMessage: action.error || "Error importing the csv file transactions." 
      });
  }

  render() {
    const renderErrorMessage = () => {
      const { errorMessage } = this.state;
      if (!errorMessage && !this.props.xero.error) return null;

      const error = this.props.xero.error || errorMessage;

      return <p className="text-danger">{error}</p>;
    };

    const renderTrialStartWarning = () => {
      if (!this.props.session.user.hasActiveTrial)
        return null;

      return (
        <div>
          <FormText color="warning">
            Trial accounts can only import up to 3 months prior
          </FormText>
          <FormText>
            <Link to="/payments">Upgrade account</Link>
          </FormText>
        </div>
      );
    };

    return (
      <Form className="text-center source-import-form">
        <Row>
          <Col className="text-right">
            <DatePicker
              selected={this.state.startDate}
              onChange={date => this.onStartChange(date)}
              placeholderText="Start Date"
            />
            {renderTrialStartWarning()}
          </Col>
          <Col className="text-left">
            <DatePicker
              selected={this.state.endDate}
              onChange={date => this.onEndChange(date)}
              placeholderText="End Date"
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={{ size: 8, offset: 2 }}>
            <AibnbFileDrop
              onDrop={this.onDrop}
              loadedFile={this.state.file}
              clearFile={this.clearFile}
            />
          </Col>
        </Row>
        {renderErrorMessage()}
        <LoadButton
          disabled={this.state.isLoading || !this.state.file}
          isLoading={this.state.isLoading}
          onClick={this.handleSubmit}
        >
          <FontAwesomeIcon icon={faFileImport} /> Import
        </LoadButton>
      </Form>
    );
  }
}

CSVImportForm.propTypes = {
  progressToNextStep: PropTypes.func.isRequired,
};

const mapStateToProps = ({ xero, session }) => ({ xero, session });
export default connect(mapStateToProps, { generateXeroImport })(CSVImportForm);
