import React from 'react';
import { Link } from 'react-router-dom';

import RegisterForm from 'components/forms/auth/Register';

class RegisterRoute extends React.Component {
  render() {
    const { type } = this.props.match.params;

    return (
      <div className="register-route">
        <RegisterForm type={type} />
        <div className="auth-extra-container m-2 text-center">
          <Link to="/auth/login">Already have account?</Link>
        </div>
      </div>
    );
  }
}

export default RegisterRoute;
