import axios from 'axios';

import { XERO_BASE_URL } from '../index';


export const REFRESH_XERO_CREDENTIALS_START = 'REFRESH_XERO_CREDENTIALS_START';
const refreshXeroCredentialsStart = () => ({ type: REFRESH_XERO_CREDENTIALS_START });

export const REFRESH_XERO_CREDENTIALS_SUCCESS = 'REFRESH_XERO_CREDENTIALS_SUCCESS';
const refreshXeroCredentialsSuccess = (data) => (
  { type: REFRESH_XERO_CREDENTIALS_SUCCESS, data }
);

export const REFRESH_XERO_CREDENTIALS_FAILURE = 'REFRESH_XERO_CREDENTIALS_FAILURE';
const refreshXeroCredentialsFailure = data => (
  { type: REFRESH_XERO_CREDENTIALS_FAILURE }
);

export const refreshXeroCredentials = () => ((dispatch) => {
  dispatch(refreshXeroCredentialsStart());
  const url = `${XERO_BASE_URL}credentials/refresh/`;
  return axios.post(url).then(
    response => dispatch(refreshXeroCredentialsSuccess(response.data)),
    error => dispatch(refreshXeroCredentialsFailure()),
  );
});
